import { useCallback, useEffect, useMemo, useRef, useState, memo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import Spinner from '../../components/common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'
import pluralize from 'pluralize'
import Formatters from '../../api/constants/Formatters'
import ConnectivityStore from '../../api/stores/ConnectivityStore'

const componentProps = data => {
  return {
    yearMakeModel: `${data.year} ${data.make} ${data.model}`,
    trim: data.full_trim,
    dealerName: data.dealer_name,
    price: Formatters.formatCurrency(data.price),
    mileage: data.mileage ? `${Formatters.formatThousands(data.mileage)} ${pluralize('mile', data.mileage)}` : null,
    daysOnLot: `${Formatters.formatThousands(data.days_on_lot)} ${pluralize('day', data.days_on_lot)}`,
    numPriceChanges: pluralize('price change', data.num_price_changes, true),
    photoUrl: data.primary_photo_url ? `${ConnectivityStore.apolloApiRoot}${data.primary_photo_url}` : null,
  }
}

const ListingImage = ({ src, style }) => {
  const hasErrorRef = useRef(false)
  const fallbackImage = '/images/missing-photo-placeholder.png'

  const onError = useCallback((e) => {
    e.target.src = hasErrorRef.current ? '' : fallbackImage
    hasErrorRef.current = true
  }, [])

  return (
    <img
      src={src || fallbackImage}
      loading='lazy'
      onError={onError}
      style={{ ...style, objectFit: 'cover', objectPosition: 'center' }}
      alt='Listing'
    />
  )
}

const ListingsList = memo(({ regionalListingsParams, order = 'mileage', getScrollParent }) => {
  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({ ...regionalListingsParams, order })
  }, [regionalListingsParams, order])

  const [
    {
      isLoading,
      data,
      endOfListReached,
    },
    setState,
  ] = useState({
    isLoading: false,
    data: null,
    endOfListReached: false,
  })

  const onDataChanged = useCallback(() => {
    const data = ValuationStore.getRegionalListingsWithClientID(clientID)
    setState(prev => ({
      ...prev,
      data: data.data,
      isLoading: false,
      endOfListReached: data.isLastPage,
    }))
  }, [clientID])

  useEffect(() => {
    const ev = `regional_listings_change_${clientID}`
    ValuationStore.on(ev, onDataChanged)

    setState(prev => ({ ...prev, isLoading: true }))
    ValuationActionCreators.loadRegionalListings({ ...regionalListingsParams, clientID, order })

    return () => {
      ValuationStore.removeListener(ev, onDataChanged)
    }
  }, [regionalListingsParams, order, onDataChanged, clientID])

  const loadMore = useCallback((page) => {
    if (page > 1)
    ValuationActionCreators.loadRegionalListings({ ...regionalListingsParams, clientID, page, order })
  }, [regionalListingsParams, clientID, data, order])

  // FIXME: handle no data, errors

  return (
    <>
      {
        isLoading ?
        <Spinner /> : (
          data &&
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            getScrollParent={getScrollParent}
            useWindow={false}
            hasMore={!endOfListReached}
            loader={<Spinner key="pagination-spinner" />}
          >
            {
              !data.length ?
              <div>No listings found</div> :
              data.map(item => {
                const { photoUrl, yearMakeModel, trim, dealerName, price, mileage, daysOnLot, numPriceChanges } = componentProps(item)
                return (
                  <div className="split-row" style={{borderBottom: '1px solid #eee'}} key={item.id}>
                    <ListingImage
                      src={photoUrl}
                      style={{width: '100px', height: '75px', marginRight: '10px'}}
                    />
                    <div style={{flexGrow: 1, flexShrink: 1}}>
                      <div className="list-emphasis">{yearMakeModel}</div>
                      <div className="secondary-text">{trim}</div>
                      <div className="secondary-text">{dealerName}</div>
                    </div>

                    <div style={{flexGrow: 0, flexShrink: 0, width: '80px'}}>
                      {price}
                    </div>

                    <div style={{flexGrow: 0, flexShrink: 0, width: '120px'}}>
                      {mileage}
                    </div>

                    <div style={{flexGrow: 0, flexShrink: 0, width: '80px'}}>
                      {daysOnLot}
                    </div>

                    <div style={{flexGrow: 0, flexShrink: 0, width: '140px'}}>
                      {numPriceChanges}
                    </div>

                  </div>
                )
              })
            }
          </InfiniteScroll>
        )
      }
    </>
  )
})

export default ListingsList
