import { useMemo } from 'react'
import ListingsModal from '../retail/ListingsModal'
import useMyLotSettings from './util/useMyLotSettings'

const RetailListingsModal = ({ year, make, model, trim, onClose, title }) => {
  const [{ regionSettings }] = useMyLotSettings()

  const regionalListingsParams = useMemo(() => {
    return {
      zip: regionSettings.zip,
      radius: regionSettings.radius,
      year,
      make,
      model,
      trim,
    }
  }, [regionSettings, year, make, model, trim])

  return (
    <ListingsModal
      regionalListingsParams={regionalListingsParams}
      onClose={onClose}
      title={title}
    />
  )
}

export default RetailListingsModal
