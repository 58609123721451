import React, { useState, useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'
import moment from 'moment'
import ListingImage from '../../components/common/ListingImage'
import VehicleTransactionDataFormModal from '../../components/myLot/VehicleTransactionDataFormModal'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import ValuationStore from '../../api/stores/ValuationStore'
import Alert from '../../components/myLot/Alert'
import LotStateButton from '../../components/myLot/LotStateButton'

const LotHistoryVehicleRow = ({ listing }) => {
  const now = moment()

  const [isShowingTransactionDataModal, setIsShowingTransactionDataModal] = useState(false)
  const [lotVehicle, setLotVehicle] = useState(listing)
  const clientID = lotVehicle.uuid

  const onDataChange = useCallback(() => {
    const data = ValuationStore.myLotVehiclesUpdated.dataWithID(clientID)
    setLotVehicle({...lotVehicle, ...data})
  }, [clientID])

  const onLotVehicleDeleted = useCallback(() => {
    setLotVehicle({...lotVehicle, isDeleted: true})
  }, [clientID])

  useEffect(() => {
    const lotVehicleEv = `lot_vehicle_updated_${clientID}`
    const lotVehicleDeletedEv = `lot_vehicle_deleted_${clientID}`
    ValuationStore.on(lotVehicleEv, onDataChange)
    ValuationStore.on(lotVehicleDeletedEv, onLotVehicleDeleted)

    return () => {
      ValuationStore.removeListener(lotVehicleEv, onDataChange)
      ValuationStore.removeListener(lotVehicleDeletedEv, onLotVehicleDeleted)
    }
  }, [onDataChange, clientID])

  const url = MyLotFunctions.myLotVehicleURL(lotVehicle)

  const showTransactiondDataModal = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsShowingTransactionDataModal(true)
  }, [])

  if (lotVehicle.isDeleted) {
    return null
  }

  return (
    <div className="card-list-row my-lot-vehicle-row">
      <Link to={url}>
        <div className="thumbnail">
          <ListingImage src={lotVehicle.photo_urls[0]} />
        </div>
        <div className="my-lot-vehicle-body">
          <div className='row'>
            <div className='listing-basics'>
              <h3 className='year-make'>{lotVehicle.year} {lotVehicle.make}</h3>
              <h3 className='model'>{lotVehicle.model}</h3>

              {
                lotVehicle.trim &&
                <div className="secondary-text trim">{lotVehicle.trim}</div>
              }

              {
                Boolean(lotVehicle.vin) &&
                <div className="secondary-text">{lotVehicle.vin}</div>
              }
              {
                Boolean(lotVehicle.mileage && lotVehicle.mileage > 0) &&
                <div className="secondary-text">{lotVehicle.mileage > 0 ? Formatters.formatThousands(lotVehicle.mileage) + ' miles' : ''}</div>
              }
            </div>

            <div className='listing-dol'>
              {
                lotVehicle.sold_at != null &&
                <div>Sold {pluralize('day', now.diff(lotVehicle.sold_at, 'days'), true)} ago</div>
              }
              {
                lotVehicle.days_on_lot != null &&
                <div style={{color: MyLotFunctions.colorForDaysOnLot(lotVehicle.days_on_lot)}}>{pluralize('day', lotVehicle.days_on_lot, true)} on lot</div>
              }
            </div>

            <div className='listing-price-changes'>
              <div>{pluralize('price change', lotVehicle.num_price_changes, true)}</div>
              {
                lotVehicle.num_price_changes > 1 && lotVehicle.total_price_change_amount_cents &&
                <div className='secondary-text'>Total change {Formatters.formatCurrencyCents(lotVehicle.total_price_change_amount_cents)}</div>
              }

              {
                lotVehicle.last_price_change_date != null && lotVehicle.last_price_change_amount_cents != null &&
                <div className='secondary-text'>
                  <div>Last: {pluralize('day', now.diff(lotVehicle.last_price_change_date, 'days'), true)} ago</div>
                </div>
              }
            </div>

            <div className='transaction-data'>
              <div>Profit, etc</div>
              <button className='text-button' onClick={showTransactiondDataModal}>Edit price and costs</button>
            </div>

            {/* FIXME: sale_price_cents can be nil even if we have a current 'price_cents' */}
            <div className='pricing'>
              {
                lotVehicle.sale_price_cents != null ?
                <>
                  <div className='list-price'>{Formatters.formatCurrencyCents(lotVehicle.sale_price_cents)}</div>
                  <div className='secondary-text'>Sale price</div>

                  {
                    lotVehicle.first_price_cents != null &&
                    <>
                      <div>{Formatters.formatCurrencyCents(lotVehicle.first_price_cents)}</div>
                      <div className='secondary-text'>First price</div>
                    </>
                  }

                  {
                    lotVehicle.profit != null &&
                    <>
                      <div>{Formatters.formatCurrency(lotVehicle.profit)}</div>
                      <div className='secondary-text'>Profit</div>
                    </>
                  }

                  {/* FIXME: percent of market for original list price needs spec */}

                  {
                    lotVehicle.portion_of_market != null &&
                    <div>{Math.round(lotVehicle.portion_of_market * 100)}% of market</div>
                  }

                </> : <button onClick={showTransactiondDataModal}>Set Price</button>
              }
            </div>
          </div>

          <div className='row capsules'>
            <LotStateButton lotVehicleState={lotVehicle.state} onClick={showTransactiondDataModal} />
            {lotVehicle.alerts.map((alert, i) => <Alert key={i} alert={alert} year={lotVehicle.year} make={lotVehicle.make} model={lotVehicle.model} trim={lotVehicle.trim} />)}
          </div>

        </div>
      </Link>

      {
        isShowingTransactionDataModal &&
        <VehicleTransactionDataFormModal onCancel={() => setIsShowingTransactionDataModal(false)} onDelete={() => setIsShowingTransactionDataModal(false)} vehicle={{ uuid: lotVehicle.uuid, year: lotVehicle.year, make: lotVehicle.make, model: lotVehicle.model, lot_vehicle: lotVehicle }} clientID={clientID} />
      }
    </div>
  )
}

export default LotHistoryVehicleRow
