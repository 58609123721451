import { ResponsiveLine } from '@nivo/line'
import { memo } from 'react'

const LineChart = memo(({
  data,
  yFormatFunction,
  lineWidth,
  pointSize,
  yMin = 0,
  enableSlices,
  sliceTooltip,
  legends,
  margin = { top: 30, right: 26, bottom: 26, left: 26 },
}) => {
  return (
    <ResponsiveLine
      theme={{
        dots: { text: { fontSize: 13 } },
        legends: { text: { fontSize: 13 } },
      }}
      data={data}
      margin={margin}
      xScale={{ type: 'point' }}
      yScale={{ type: 'linear', stacked: false, min: yMin }}
      yFormat={yFormatFunction}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 2,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={null}
      enableGridY={false}
      colors={node => node.color}
      lineWidth={lineWidth || 2}
      pointSize={pointSize || 4}
      pointBorderWidth={3}
      enableArea={true}
      areaBaselineValue={yMin}
      areaOpacity={0.08}
      pointBorderColor={{ from: 'serieColor' }}
      enablePointLabel={true}
      pointLabel="yFormatted"
      pointLabelYOffset={-12}
      useMesh
      enableSlices={enableSlices}
      sliceTooltip={sliceTooltip}
      legends={legends}
      tooltip={(el) => {
        return (
          <div className="graph-tooltip">
            <div>{el.point.data.xFormatted}</div>
            <div>{el.point.data.yFormatted}</div>
          </div>
        )
      }}
    />
  )
})

export default LineChart
