import {
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  CategoryScale,
  Filler,
  BarElement,
  ArcElement,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

Chart.register(LinearScale, Filler, CategoryScale, PointElement, LineElement, Tooltip, Legend, ChartDataLabels, BarElement, ArcElement)

Chart.defaults.maintainAspectRatio = false
Chart.defaults.plugins.legend.display = false
Chart.defaults.plugins.datalabels.display = false
Chart.defaults.color = '#16181A'
Chart.defaults.plugins.tooltip.padding = 8
Chart.defaults.plugins.tooltip.bodySpacing = 0
Chart.defaults.plugins.tooltip.displayColors = false
Chart.defaults.plugins.tooltip.bodyFont.size = 13

// Animation
Chart.defaults.animation = false
// Animate only tooltips
// Chart.defaults.animation.duration = 0
// Chart.defaults.plugins.tooltip.animation.duration = 160
