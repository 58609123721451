import React from 'react'
import PropTypes from 'prop-types'
import { Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import pluralize from 'pluralize'
import moment from 'moment'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import SessionStore from '../../api/stores/SessionStore'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import ValuationSummary from './ValuationSummary'
import MatchupWholesale from './MatchupWholesale'
import MatchupRetail from './MatchupRetail'
import ValuationManheim from './ValuationManheim'
import ValuationPIN from './ValuationPIN'
import ValuationPipeline from './ValuationPipeline'
import ValuationUniverse from './ValuationUniverse'
import ValuationLLM from './ValuationLLM'
import ValuationNADA from './ValuationNADA'
import ValuationKBB from './ValuationKBB'
import ValuationBlackBook from './ValuationBlackBook'
import ValuationGalves from './ValuationGalves'
import Carfax from './Carfax'
import AutoCheck from './AutoCheck'
import ValuationPartners from './ValuationPartners'
import ValuationShareModal from './ValuationShareModal'
import ValuationProviderChoicesCard from './ValuationProviderChoicesCard'
import ValuationChoiceReset from './ValuationChoiceReset'
import ValuationNotes from './ValuationNotes'
import ValuationPhotos from './ValuationPhotos'
import FolderAssignmentPopover from './FolderAssignmentPopover'
import MileageEntryField from '../common/MileageEntryField'
import ZipCodeEntryField from '../common/ZipCodeEntryField'
import ValuationHUD from './ValuationHUD'
import AccountBug from '../user/AccountBug'
import Avatar from '../user/Avatar'
import ClipboardIcon from '../common/icons/ClipboardIcon'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import ChoicesFunctions from '../../api/constants/ChoicesFunctions'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AppConstants from '../../api/constants/AppConstants'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import TargetPrice from './TargetPrice'
import InfoIcon from '../common/icons/InfoIcon'
import OfferingsUpsellModal from '../common/OfferingsUpsellModal'
import Bookout from './Bookout'
import BookoutIcon from '../common/icons/BookoutIcon'
import LLMSampleDetail from './LLMSampleDetail'
import PlusIcon from '../common/icons/PlusIcon'
import ConnectivityStore from '../../api/stores/ConnectivityStore'
import OfferingUpsellModal from '../common/OfferingUpsellModal'
import Calculator from '../common/Calculator'
import AccountNeedsAction from '../user/AccountNeedsAction'
import AuctionsActionCreators from '../../api/actions/AuctionsActionCreators'
import AuctionStore from '../../api/stores/AuctionStore'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import AuctionsListingShowModal from '../auctions/AuctionsListingShowModal'
import MagnifyingGlassIcon from '../common/icons/MagnifyingGlassIcon'
import UserActionCreators from '../../api/actions/UserActionCreators'
import Vintel from './Vintel'
import ModelStats from './ValuationRetailModelStats'
import AuctionsPlusUpsellModal from '../auctions/AuctionsPlusUpsellModal'
import LotVehicle from './LotVehicle'
import VehicleTransactionDataFormModal from '../myLot/VehicleTransactionDataFormModal'
import ValuationScorecard from './ValuationScorecard'

const PROVIDER_DATA_KEY_STRUCTURE = {
  black_book: {data: 'blackBookData', choices: 'blackBookFullChoices'},
  manheim: {data: 'manheimData', choices: 'manheimFullChoices'},
  pin: {data: 'pinData', choices: 'pinFullChoices'},
  pmr: {data: 'pmrData', choices: 'pmrFullChoices'},
  universe: {data: 'universeData', choices: 'universeFullChoices'},
  nada: {data: 'nadaData', choices: 'nadaFullChoices'},
  kbb: {data: 'kbbData', choices: 'kbbFullChoices'},
  galves: {data: 'galvesData', choices: 'galvesFullChoices'}
}

const MAX_SIMILAR_VEHICLES_AT_AUCTION = 8

export default class Valuation extends React.Component {
  static contextTypes = { router: PropTypes.object }

  state = {
    needsRefreshToggle: false,
    isShowingMileagePopover: false,
    modifiedMileage: "",
    modifiedCondition: null,
    modifiedZipCode: "",
    modifiedZipCodeIsValid: false,
    isShowingZipCodePopover: false,
    choicesCanBeReset: false,
    isShowingChoiceResetModal: false,
    isShowingExtendedDetails: false,
    isShowingAuctionDetailsModal: false,
    auctionDetailsModalListing: null,
    isShowingRetailDetailsModal: false,
    isShowingShareModal: false,
    isShowingBookoutModal: false,
    carfaxSummary: null,
    carfaxSnapshotStatus: null,
    hasCarfaxPreview: false,
    hasAutocheckPreview: false,
    choices: this.props.valuationData ? ChoicesFunctions.initializeChoices(this.props.valuationData) : null,
    choicesUpdatingProviders: [],
    hiddenTrialFullData: SessionStore.hiddenTrialFullData,
    isShowingLightbox: false,
    hasAuthorizedCarfaxConnect: SessionStore.hasAuthorizedCarfaxConnect(),
    hasLinkedAutoCheck: SessionStore.hasLinkedAutoCheck(),
    isShowingFreeUserUpsellModal: false,
    bookoutSelectedProvider: null,
    similarVehicesAtAuction: null,
    isShowingAuctionsPlusUpsellModal: false,
    isShowingLotVehicleTransactionDataModal: false,
  }

  constructor(props) {
    super(props)
    this.isShared = ValuationStore.isSharedValuation
    this.isPartnerPreview = this.props.isPartnerPreview === true
  }

  componentDidMount() {
    const valuation = this.props.valuationData
    const providers = valuation.full_valuations
    var choicesCanBeReset = false

    if (providers) {
      for (let providerKey of Object.keys(providers)) {
        if (providers[providerKey]['full_choices'] && providers[providerKey]['full_choices'].length > 0) {
          choicesCanBeReset = true
          break
        }
      }
    }

    this.setState({
      choicesCanBeReset: choicesCanBeReset
    })

    this.fetchCarfaxSummary()
    this.fetchAutoCheckSummary()
    this.findSimilarVehicesAtAuction()
    this.fetchVintelReport()

    SessionStore.on("user_data_changed", this.userDataChanged)
    ValuationStore.on(`carfax_summary_changed_${valuation.uuid}`, this.carfaxSummaryDidChange)
    ValuationStore.on(`autocheck_summary_changed_${valuation.uuid}`, this.autoCheckSummaryDidChange)
    ValuationStore.on(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
    ValuationStore.on(`lot_vehicle_updated_${valuation.uuid}`, this.lotVehicleDidChange)
    AuctionStore.on(`auction_similar_vehicles_change`, this.findSimilarVehicesAtAuctionDidChange)
  }

  componentWillUnmount() {
    const vehicle = this.props.valuationData
    ValuationStore.removeListener(`carfax_summary_changed_${vehicle.uuid}`, this.carfaxSummaryDidChange)
    ValuationStore.removeListener(`autocheck_summary_changed_${vehicle.uuid}`, this.autoCheckSummaryDidChange)
    SessionStore.removeListener("user_data_changed", this.userDataChanged)
    ValuationStore.removeListener(`valuation_change_${this.props.clientID}`, this.valuationDidChange)
    ValuationStore.removeListener(`lot_vehicle_updated_${vehicle.uuid}`, this.lotVehicleDidChange)
    AuctionStore.removeListener('auction_similar_vehicles_change', this.findSimilarVehicesAtAuctionDidChange)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasAuthorizedCarfaxConnect !== prevState.hasAuthorizedCarfaxConnect) {
      this.fetchCarfaxSummary()
      this.fetchAutoCheckSummary()
    }
  }

  fetchCarfaxSummary = () => {
    const vin = this.props.valuationData.vin !== null && this.props.valuationData.vin !== undefined ? this.props.valuationData.vin : null
    if (!this.isShared && this.state.hasAuthorizedCarfaxConnect && vin) {
      ValuationActionCreators.loadCarfaxSummary(this.props.valuationData.uuid)
    }
  }

  fetchAutoCheckSummary = () => {
    if (this.state.hasLinkedAutoCheck && this.props.valuationData?.vin !== null) {
      ValuationActionCreators.loadAutoCheckSummary(this.props.valuationData.uuid)
    }
  }

  fetchVintelReport = () => {
    if (this.props.valuationData?.vin != null && !this.isShared && !this.isPartnerPreview) {
      ValuationActionCreators.loadVintelReport(this.props.valuationData.uuid)
    }
  }

  valuationDidChange = () => {
    if (global.isExtensionMode && this.props.valuationUpdated) {
      // Extension doesn't have an outer wrapper to manage events
      const newValuation = ValuationStore.valuationWithClientID(this.props.clientID)
      if (newValuation) {
        this.props.valuationUpdated(newValuation)
      }
    } else {
      this.isShowingBlackBookChoice = false
      this.setState({
        choicesUpdatingProviders: [],
        choices: this.props.valuationData ? ChoicesFunctions.initializeChoices(this.props.valuationData) : null,
      })
    }
  }

  lotVehicleDidChange = () => {
    ValuationActionCreators.loadExistingVehicle(this.props.valuationData.uuid, this.props.clientID)
  }

  userDataChanged = () => {
    this.setState({
      needsRefreshToggle: !this.state.needsRefreshToggle,
      hasAuthorizedCarfaxConnect: SessionStore.hasAuthorizedCarfaxConnect()
    })
  }

  carfaxSummaryDidChange = () => {
    if (!this.refs.mainRef) { return }
    const vehicle = this.props.valuationData
    const carfaxSummary = ValuationStore.carfaxSummaryWithID(vehicle.uuid)

    let snapshotStatus = ValuationFunctions.carfaxSnapshotStatus(carfaxSummary)
    if (snapshotStatus !== 'ignore') {
      switch (snapshotStatus) {
        case 'good':
          snapshotStatus = 'carfax-ok-small.png'
          break;
        case 'warn':
          snapshotStatus = 'carfax-alert-small.png'
          break;
        default:
          snapshotStatus = 'carfax-alert-small.png'
      }
    }
    const hasCarfaxPreview = ValuationFunctions.hasCarfaxPreviewData(carfaxSummary)
    this.setState({
      carfaxSummary: carfaxSummary,
      carfaxSnapshotStatus: snapshotStatus,
      carfaxSummaryIsLoading: false,
      hasCarfaxPreview: hasCarfaxPreview
    })
  }

  autoCheckSummaryDidChange = () => {
    const vehicle = this.props.valuationData
    const autoCheckPreview = ValuationStore.autoCheckSummaryWithID(vehicle.uuid)
    if (autoCheckPreview && !autoCheckPreview.errors) {
      this.setState({
        hasAutocheckPreview: true
      })
    }
  }

  findSimilarVehicesAtAuction = () => {
    const vehicle = this.props.valuationData
    const { auctionListingProvider } = this.props
    if (!AuctionFunctions.shouldShowSimilarVehiclesForProvider(auctionListingProvider)) {
      // As per license agreement, we can't show similar vehicles for some providers
      return
    }


    if (vehicle?.year && vehicle?.make && vehicle?.model) {
      let savedSearch = AuctionFunctions.savedSearchForSimilarVehicles(vehicle)
      let serverSearch = AuctionFunctions.savedSearchServerQueryFormatter(savedSearch)

      // We only need 3 results for the preview
      serverSearch['limit'] = MAX_SIMILAR_VEHICLES_AT_AUCTION
      serverSearch['location_type'] = 'distance'
      serverSearch['zip_code'] = SessionStore.user?.zip_code
      serverSearch['location_radius'] = AppConstants.auctionSimilarVehiclesSearchRadius
      serverSearch.search_type = 'similar'

      // Exclude the current vehicle from the search
      if (vehicle?.vin) {
        serverSearch['exclude_vin'] = vehicle.vin
      }
      AuctionsActionCreators.searchAuctionListings(1, serverSearch, '__similar_vehicles')
    }
  }

  findSimilarVehicesAtAuctionDidChange = () => {
    // FIXME: need to store / get separate auc search results by id in store
    this.setState({ similarVehicesAtAuction: AuctionStore.similarVehiclesSearchResults })
  }

  similarVehiclesLink = (valuation) => {
    return platformFunctions.similarVehiclesSearchLink(valuation.year, valuation.make, valuation.model)
  }

  renderProviderChoicesCard = (providerKey) => {
    const choices = this.state.choices
    return (
      <ValuationProviderChoicesCard
        providerKey={providerKey}
        onChoice={this.onChoice}
        showAsLoading={this.state.choicesUpdatingProviders.includes(`${providerKey}`)}
        providerResults={choices && choices[providerKey] ? choices[providerKey] : this[PROVIDER_DATA_KEY_STRUCTURE[providerKey]['data']].choices}
        clientID={this.props.clientID}
        key={`choices-card-${providerKey}`}
      />
    )
  }

  renderProviders = (valuation) => {
    const providers = valuation.full_valuations
    var limitedProviders = this.props.limitProviders // Shared valuations can hide providers
    const user = SessionStore.user
    const activeCards = user && user.active_cards ? user.active_cards : null
    let trialCards = user && user.trial_cards ? user.trial_cards : null

    // Special case for Market Tracker case since it's a feature, not a provider
    // If they have access to MT, but it's not in account offerings, add as a trial
    // If they have purchased MT, it would have been already added in SessionStore.reconciledCardOrder()
    let isTrialingMarketTracker = false
    if (trialCards && trialCards.includes('regional_report')) {
      isTrialingMarketTracker = true
    } else if (SessionStore.hasMarketTrackerAccess() && !SessionStore.hasPurchasedSKU(AppConstants.skuMappings.marketTracker) && (!trialCards || !trialCards.includes('regional_report')) && !activeCards.includes('regional_report')) {
      if (!trialCards) { trialCards = [] }
      trialCards.push('regional_report')
      isTrialingMarketTracker = true
    }
    const { hiddenTrialFullData } = this.state
    const clientID = this.props.clientID
    const overallCondition = valuation.overall_condition

    // Share view never loads the user, so just use defaults
    let allCards = null
    const isShared = this.isShared
    if (isShared || !SessionStore.hasAccountOfferings()) {
      allCards = AppConstants.defaultProviderOrder
    } else if (this.isPartnerPreview) {
      allCards = AppConstants.edgePipelinePreviewDefaultProviderOrder
    } else {
      allCards = activeCards.concat(trialCards)
    }

    var providerComponents = {active: [], trial: []}

    for (let providerKey of allCards) {
      if (limitedProviders && !limitedProviders.includes(providerKey)) {
        continue
      }
      const cardKey = `provider-card-${providerKey}`
      const commonProps = {
        vehicleUUID: valuation.uuid,
        clientID: clientID,
        providerKey: providerKey,
        systemData: providers && providers[providerKey] && providers[providerKey]['system'] ? providers[providerKey]['system'] : null,
        fullChoices: PROVIDER_DATA_KEY_STRUCTURE[providerKey] ? this[PROVIDER_DATA_KEY_STRUCTURE[providerKey]['choices']] : null,
        providerData: PROVIDER_DATA_KEY_STRUCTURE[providerKey] ? this[PROVIDER_DATA_KEY_STRUCTURE[providerKey]['data']] : null,
        valuationMileage: valuation.mileage,
        overallCondition: overallCondition,
        onBookout: () => this.setState({
          isShowingBookoutModal: true,
          bookoutSelectedProvider: providerKey
        })
      }

      let isActive = false
      const hasAccountOfferings = SessionStore.hasAccountOfferings() // For free users with no offerings, treat all as active, since they're sold in other ways
      if (limitedProviders || this.isPartnerPreview || activeCards.includes(providerKey) || !hasAccountOfferings) {
        isActive = true
      }

      switch (providerKey) {
        case 'regional_report':
          providerComponents[isTrialingMarketTracker ? 'trial' : 'active'].push( <ModelStats valuation={valuation} key={cardKey} /> )
          break;
        case 'matchup_wholesale':
          if (!this.isPartnerPreview) {
            providerComponents['active'].push( <MatchupWholesale valuation={valuation} key='matchup-ws' /> )
          }
          break;
        case 'matchup_retail':
          if (!this.isPartnerPreview) {
            providerComponents['active'].push( <MatchupRetail valuation={valuation} key='matchup-rt' /> )
          }
          break;
        case 'black_book':
          if (this.blackBookData && this.blackBookData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.blackBookData && !this.blackBookData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationBlackBook {...commonProps} key={cardKey} />)
          }
          break;
        case 'manheim':
          if (this.manheimData && this.manheimData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.manheimData && !this.manheimData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationManheim {...commonProps} key={cardKey} conditionGrade={valuation.grade} />)
          }
          break;
        case 'pin':
          if (this.pinData && this.pinData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.pinData && !this.pinData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationPIN {...commonProps} key={cardKey} />)
          }
          break;
        case 'pmr':
          if (this.pmrData && this.pmrData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.pmrData && !this.pmrData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationPipeline {...commonProps} key={cardKey} conditionGrade={valuation.grade} />)
          }
          break;
        case 'universe':
          if (this.universeData && this.universeData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.universeData && !this.universeData.choices) {
            if (this.universeData.version === 2) {
              providerComponents[isActive ? 'active' : 'trial'].push( <ValuationLLM {...commonProps} vin={this.props.valuationData.vin} key={cardKey} />)
            } else {
              providerComponents[isActive ? 'active' : 'trial'].push( <ValuationUniverse {...commonProps} key={cardKey} />)
            }
          }
          break;
        case 'nada':
          if (this.nadaData && this.nadaData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.nadaData && !this.nadaData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationNADA {...commonProps} key={cardKey} />)
          }
          break;
        case 'kbb':
          if (this.kbbData && this.kbbData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.kbbData && !this.kbbData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationKBB {...commonProps} key={cardKey} />)
          }
          break;
        case 'galves':
          if (this.galvesData && this.galvesData.choices && !isShared) {
            providerComponents[isActive ? 'active' : 'trial'].push(this.renderProviderChoicesCard(providerKey))
          } else if (this.galvesData && !this.galvesData.choices) {
            providerComponents[isActive ? 'active' : 'trial'].push( <ValuationGalves {...commonProps} key={cardKey} />)
          }
          break;
        case 'carfax':
          if (!isShared && !this.isPartnerPreview) {
            providerComponents['active'].push(
              <Carfax
                vehicleID={this.props.valuationData.uuid}
                hasVIN={this.props.valuationData.vin !== null && this.props.valuationData.vin !== undefined}
                key='provider-card-carfax'
                overallCondition={overallCondition}
              />
            )
          }
          break;
        case 'autocheck':
          if (!isShared && !this.isPartnerPreview) {
            // Don't show the card for aux users if they don't have autocheck linked
            providerComponents['active'].push(
              <AutoCheck
                vehicleUUID={valuation.uuid}
                hasVIN={this.props.valuationData.vin !== null && this.props.valuationData.vin !== undefined}
                key='provider-card-autocheck'
              />
            )
          }
          break;
        case 'vintel':
          if (!isShared && !this.isPartnerPreview) {
            providerComponents['active'].push(
              <Vintel
                vehicleUUID={valuation.uuid}
                hasVIN={this.props.valuationData.vin != null}
                key='provider-card-vintel'
              />
            )
          }
          break;
        default:

      }
    }


    if (!valuation.lot_vehicle && SessionStore.hasMyLotAccess()) {
      providerComponents.active.unshift( <ValuationScorecard valuation={valuation} key={'scorecard'} /> )
    }

    // Matchup cards are not present in active list if currently trialing, but after trial the matchups won't be there (single bookers)
    // Add them in after the fact
    const isTrialing = SessionStore.isTrialing()
    if (isTrialing && !SessionStore.qualifiesForMatchup() && SessionStore.hasAccountOfferings()) {
      providerComponents['trial'].unshift( <MatchupRetail valuation={valuation} key='matchup-rt' /> )
      providerComponents['trial'].unshift( <MatchupWholesale valuation={valuation} key='matchup-ws' /> )
    }

    var out = (
      <div>
        {
          SessionStore.valuationProvidersEnabled() !== true && !this.isShared && !this.isPartnerPreview &&
          <div className="secondary-text" style={{marginBottom: '20px'}}>Your account does not have access to valuation data</div>
        }

        {
          !SessionStore.hasAccountOfferings() && SessionStore.isTrialing() &&
          <section className="" style={{marginBottom: '20px', padding: '10px', border: '1px dashed #aaa', borderRadius: '6px', backgroundColor: '#fff', fontSize: '1.1rem'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              <InfoIcon width={40} height={40} style={{marginRight: '10px'}} />
              <div>
                {
                  user.account?.days_until_valuation_trial_expires && user.account.days_until_valuation_trial_expires > -1 &&
                  <div>You have <strong>{pluralize('day', user.account.days_until_valuation_trial_expires, true)} left</strong> of your free preview. During this time, you have access to all Carbly data sources except {AppConstants.providerNames.black_book}.</div>
                }

                <div style={{marginTop: '10px'}}>After your preview ends, you will still have full access to auction run lists.</div>

                {
                  global.isExtensionMode ?
                  <div style={{marginTop: '10px'}}>
                    <a href='/settings/account' target="_blank" rel="noopener noreferrer">Open Carbly Desktop to manage your plan</a></div> :
                  <div style={{marginTop: '10px'}}>
                    <a href='#get-started' onClick={(e) => {
                    e.preventDefault()
                    platformFunctions.logAnalyticsEvent("User", "Free User Show Manage Plan")
                    this.setState({ isShowingFreeUserUpsellModal: true })
                  }}>View pricing and customize your plan</a></div>
                }
              </div>
            </div>
          </section>
        }

        { providerComponents.active.map((activeProvider) => activeProvider) }

        {
          providerComponents.trial.length > 0 && !this.isShared &&
          <div style={{marginTop: '80px'}}>
            {
              hiddenTrialFullData &&
              <section className="card valuation-section">
                <a href="#show-providers" onClick={(e) => {
                  e.preventDefault()
                  this.setState({hiddenTrialFullData: false})
                  SessionStore.setHiddenTrialData(false)
                  platformFunctions.logAnalyticsEvent("Valuations", "Trial Show All Books")
                  }}>

                  <div>
                    <div className="trial-provider-names"></div>
                    <div>Show the full trial preview data</div>
                  </div>
                </a>
              </section>
            }

            {
              !hiddenTrialFullData && SessionStore.hasAccountOfferings &&
              <section className="card valuation-section">
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                  <InfoIcon color='#e95656' style={{marginRight: '10px', marginTop: '-5px'}} />
                  <div>Carbly is currently showing a preview of all available data sources during your free trial below.  Once your trial ends, you will only see the data you've subscribed to.

                  <a href="#hide-trial-data"
                  style={{textAlign: 'center', marginTop: '20px'}}
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({hiddenTrialFullData: true})
                    SessionStore.setHiddenTrialData(true)
                    platformFunctions.logAnalyticsEvent("Valuations", "Trial Hide All Books")
                  }}> Hide the additional data</a>
                  </div>
                </div>

              </section>
            }

            {
              !hiddenTrialFullData &&
              providerComponents.trial.map((activeProvider) => activeProvider)
            }
          </div>
        }
      </div>
    )

    return out
  }


  updateMileage = () => {
    const { modifiedMileage } = this.state
    const valuation = this.props.valuationData

    const normalizedMileage = CommonFunctions.trueMileageFromInput(modifiedMileage)

    var params = {}
    if (parseInt(normalizedMileage) !== valuation.mileage) {
      params['mileage'] = normalizedMileage
    }

    if (params.mileage) {
      this.props.onUpdateMileageAndCondition(params)
    }

    this.setState({
      modifiedMileage: '',
      isShowingMileagePopover: false
    })
  }

  updateCondition = () => {
    const { modifiedCondition } = this.state
    const valuation = this.props.valuationData

    var params = {}
    if (modifiedCondition && modifiedCondition !== valuation.overall_condition) {
      params['overall_condition'] = modifiedCondition
    }

    if (params.overall_condition) {
      this.props.onUpdateMileageAndCondition(params)
    }

    this.setState({
      modifiedCondition: null,
      isShowingMileagePopover: false
    })
  }

  zipCodeChanged = (zipCode, isValid) => {
    this.setState({
      modifiedZipCode: zipCode,
      modifiedZipCodeIsValid: isValid
    })
  }

  updateZipCode = (reset = false) => {
    const { modifiedZipCode } = this.state
    var newZip = modifiedZipCode
    if (reset === true) {
      newZip = null
    }
    const params = {'zip_code': newZip}

    this.props.onUpdateMileageAndCondition(params)
    this.setState({
      modifiedZipCode: '',
      isShowingZipCodePopover: false
    })
  }

  onChoice = (providerKey, selectedChoiceUID) => {
    // Partner preview has its own separate cycle for saving choices
    if (this.props.onChoice) {
      this.props.onChoice(providerKey, selectedChoiceUID)
      return
    }
     const choices = this.state.choices
     const newChoices = ChoicesFunctions.handleChoiceSelection(choices, providerKey, selectedChoiceUID)

     // We're going to update several providers (possibly), each card needs to know not to show choices
     var updatingProviders = []
     for (let key of Object.keys(newChoices)) {
       if (newChoices[key].selectedChoiceUID) {
         updatingProviders.push(key)
       }
     }

     this.setState({
       choices: newChoices,
       choicesUpdatingProviders: updatingProviders
     })

     setTimeout(() => {
       this.choicesComplete(newChoices)
     }, 40)

   }

  choicesComplete = (choices) => {
    const formattedChoices = ChoicesFunctions.formattedChoicesForServer(choices)
    const params = {'updated_selected_valuations': formattedChoices}
    ValuationActionCreators.updateValuation(this.props.valuationData.uuid, params, this.props.clientID)
  }

  renderConditionButtons = () => {
    // FIXME: centralize selected option style
    const valuation = this.props.valuationData
    var buttons = []
    const { modifiedCondition } = this.state

    const conditions = AppConstants.overallConditions
    const selectedConditionKey = modifiedCondition ? modifiedCondition : valuation.overall_condition

    for (let key of Object.keys(conditions)) {
      let condition = conditions[key]
      buttons.push(
        <a href="#select-condition" className={`edit-condition-condition${selectedConditionKey && selectedConditionKey === key ? ' edit-condition-condition-selected' : ''}`} onClick={(e) => {e.preventDefault(); this.setState({modifiedCondition: key})}} key={`cond-button-${key}`}>
          <div style={{fontWeight: 'bold'}}>{condition.icon} {condition.title}</div>
          <div>{condition.description}</div>
        </a>
      )
    }
    return buttons
  }

  assignProvidersData = (providers) => {
    if (!providers) { return }
    const keyStructure = PROVIDER_DATA_KEY_STRUCTURE
    for (let key of Object.keys(PROVIDER_DATA_KEY_STRUCTURE)) {
      const provider = providers[key]
      if (provider) {
        if (provider.results && provider.results.length > 0) {
          this[keyStructure[key]['data']] = provider.results.length === 1 ? provider.results[0] : {choices: provider.results}
          if (provider.full_choices && provider.full_choices.length > 1) {
            this[keyStructure[key]['choices']] = provider.full_choices
          }
        } else if (provider.system || provider.error) {
          this[keyStructure[key]['data']] = provider
        } else {
          this[keyStructure[key]['data']] = null
        }
      }
    }
  }

  onPhotosChange = (photosCount) => {
    this.setState({ updatedPhotosCount: photosCount})
  }

  renderCarfaxShared(carfaxSummary) {
    if (!ValuationFunctions.hasCarfaxPreviewData(carfaxSummary)) return null
    const url = ValuationFunctions.carfaxIsConnect(carfaxSummary) ?
      carfaxSummary.results?.dealerReport?.carfaxLink?.url :
      carfaxSummary.web_url

    if (!url) return null

    return (
      <div className="shared-carfax">
        <a href={url} target="_blank" rel="noopener noreferrer" style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}><img src="/images/carfax-logo.png" alt="CARFAX" style={{height: '22px', width: '107px', marginRight: '5px'}} /><div style={{fontSize: '1.1rem'}}>View CARFAX report</div></a>
      </div>
    )
  }

  renderBody = () => {
    const valuation = this.props.valuationData
    const providers = valuation.full_valuations

    const carbly = providers?.carbly
    this.carblyData = carbly?.results

    // We might be showing a shared valuation that is skipping the carbly summary
    const limitedProviders = this.props.limitProviders
    var showSummaryIfAvailable = true
    if (limitedProviders && !limitedProviders.includes('carbly')) {
      showSummaryIfAvailable = false
    }

    this.assignProvidersData(providers)

    const user = SessionStore.user
    const owner = this.isShared || this.isPartnerPreview ? null : SessionStore.getActivityUser(valuation?.user?.uuid)
    const isVehicleOwner = owner?.uuid && owner.uuid === user.uuid

    const { isShowingMileagePopover, modifiedMileage, modifiedCondition, isShowingConditionPopover, isShowingZipCodePopover, modifiedZipCode, modifiedZipCodeIsValid, choicesCanBeReset, isShowingChoiceResetModal, isShowingExtendedDetails, isShowingAuctionDetailsModal, isShowingRetailDetailsModal, isShowingLLMUpsellModal, isShowingShareModal, updatedPhotosCount, isShowingLightbox, isShowingBookoutModal, similarVehicesAtAuction, isShowingAuctionsPlusUpsellModal, isShowingLotVehicleTransactionDataModal } = this.state

    const extendedVehicleDetails = ValuationFunctions.extendedVehicleDetails(valuation)
    const hasExtendedDetails = extendedVehicleDetails && extendedVehicleDetails.length > 0
    const vehicleNotes = valuation.vehicle_notes
    const vehiclePhotos = valuation.vehicle_photos
    const showAuctionMatch = valuation.runlist_listing !== null && this.props.hideAuctionMatch !== true && !this.isShared && !global.isIFrameMode && !this.isPartnerPreview
    const showRetailMatch = valuation.retail_listing && !this.isShared && !global.isIFrameMode && SessionStore.valuationProvidersEnabled()
    const hideNotes = this.props.hideNotes === true
    const hidePhotos = this.props.hidePhotos === true || (this.isShared && vehiclePhotos.length === 0)
    const hideShare = this.props.hideShare === true || SessionStore.valuationProvidersEnabled() === false
    const zipCodeDiffers = !this.isShared && !this.isPartnerPreview && valuation.zip_code && SessionStore.userZipCode() && valuation.zip_code !== SessionStore.userZipCode()
    const zipSubmitDisabled = modifiedZipCode.length !== 5 || (modifiedZipCode.length === 5 && !modifiedZipCodeIsValid)
    const isEmbedded = this.props.isEmbedded === true
    const auctionListingProvider = this.props.auctionListingProvider

    const showSimilarVehiclesAtAuctionMatch = similarVehicesAtAuction?.length > 0 && !this.isShared && !global.isIFrameMode && !this.isPartnerPreview
    const similarVehiclesAtAuctionCount = showSimilarVehiclesAtAuctionMatch ? AuctionStore.similarVehiclesSearchResultsTotalCount : 0
    const similarVehiclesAtAuctionThumbnail = showSimilarVehiclesAtAuctionMatch ? similarVehicesAtAuction.find(listing => listing.thumbnail)?.thumbnail : null

    var photosCount = 0
    if (updatedPhotosCount) {
      photosCount = updatedPhotosCount
    } else if (vehiclePhotos.length > 0) {
      photosCount = vehiclePhotos.length
    }

    const parentAuctionListing = this.props.parentAuctionListing
    const ymm = parentAuctionListing ? `${parentAuctionListing.year} ${parentAuctionListing.make} ${parentAuctionListing.model}` : `${valuation.year} ${valuation.make} ${valuation.model !== null ? valuation.model : ''}`
    var milesHUDString = ''
    if (parentAuctionListing && parentAuctionListing.mileage) {
      const mileageInt = parseInt(parentAuctionListing.mileage, 10)
      milesHUDString = `${Formatters.formatThousands(mileageInt)} ${pluralize('mile', mileageInt, false)}`
    } else if (valuation.mileage) {
      const mileageInt = parseInt(valuation.mileage, 10)
      milesHUDString = `${Formatters.formatThousands(mileageInt)} ${pluralize('mile', mileageInt, false)}`
    }
    const conditionValueString = valuation.overall_condition ? AppConstants.overallConditions[valuation.overall_condition]['title'] : ''
    const zipCodeValueString = valuation.zip_code ? valuation.zip_code : null

    var hudItems = []
    if (global.isExtensionMode) {
      hudItems = [ymm, milesHUDString]
    } else if (global.isIFrameMode) {
      hudItems = []
    } else {
      if (this.props.parentAuctionListing) {
        hudItems = [ymm, milesHUDString]
      } else {
        hudItems = [ymm, milesHUDString, conditionValueString, zipCodeValueString]
      }
    }

    return (
      <div className="body-content" ref="mainRef">
        {
          !this.isShared && !this.isPartnerPreview && !isShowingLightbox && hudItems && hudItems.length > 0 && !isShowingChoiceResetModal &&
          <ValuationHUD items={hudItems} offset={isEmbedded ? 1400 : null}/>
        }

        {
          isShowingRetailDetailsModal &&
          <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '400px', maxWidth: '1000px'}} backdrop={true} toggle={() => {this.setState({ isShowingRetailDetailsModal: false })}}>
            <ModalBody>
              <div>
                <LLMSampleDetail sampleDetail={valuation.retail_listing} />
                <div className="modal-controls" style={{marginTop: '20px'}}>
                  <Button color="secondary" style={{marginLeft: 0}} onClick={() => {
                    this.setState({
                      isShowingRetailDetailsModal: false
                    })
                    }}>Close</Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        }

        {
          isShowingLLMUpsellModal &&
          <OfferingUpsellModal
            offering={{sku: AppConstants.skuMappings['universe'], provider_key: 'universe'}}
            onDone={() => this.setState({ isShowingLLMUpsellModal: false})}
            onPurchase={() => {
              this.setState({ isShowingUpsellModal: false })
              if (this.props.onReloadValuation) {
                this.props.onReloadValuation()
              } else {
                window.location.reload()
              }
            }} />
        }

        {
          isShowingAuctionDetailsModal &&
          <AuctionsListingShowModal
            listingsContext={null}
            listingID={this.state.auctionDetailsModalListing.id}
            history={this.props.history}
            showValuation={this.state.auctionDetailsModalShowValuation}
            onDone={() => {
              this.setState({ isShowingAuctionDetailsModal: false })
            }} />
        }

        {
          !isEmbedded &&
          <div className="content-header" style={{alignItems: 'flex-start'}}>
            <div>
              <h1>{ymm}</h1>
              <h2 style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>{Formatters.seriesStyleString(valuation.series, valuation.style)}
                {
                  hasExtendedDetails &&
                  <a href="#show-details" onClick={(e) => {e.preventDefault(); this.setState({ isShowingExtendedDetails: true })}}><sup style={{marginLeft: '5px'}}>i</sup></a>
                }
                {
                  Boolean(valuation.exterior_color) &&
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '20px', fontSize: '0.85rem', flexGrow: 1, flexShrink: 1}}>
                    {
                      Boolean(valuation.exterior_color_hex) &&
                      <div style={{backgroundColor: valuation.exterior_color_hex, width: '15px', height: '15px', marginRight: '5px', borderRadius: '4px'}}></div>
                    }
                    { valuation.exterior_color }
                    {
                      Boolean(valuation.interior_color) &&
                      <div>&nbsp;/ { valuation.interior_color }</div>
                    }
                  </div>
                }
                {
                  Boolean(valuation.engine_description) &&
                  <a href="#show-details" style={{color: '#888'}} onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingExtendedDetails: true})
                  }}>
                    <div style={{marginLeft: '20px', fontSize: '0.85rem', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', flexGrow: 1, flexShrink: 1}}>
                      {valuation.engine_description.length > 50 ? `${valuation.engine_description.substring(0, 50)}...` : valuation.engine_description}
                    </div>
                  </a>
                }
              </h2>

            </div>

            {
              user &&
              <div className="global-tools">
                <AccountBug />
              </div>
            }
          </div>
        }
        {
          isEmbedded &&
          <div className="content-header">
            <div>
              <h1>Carbly Data</h1>
              <div>{ymm}</div>
              <div className='secondary-text'>{Formatters.seriesStyleString(valuation.series, valuation.style)}</div>
            </div>
          </div>
        }

        {
          !this.isShared && !this.isPartnerPreview &&
          <div className="content-context-actions">
            {
              !hideShare &&
              <span>
              <a href="#share" onClick={(e) => {
                e.preventDefault()
                this.setState({ isShowingShareModal: true })
                }}>
                <svg className="bi bi-person-plus-fill" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm7.5-3a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"/>
                  <path fillRule="evenodd" d="M13 7.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0v-2z"/>
                </svg>
              Share</a> |
              </span>
            }

            {
              SessionStore.valuationProvidersEnabled() &&
                <span style={{paddingLeft: !hideShare ? '12px' : 0}}>
                  <a href="#bookout" onClick={(e) => {
                    e.preventDefault()
                    this.setState({ isShowingBookoutModal: true })
                    }}>
                    <BookoutIcon color='currentColor' width='1em' height='1em' />
                  Bookout</a> | </span>
            }


            <span style={{paddingLeft: '12px'}}>
              <a href="#print" onClick={(e) => {
              e.preventDefault()
              window.print()
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"/>
                <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
              </svg>
            Print</a> |</span>


            {
              AuctionFunctions.shouldShowSimilarVehiclesForProvider(auctionListingProvider) &&
              <span style={{paddingLeft: '12px'}}>
                <a href={`${this.similarVehiclesLink(valuation)}`} target="_blank" rel="noopener noreferrer">
                  <MagnifyingGlassIcon color="currentColor" />
                Search for similar</a> |
              </span>
            }



            <FolderAssignmentPopover valuation={valuation} clientID={this.props.clientID} disabled={!isVehicleOwner} />
          </div>
        }


        <div className="valuation-header">
          {
            valuation.vin !== null &&
            <div><div className="label">VIN</div>{valuation.vin}
              {
                !this.isPartnerPreview &&
                  <CopyToClipboard
                  text={valuation.vin}
                  onCopy={() => {
                    toast.success("VIN Copied to Clipboard")
                  }}
                  >
                  <div className="label" style={{marginLeft: '8px', color: '#2f323e', cursor: 'pointer'}}>
                  <ClipboardIcon />
                  </div>
                </CopyToClipboard>
              }
            </div>
          }


          {
            !this.isShared && !this.isPartnerPreview &&
            <div>
              <span className="label">Mileage</span><a href="#change-mileage" onClick={(e) => e.preventDefault()} id="mileage_popover">{Formatters.formatThousands(parseInt(valuation.mileage, 10))}</a>

              <Popover
                placement="bottom"
                isOpen={isShowingMileagePopover}
                trigger="legacy"
                target="mileage_popover"
                toggle={() => {
                    this.setState({ isShowingMileagePopover: !isShowingMileagePopover })
                    setTimeout(() => {
                      if (this.refs.mileageRef) {
                        this.refs.mileageRef.focus()
                      }
                    }, 200)
                  }
                }

                >
                <PopoverHeader>Change Mileage</PopoverHeader>
                <PopoverBody className='popover-med'>
                  <form onSubmit={(e) => {e.preventDefault(); if (modifiedMileage && modifiedMileage.length > 0) {this.updateMileage()}}}>
                    <MileageEntryField ref='mileageRef' onMileageChange={(mileage) => this.setState({ modifiedMileage: mileage })} />
                    <div className='popover-controls'>
                      <Button color="secondary" onClick={() => this.setState({isShowingMileagePopover: false, modifiedMileage: ''})}>Cancel</Button>
                      <Button color="primary" onClick={this.updateMileage} disabled={!modifiedMileage || modifiedMileage.length === 0}>Update</Button>
                    </div>
                  </form>
                </PopoverBody>
              </Popover>
            </div>
          }

          {
            (this.isShared || this.isPartnerPreview) &&
            <div><span className="label">Mileage</span>{Formatters.formatThousands(parseInt(valuation.mileage, 10))}</div>
          }

          {
            !this.isShared && !this.isPartnerPreview &&
            <div>
              <span className="label">Condition</span><a href="#change-condition" onClick={(e) => e.preventDefault()} id="condition_popover">{conditionValueString}</a>

              <Popover placement="bottom" isOpen={isShowingConditionPopover} trigger="legacy" target="condition_popover" toggle={() => this.setState({ isShowingConditionPopover: !isShowingConditionPopover })}>
                <PopoverHeader>Change Condition</PopoverHeader>
                <PopoverBody className='popover-med'>
                  { this.renderConditionButtons() }
                  <div className='popover-controls'>
                    <Button color="secondary" onClick={() => this.setState({isShowingConditionPopover: false})}>Cancel</Button>
                    <Button color="primary" onClick={this.updateCondition} disabled={modifiedCondition === null || modifiedCondition === valuation.overall_condition}>Update</Button>
                  </div>
                </PopoverBody>
              </Popover>
            </div>
          }

          {
            !this.isShared && !this.isPartnerPreview && SessionStore.canOverrideZip() &&
            <div>
              <span className="label">In ZIP Code</span><a href="#change-zip-code" onClick={(e) => e.preventDefault()} id="zip_code_popover" className={`${zipCodeDiffers ? 'warn' : ''}`}>{valuation.zip_code ? valuation.zip_code : 'None'}</a>

              <Popover
                placement="bottom"
                isOpen={isShowingZipCodePopover}
                trigger="legacy"
                target="zip_code_popover"
                toggle={() => {
                  this.setState({ isShowingZipCodePopover: !isShowingZipCodePopover })
                  setTimeout(() => {
                    if (this.refs.zipRef) {
                      this.refs.zipRef.focus()
                    }
                  }, 200)
                }}>
                <PopoverHeader>Change Valuation ZIP Code</PopoverHeader>
                <PopoverBody className='popover-med'>
                  {
                    zipCodeDiffers === true && isVehicleOwner &&
                    <p>The ZIP code used for this valuation was changed from your default ZIP code. <a href="#reset" onClick={(e) => {e.preventDefault(); this.updateZipCode(true)}}>Reset ZIP code to {SessionStore.userZipCode()}</a></p>
                  }
                  <form onSubmit={(e) => {e.preventDefault(); if (!zipSubmitDisabled) {this.updateZipCode()}}}>
                    <ZipCodeEntryField ref='zipRef' placeholder={SessionStore.userZipCode() ? SessionStore.userZipCode() : 'ZIP code'} onZipCodeChange={this.zipCodeChanged} />
                    <div className='popover-controls'>
                      <Button color="secondary" onClick={() => this.setState({modifiedZipCode: '', isShowingZipCodePopover: false})}>Cancel</Button>
                      <Button color="primary" onClick={() => this.updateZipCode()} disabled={zipSubmitDisabled}>Update</Button>
                    </div>
                  </form>
                </PopoverBody>
              </Popover>
            </div>
          }

          {
            (this.isShared || this.isPartnerPreview) && zipCodeValueString &&
            <div><span className="label">In ZIP Code</span>{zipCodeValueString}</div>
          }

          {
            (this.isShared || this.isPartnerPreview) && valuation.overall_condition &&
            <div><span className="label">Condition</span>{conditionValueString}</div>
          }

        </div>

        {
          global.isPartnerPreview &&
          <div className="partner-preview-access-message" style={{marginBottom: '20px'}}>
            With a <a href="https://getcarbly.com/register/?r=edge" target="_blank">Carbly account</a>, you can modify mileage, ZIP code, condition, options, trim, assign to folders, share with other people and much more.
          </div>
        }


        {
          SessionStore.hasMyLotAccess() && !global.isPartnerPreview && !this.isShared && !global.isExtensionMode && valuation.lot_vehicle &&
          <LotVehicle valuation={valuation} />
        }


        <div className="valuation-body">
          <div className="valuation">
            {
              Boolean(showAuctionMatch || showRetailMatch || showSimilarVehiclesAtAuctionMatch) &&
              <div className={`vehicle-found-external-c ${global.isExtensionMode ? 'vehicle-found-external-extension-c' : ''}`}>
                {
                  showAuctionMatch &&
                  <div className='vehicle-found-external'>
                    <div className="vehicle-found-external-type-label">{valuation.runlist_listing.auction_in_past ? 'Vehicle recently at auction' : 'Vehicle is at auction'}</div>

                    <a href="#show-auction-listing" className="vehicle-found-external" onClick={(e) => {
                      e.preventDefault()
                      this.setState({
                        isShowingAuctionDetailsModal: !isShowingAuctionDetailsModal,
                        auctionDetailsModalListing: valuation.runlist_listing,
                        auctionDetailsModalShowValuation: false
                      })
                      UserActionCreators.createMetric(AppConstants.metricNames['found_at_auction_show'])
                    }}>
                      {
                        valuation.runlist_listing.thumbnail &&
                        <div className='photo-container' style={{backgroundImage: `url(${valuation.runlist_listing.thumbnail})`}} />
                      }
                      <div className="external-details">
                        {
                          valuation.runlist_listing?.auction?.type === 'digital' &&
                          <div className="external-location">
                            {
                              valuation.runlist_listing.auction.name &&
                              <div style={{fontWeight: 'bold'}}>{valuation.runlist_listing.auction.name}</div>
                            }
                            <div>{AppConstants.auctionOnlineSourceNames[valuation.runlist_listing.auction.code]}</div>
                          </div>
                        }
                        {
                          valuation.runlist_listing?.auction?.type === 'physical' &&
                          <div className="external-location">
                            <div style={{fontWeight: 'bold'}}>{valuation.runlist_listing.auction.name}</div>
                            <div>
                              {moment(valuation.runlist_listing.start_time).format('ddd MM/DD')}
                            </div>
                          </div>
                        }
                        {
                          valuation.runlist_listing.salvage === true &&
                          <div className="salvage">Salvage</div>
                        }
                      </div>
                      <PlusIcon width={20} height={20} />

                    </a>
                  </div>
                }

                {
                  showRetailMatch &&
                  <div className='vehicle-found-external'>
                    <div className="vehicle-found-external-type-label">Vehicle {valuation.retail_listing.last_seen && moment().diff(moment(valuation.retail_listing.last_seen), 'days') < 7 ? 'found' : 'recently'} at retail</div>

                    <a href="#show-auction" onClick={(e) => {
                      e.preventDefault()
                      if (SessionStore.hasLLMAccess()) {
                        UserActionCreators.createMetric(AppConstants.metricNames['llm_found_at_retail_show'])

                        this.setState({
                          isShowingRetailDetailsModal: !isShowingRetailDetailsModal
                        })
                      } else {
                        UserActionCreators.createMetric(AppConstants.metricNames['llm_found_at_retail_upsell'])
                        this.setState({
                          isShowingLLMUpsellModal: true
                        })
                      }
                    }}>
                      {
                        valuation.retail_listing.primary_photo_url &&
                        <div className='photo-container' style={{backgroundImage: `url(${`${ConnectivityStore.apolloApiRoot}${valuation.retail_listing.primary_photo_url}`})`}} />
                      }
                      <div className="external-details">
                        <div className="external-location">
                          {
                            SessionStore.hasLLMAccess() ?
                            <div>
                              <div style={{fontWeight: 'bold'}}>{valuation.retail_listing.dealer_name}</div>
                              {
                                Boolean(valuation.retail_listing.price) &&
                                <div>Asking {Formatters.formatCurrency(valuation.retail_listing.price)}</div>
                              }
                            </div> : <div>{AppConstants.providerNames.universe} found this vehicle for sale</div>
                          }

                        </div>
                      </div>
                      <PlusIcon width={20} height={20} />
                    </a>
                  </div>
                }

                {
                  showSimilarVehiclesAtAuctionMatch &&
                  <div className='vehicle-found-external'>
                    <div className="vehicle-found-external-type-label">Similar at nearby auctions</div>

                    <a href={this.similarVehiclesLink(valuation)} target="_blank" rel="noopener noreferrer">
                      {
                        similarVehiclesAtAuctionThumbnail &&
                        <div className='photo-container' style={{backgroundImage: `url(${similarVehiclesAtAuctionThumbnail})`}} />
                      }
                      <div className="external-details">
                        <div className="external-location">
                        {similarVehiclesAtAuctionCount} similar at auction
                        </div>
                      </div>
                      <ExternalLinkIcon width={20} height={20} />
                    </a>
                  </div>
                }
              </div>
            }

            { this.isShared && this.props.hideCarfax !== true && this.renderCarfaxShared(valuation.carfax_summary) }

            {
              this.renderProviders(valuation)
            }

            {
              !SessionStore.hasBookOfferings() && SessionStore.hasFeatureOfferings() && !SessionStore.isAuxUser() &&
              <AccountNeedsAction />
            }

            {
              choicesCanBeReset === true && !this.isShared && !this.isPartnerPreview &&
              <div style={{margin: '20px 0', align: 'center'}}>
                <a href="#reset-choices" onClick={(e) => {e.preventDefault(); this.setState({ isShowingChoiceResetModal: true })}}>Reset Vehicle Configuration</a>

                <Modal isOpen={isShowingChoiceResetModal} toggle={() => this.setState({ isShowingChoiceResetModal: !isShowingChoiceResetModal})}>
                  <ModalHeader toggle={this.toggle}>Select Configuration</ModalHeader>
                  <ModalBody>
                    <ValuationChoiceReset vehicleID={valuation.uuid} onNewChoicesSaved={() => {
                      this.setState({ isShowingChoiceResetModal: false })
                      if (this.props.onReloadValuation) {
                        this.props.onReloadValuation(valuation.uuid)
                      } else {
                        window.location.reload()
                      }
                      }}/>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={() => {this.setState({ isShowingChoiceResetModal: false })}}>Cancel</Button>
                  </ModalFooter>
                </Modal>
              </div>
            }

            {
              global.isExtensionMode &&
              <a href="#logout" onClick={(e) => {e.preventDefault(); SessionActionCreators.logout();}}>Logout of Carbly</a>
            }

            {
              isShowingExtendedDetails &&
              <Modal centered={true} isOpen={isShowingExtendedDetails} toggle={() => this.setState({ isShowingExtendedDetails: !isShowingExtendedDetails})} style={{maxHeight: '200px'}}>
                <ModalHeader toggle={this.toggle}>Extended Details</ModalHeader>
                <ModalBody>
                  <div style={{maxHeight: '70vh', overflowY: 'auto'}}>
                  {
                    extendedVehicleDetails.map(function (detail) {
                      return (
                        <div style={{marginBottom: '10px'}} key={`extended-detail-${detail.value}`}>
                          <div style={{fontWeight: 'bold', color: '#aaa', fontSize: '0.75rem', textTransform: 'uppercase', lineHeight: '0.8rem'}}>{detail.label}</div>
                          <div style={{fontSize: '0.9rem'}}>{detail.value}</div>
                        </div>
                      )
                    })
                  }
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={() => {this.setState({ isShowingExtendedDetails: false })}}>Close</Button>
                </ModalFooter>
              </Modal>
            }

            {
              isShowingShareModal &&
              <ValuationShareModal onCancel={() => this.setState({ isShowingShareModal: false })} vehicleUUID={valuation.uuid} clientID={this.props.clientID} hasCarblyData={this.carblyData !== null} hasCarfaxPreview={this.state.hasCarfaxPreview}/>
            }

            {
              isShowingBookoutModal &&
              <Bookout vehicle={valuation} onCancel={() => {
                this.setState({
                  isShowingBookoutModal: false,
                  bookoutSelectedProvider: null
                })
              }} defaultProviderKey={this.state.bookoutSelectedProvider} />
            }

            <div className="valuation-footer"></div>

          </div>


          <div className="valuation-sidebar">
            {
              SessionStore.hasMyLotAccess() && !valuation.lot_vehicle && !this.isShared && !this.isPartnerPreview &&
              <Button className='my-lot-button' onClick={() => {
                this.setState({
                  isShowingLotVehicleTransactionDataModal: true
                })
                }}>
                  <PlusIcon width={20} height={20} style={{marginRight: '8px'}} />
                  Put on My Lot</Button>
            }
            {
              !isVehicleOwner && !this.isShared && !this.isPartnerPreview && valuation?.state !== 'invisible' &&
              <div className="card valuation-owner">
                <Avatar
                  user={valuation.user}
                  size={AppConstants.avatarSizes.small}
                  className="owner-avatar"
                />

                <div className="owner-desc">
                  Vehicle created by {valuation.user.full_name}
                </div>
              </div>
            }
            {
              // Don't show for aux users, since this might come back for them
              this.carblyData && showSummaryIfAvailable && (this.isShared || this.isPartnerPreview || SessionStore.valuationProvidersEnabled()) &&
              <ValuationSummary
                providerData={this.carblyData}
                vehicleID={this.props.valuationData.uuid}
                isShared={this.isShared}
                key={this.props.clientID}
              />
            }


            {
              !this.isShared && !this.isPartnerPreview && !global.isExtensionMode &&
              <div className='card embedded-calculator-c'>
                <Calculator
                  valuation={valuation}
                  marketPrice={this.universeData?.description?.mileage_adjusted_price}
                  wholesalePriceEstimate={this.carblyData?.summary?.wholesale?.appraisal}
                  retailPriceEstimate={this.carblyData?.summary?.retail?.appraisal}
                  auctionListingCRScore={this.props.auctionListingCRScore}
                  auctionListingLongitude={this.props.auctionListingLongitude}
                  auctionListingLatitude={this.props.auctionListingLatitude}
                  hasAuctionListing={valuation.runlist_listing !== null && valuation.runlist_listing !== undefined}
                  isEmbedded />
              </div>
            }

            {
              !this.isShared && !this.isPartnerPreview && valuation.partners && valuation.partners.length > 0 &&
              <ValuationPartners valuation={valuation} clientID={this.props.clientID} />
            }

            {
              !this.isShared && !this.isPartnerPreview &&
              <div className="card">
                <h2>Target Bid Price</h2>
                <TargetPrice
                  vehicleUUID={valuation.uuid}
                  clientID={this.props.clientID}
                  onSetTargetPrice={this.props.onSetTargetPrice}
                  targetPrice={valuation.structured_data?.target_bid_price} />
              </div>
            }

            {
              !hidePhotos &&
              <div className="card">
                <h2>{photosCount > 0 ? pluralize('Photo', photosCount, true) : 'Photos'}</h2>
                <ValuationPhotos vehicleUUID={this.props.valuationData.uuid} clientID={this.props.clientID} vehiclePhotos={vehiclePhotos} onLightboxToggle={(isShowingLightbox) => {
                  this.setState({ isShowingLightbox: isShowingLightbox })
                }
                }/>
              </div>
            }
            {
              !hideNotes &&
              <div className="card">
                <h2>{vehicleNotes.length > 0 ? pluralize('Note', vehicleNotes.length, true) : 'Notes'}</h2>
                <div>
                  <ValuationNotes vehicleNotes={vehicleNotes} vehicleUUID={this.props.valuationData.uuid} clientID={this.props.clientID} vehiclePhotos={vehiclePhotos}/>
                </div>
              </div>
            }



            {
              showSimilarVehiclesAtAuctionMatch && !this.props.isModal && !global.isExtensionMode &&
              <div className='similar-vehicles-preview-c'>
                <h2>{similarVehiclesAtAuctionCount} similar at nearby auctions</h2>
                <div className='similar-vehicles-preview-grid' style={{alignItems: 'center'}}>
                  {
                    similarVehicesAtAuction.map((listing, index) => {
                      return (
                        <a key={`sim-${listing.id}`} href="#show-auction-listing" onClick={(e) => {
                          e.preventDefault()
                          if (!SessionStore.hasAuctionsPlus()) {
                            this.setState({ isShowingAuctionsPlusUpsellModal: true })
                          } else {
                            this.setState({
                              isShowingAuctionDetailsModal: !isShowingAuctionDetailsModal,
                              auctionDetailsModalListing: listing,
                              auctionDetailsModalShowValuation: true
                            })
                          }
                        }}>

                          <div>
                            <div style={{width: '100%', height: '110px', backgroundImage: `url(${listing.thumbnail ? listing.thumbnail : '/images/missing-photo-placeholder.png'})`, backgroundSize: 'cover', backgroundPosition: '50% 50%', borderRadius: '4px'}} />
                          </div>

                        <div style={{color: '#aaa', marginBottom: '-5px'}}>{listing.year}</div>
                        <div>{listing.model}</div>
                        {
                          Boolean(listing.mileage) &&
                          <div className='secondary-text' style={{lineHeight: '0.9rem'}}>{Formatters.formatThousands(listing.mileage)} miles</div>
                        }
                        {
                          Boolean(listing?.auction?.name) &&
                          <div className='secondary-text'>{listing.auction.name}</div>
                        }
                      </a>
                      )

                    })
                  }

                </div>
                {
                  similarVehiclesAtAuctionCount > MAX_SIMILAR_VEHICLES_AT_AUCTION && SessionStore.hasAuctionsPlus() &&
                  <a href={this.similarVehiclesLink(valuation)} target="_blank" rel="noopener noreferrer" style={{display: 'block', border: '1px solid #ddd', borderRadius: '80px', padding: '10px', textAlign: 'center'}}>View all similar vehicles <ExternalLinkIcon /> </a>
                }
              </div>
            }

            {
              isShowingAuctionsPlusUpsellModal &&
              <AuctionsPlusUpsellModal
                onClose={() => {
                  this.setState({ isShowingAuctionsPlusUpsellModal: false })
                }}
                onDone={() => {
                  this.setState({ isShowingAuctionsPlusUpsellModal: false })
                }}
                />
            }
          </div>
        </div>

        {
          global.isExtensionMode && !this.state.hasSavedFromExtension && valuation.state !== 'active' &&
          <a href="#save" style={{zIndex: 9999}} className="extension-save-button" onClick={(e) => {
            e.preventDefault()
            this.setState({ hasSavedFromExtension: true })
            ValuationActionCreators.updateValuation(valuation.uuid, {state: 'active'}, this.clientID)
            platformFunctions.logAnalyticsEvent("Valuations", "Saved from Extension")
          }}>
            Save to Carbly
          </a>
        }

        {
          this.state.isShowingFreeUserUpsellModal &&
          <OfferingsUpsellModal
            onCancel={() => this.setState({ isShowingFreeUserUpsellModal: false })}
            offeringsAdded={() => {
              setTimeout(() => {
                if (this.props.onReloadValuation) {
                  this.props.onReloadValuation(valuation.uuid)
                } else {
                  window.location.reload()
                }
              }, 2000)
            }}
            />
        }
        {
          isShowingLotVehicleTransactionDataModal &&
          <VehicleTransactionDataFormModal
            onCancel={() => this.setState({ isShowingLotVehicleTransactionDataModal: false })}
            onUpdate={() => {
              setTimeout(() => {
                if (this.props.onReloadValuation) {
                  this.props.onReloadValuation(valuation.uuid)
                } else {
                  window.location.reload()
                }
              }, 2000)
            }}
            vehicle={{ uuid: valuation.uuid, year: valuation.year, make: valuation.make, model: valuation.model }}
            clientID={this.props.clientID}
          />
        }

      </div>

    )
  }

  render() {
    if (!SessionStore.valuationsEnabled() && !this.isShared && !this.isPartnerPreview) {
      return (
        <div style={{margin: '2rem 20px'}}>
          <div style={{}}>{AppConstants.valuationsDisabledTitle}</div>
          <div style={{}}>{SessionStore.noValuationsMessage()}</div>
          {
            global.isIFrameMode &&
            <div style={{textAlign: 'center', marginTop: '20px'}}>
              <a href='/' target="_blank" rel="noopener noreferrer">Visit Carbly Desktop to manage your account <ExternalLinkIcon /></a>
            </div>
          }
        </div>
      )
    }

    return (
      this.renderBody()
    )
  }
}
