import { useState } from 'react'
import useDidMountEffect from './useDidMountEffect'
import usePrevious from '../../hooks/usePrevious'

export default (value, shouldReset = false) => {
  const [valueBecameTrue, setValueBecameTrue] = useState(false)
  const prevValue = usePrevious(value)

   useDidMountEffect(() => {
    if (shouldReset) {
      setValueBecameTrue(false)
    } else if (value && !prevValue) {
      setValueBecameTrue(true)
    }
  }, [value, prevValue, shouldReset])

  return valueBecameTrue
}
