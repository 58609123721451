import { EventEmitter } from 'events'
import Dispatcher from '../actions/Dispatcher'
import moment from 'moment'
import { memoize } from 'lodash'
import decodeToken from "jwt-decode"
import SessionActionCreators from '../actions/SessionActionCreators'
import UserActionCreators from '../actions/UserActionCreators'
import AuctionsActionCreators from '../actions/AuctionsActionCreators'
import platformFunctions from '../../platformFunctions'
import AppConstants from '../constants/AppConstants'
import RemoteConstants from '../constants/RemoteConstants'
var ActionTypes = RemoteConstants.ActionTypes
import DotAuctionEventsCollection from '../constants/DotAuctionEventsCollection'

// appraisalProvidersOrder can be deprecated later.  3.3 will remove the use of it except for migration

const keyNames = {
  accessToken: 'session_accessToken',
  uid: 'session_uid',
  client: 'session_client',
  user: 'session_user',
  lastSelectedTab: 'settings_last_tab',
  hasShownScannerTips: 'settings_shown_scanner_tips',
  scannerTorchModeOn: 'settings_scanner_torch_mode_on',
  hasShownSettingsBadge: 'settings_shown_settings_badge',
  appraisalProvidersOrder: 'settings_appraisal_providers_order',
  hiddenAddDeducts: 'settings_hidden_add_deducts',
  vehiclesHomeRecentFilter: 'settings_vehicles_home_recent_filter',
  vehiclesAllFilter: 'settings_vehicles_all_filter',
  watchlistFilter: 'settings_watchlist_filter',
  latestViewedNewsVersion: 'settings_latest_viewed_news',
  hiddenMatchupWholesale: 'settings_matchup_hidden_wholesale',
  hiddenMatchupRetail: 'settings_matchup_hidden_retail',
  hiddenTrialFullData: 'settings_trial_full_hidden',
  showPipelineDetailedSamples: 'settings_pipeline_detailed_samples',
  hiddenPipelineSamples: 'settings_pipeline_samples_hidden',
  hiddenLLMSamples: 'settings_llm_samples_hidden',
  hiddenLLMTrims: 'settings_llm_trims_hidden',
  showCarfaxHistoryValue: 'settings_carfax_history_value',
  showAuctionConditionReport: 'settings_auction_cr',
  auctionListingsDisplayType: 'settings_auction_display_type',
  auctionListingsLaneDisplayType: 'settings_auction_lane_display_type',
  hiddenAuctionsSearchFilters: 'settings_auction_filters_hidden',
  hiddenAuctionsSearchVehicleDetails: 'settings_auction_vehicle_details_hidden',
  regionalReportZipCode: 'settings_rr_zipcode',
  regionalReportRadius: 'settings_rr_radius',
  regionalReportDealerType: 'settings_rr_dealer_type',
  lastSelectedDigitalAuctions: 'settings_last_digital_auction_selections',
  hiddenEmbeddedCalculator: 'settings_hidden_embedded_calculator',
  myLotCompetitorsScope: 'settings_my_lot_competitors_scope',
}

class SessionStore extends EventEmitter {
  constructor() {
    super()
    this.setMaxListeners(15)
    this.userHasSessionControl = true
    this.lastUserUpdatedAt = null
    this.addUserErrors = null
    this.upgradePricing = null
    this.news = null
    this.newsNotification = null
    this.hiddenMatchupWholesale = false
    this.hiddenMatchupRetail = false
    this.hiddenTrialFullData = false
    this.showPipelineDetailedSamples = false
    this.hiddenPipelineSamples = false
    this.hiddenLLMSamples = false
    this.hiddenLLMTrims = false
    this.updateCCErrorMessage = null
    this.accountReactivationErrorMessage = null
    this.yearlyBillingSwitchErrorMessage = null
    this.addOfferingsErrorMessage = null
    this.retryCCErrors = null
    this.showCarfaxHistoryValue = false
    this.showAuctionConditionReport = true
    this.auctionListingsDisplayType = null
    this.auctionListingsLaneDisplayType = null
    this.hiddenAuctionsSearchFilters = false
    this.hiddenAuctionsSearchVehicleDetails = false
    this.edgeAuthorizeURL = null
    this.auctionListingBids = null
    this.regionalReportZipCode = null
    this.regionalReportRadius = null
    this.regionalReportDealerType = null
    this.lastSelectedDigitalAuctions = null
    this.hiddenEmbeddedCalculator = false
    this.myLotCompetitorsScope = null
  }
  loadData() {
    Promise.all([
      platformFunctions.getLocalStorageValue(keyNames.accessToken).then((accessToken) => {
        this.accessToken = this.accessToken || accessToken
      }),

      platformFunctions.getLocalStorageValue(keyNames.uid).then((uid) => {
        this.uid = this.uid || uid
      }),

      platformFunctions.getLocalStorageValue(keyNames.client).then((client) => {
        this.client = this.client || client
      }),
      platformFunctions.getLocalStorageValue(keyNames.user).then((user) => {
        this.user = this.user || JSON.parse(user)
      }),

      platformFunctions.getLocalStorageValue(keyNames.lastSelectedTab).then((value) => {
        this.lastSelectedTab = value ? value : "NONE"
      }),

      platformFunctions.getLocalStorageValue(keyNames.hasShownScannerTips).then((value) => {
        this.hasShownScannerTips = (value === null || value === 'false') ? false : true
      }),
      platformFunctions.getLocalStorageValue(keyNames.hasShownSettingsBadge).then((value) => {
        this.hasShownSettingsBadge = (value === null || value === 'false') ? false : true
      }),
      platformFunctions.getLocalStorageValue(keyNames.vehiclesHomeRecentFilter).then((value) => {
        this.vehiclesHomeRecentFilter = (value !== null && value !== undefined) ? value : RemoteConstants.vehicleScopeAll
      }),
      platformFunctions.getLocalStorageValue(keyNames.vehiclesAllFilter).then((value) => {
        this.vehiclesAllFilter = value ? value : RemoteConstants.vehicleScopeAll
      }),
      platformFunctions.getLocalStorageValue(keyNames.watchlistFilter).then((value) => {
        this.watchlistFilter = value ? value : RemoteConstants.vehicleScopeAll
      }),
      platformFunctions.getLocalStorageValue(keyNames.latestViewedNewsVersion).then((value) => {
        this.latestViewedNewsVersion = value && parseInt(value) > parseInt(0) ? value : 0
      }),
      platformFunctions.getLocalStorageValue(keyNames.scannerTorchModeOn).then((value) => {
        this.scannerTorchModeOn = (value === null || value === 'false') ? false : true
      }),
      platformFunctions.getLocalStorageValue(keyNames.appraisalProvidersOrder).then((value) => {
        if (value) {
          this.appraisalProvidersOrder = value.split(",")
        }
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenAddDeducts).then((value) => {
        this.hiddenAddDeducts = (value === null) ? [] : JSON.parse(value)
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenMatchupWholesale).then((value) => {
        this.hiddenMatchupWholesale = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenMatchupRetail).then((value) => {
        this.hiddenMatchupRetail = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenTrialFullData).then((value) => {
        this.hiddenTrialFullData = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.showPipelineDetailedSamples).then((value) => {
        this.showPipelineDetailedSamples = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenPipelineSamples).then((value) => {
        this.hiddenPipelineSamples = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenLLMSamples).then((value) => {
        this.hiddenLLMSamples = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenLLMTrims).then((value) => {
        this.hiddenLLMTrims = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.showCarfaxHistoryValue).then((value) => {
        this.showCarfaxHistoryValue = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.showAuctionConditionReport).then((value) => {
        this.showAuctionConditionReport = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.auctionListingsDisplayType).then((value) => {
        this.auctionListingsDisplayType = value || 'grid'
      }),
      platformFunctions.getLocalStorageValue(keyNames.auctionListingsLaneDisplayType).then((value) => {
        this.auctionListingsLaneDisplayType = value || 'grid'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenAuctionsSearchFilters).then((value) => {
        this.hiddenAuctionsSearchFilters = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenAuctionsSearchVehicleDetails).then((value) => {
        this.hiddenAuctionsSearchVehicleDetails = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.regionalReportZipCode).then((value) => {
        this.regionalReportZipCode = value
      }),
      platformFunctions.getLocalStorageValue(keyNames.regionalReportRadius).then((value) => {
        this.regionalReportRadius = value
      }),
      platformFunctions.getLocalStorageValue(keyNames.regionalReportDealerType).then((value) => {
        this.regionalReportDealerType = value
      }),
      platformFunctions.getLocalStorageValue(keyNames.lastSelectedDigitalAuctions).then((value) => {
        this.lastSelectedDigitalAuctions = value
      }),
      platformFunctions.getLocalStorageValue(keyNames.hiddenEmbeddedCalculator).then((value) => {
        this.hiddenEmbeddedCalculator = value === 'true'
      }),
      platformFunctions.getLocalStorageValue(keyNames.myLotCompetitorsScope).then((value) => {
        this.myLotCompetitorsScope = value
      }),
    ]).then(() => {
      this.emit("user_did_load")
    })
  }

  persistData() {
    try {
      if (this.accessToken && this.uid && this.client) {
        platformFunctions.setLocalStorageValue(keyNames.accessToken, this.accessToken)
        platformFunctions.setLocalStorageValue(keyNames.uid, this.uid)
        platformFunctions.setLocalStorageValue(keyNames.client, this.client)
      }
      if (this.user) {
        platformFunctions.setLocalStorageValue(keyNames.user, JSON.stringify(this.user))
      }
      if (this.lastSelectedTab) {
        platformFunctions.setLocalStorageValue(keyNames.lastSelectedTab, this.lastSelectedTab)
      }

      if (this.vehiclesHomeRecentFilter) {
        platformFunctions.setLocalStorageValue(keyNames.vehiclesHomeRecentFilter, this.vehiclesHomeRecentFilter)
      }

      if (this.vehiclesAllFilter) {
        platformFunctions.setLocalStorageValue(keyNames.vehiclesAllFilter, this.vehiclesAllFilter)
      }

      if (this.watchlistFilter) {
        platformFunctions.setLocalStorageValue(keyNames.watchlistFilter, this.watchlistFilter)
      }

      if (this.latestViewedNewsVersion !== null) {
        platformFunctions.setLocalStorageValue(keyNames.latestViewedNewsVersion, `${this.latestViewedNewsVersion}`)
      }

      platformFunctions.setLocalStorageValue(keyNames.hasShownScannerTips, this.hasShownScannerTips === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.scannerTorchModeOn, this.scannerTorchModeOn === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hasShownSettingsBadge, this.hasShownSettingsBadge === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenAddDeducts, this.hiddenAddDeducts && this.hiddenAddDeducts.length > 0 ? JSON.stringify(this.hiddenAddDeducts) : null)
      platformFunctions.setLocalStorageValue(keyNames.hiddenMatchupWholesale, this.hiddenMatchupWholesale === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenMatchupRetail, this.hiddenMatchupRetail === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenTrialFullData, this.hiddenTrialFullData === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.showPipelineDetailedSamples, this.showPipelineDetailedSamples === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenPipelineSamples, this.hiddenPipelineSamples === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenLLMSamples, this.hiddenLLMSamples === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenLLMTrims, this.hiddenLLMTrims === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.showCarfaxHistoryValue, this.showCarfaxHistoryValue === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.showAuctionConditionReport, this.showAuctionConditionReport === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.auctionListingsDisplayType, this.auctionListingsDisplayType)
      platformFunctions.setLocalStorageValue(keyNames.auctionListingsLaneDisplayType, this.auctionListingsLaneDisplayType)
      platformFunctions.setLocalStorageValue(keyNames.hiddenAuctionsSearchFilters, this.hiddenAuctionsSearchFilters === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.hiddenAuctionsSearchVehicleDetails, this.hiddenAuctionsSearchVehicleDetails === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.regionalReportZipCode, this.regionalReportZipCode)
      platformFunctions.setLocalStorageValue(keyNames.regionalReportRadius, this.regionalReportRadius)
      platformFunctions.setLocalStorageValue(keyNames.regionalReportDealerType, this.regionalReportDealerType)
      platformFunctions.setLocalStorageValue(keyNames.lastSelectedDigitalAuctions, this.lastSelectedDigitalAuctions)
      platformFunctions.setLocalStorageValue(keyNames.hiddenEmbeddedCalculator, this.hiddenEmbeddedCalculator === true ? 'true' : 'false')
      platformFunctions.setLocalStorageValue(keyNames.myLotCompetitorsScope, this.myLotCompetitorsScope)
    } catch(err) {
      console.log("Error persisting user data: ", err)
    }
  }

  isAccountOwner = () => {
    return this.user?.account?.owner === true
  }

  setHasShownScannerTips = () => {
    this.hasShownScannerTips = true
    this.persistData()
  }

  shouldShowSettingsBadge = () => {
    // Since carfax legacy have been removed, no badge for now
    return false
  }

  setHasShownSettingsBadge = () => {
    this.hasShownSettingsBadge = true
    this.persistData()
    this.emit("settings_page_viewed")
  }

  setHiddenAddDeducts = (hidden, providerKey) => {
    this.hiddenAddDeducts = this.hiddenAddDeducts || []
    if (this.hiddenAddDeducts.includes(providerKey) && hidden === true) { return }
    if (hidden) {
      this.hiddenAddDeducts.push(providerKey)
    } else {
      var index = this.hiddenAddDeducts.indexOf(providerKey)
      if (index > -1) { this.hiddenAddDeducts.splice(index, 1) }
    }
    this.persistData()
  }

  areAddDeductsHidden = (providerKey) => {
    if (this.hiddenAddDeducts?.includes(providerKey)) {
      return true
    }
    return false
  }

  setHiddenMatchup = (isHidden, typeKey) => {
    if (typeKey === 'wholesale') {
      this.hiddenMatchupWholesale = isHidden
    } else {
      this.hiddenMatchupRetail = isHidden
    }
    this.persistData()
  }

  setHiddenTrialData = (isHidden) => {
    this.hiddenTrialFullData = isHidden
    this.persistData()
  }

  setShowPipelineDetailedSamples = (isHidden) => {
    this.showPipelineDetailedSamples = isHidden
    this.persistData()
  }

  setHiddenPipelineSamples = (isHidden) => {
    this.hiddenPipelineSamples = isHidden
    this.persistData()
  }

  setHiddenLLMSamples = (isHidden) => {
    this.hiddenLLMSamples = isHidden
    this.persistData()
  }

  setHiddenLLMTrims = (isHidden) => {
    this.hiddenLLMTrims = isHidden
    this.persistData()
  }

  setShowCarfaxHistoryValue = (isHidden) => {
    this.showCarfaxHistoryValue = isHidden
    this.persistData()
  }

  setShowAuctionConditionReport = (isHidden) => {
    this.showAuctionConditionReport = isHidden
    this.persistData()
  }

  setAuctionListingsDisplayType = (type) => {
    this.auctionListingsDisplayType = type
    this.persistData()
  }

  setAuctionListingsLaneDisplayType = (type) => {
    this.auctionListingsLaneDisplayType = type
    this.persistData()
  }

  setHiddenAuctionsSearchFilters = (isHidden) => {
    this.hiddenAuctionsSearchFilters = isHidden
    this.persistData()
  }

  setHiddenAuctionsSearchVehicleDetails = (isHidden) => {
    this.hiddenAuctionsSearchVehicleDetails = isHidden
    this.persistData()
  }

  setLastSelectedDigitalAuctions = (auctions) => {
    this.lastSelectedDigitalAuctions = auctions
    this.persistData()
  }

  setHiddenEmbeddedCalculator = (isHidden) => {
    this.hiddenEmbeddedCalculator = isHidden
    this.persistData()
  }

  setMyLotCompetitorsScope = (scope) => {
    this.myLotCompetitorsScope = scope
    this.persistData()
  }

  getDefaultVehicleCondition = () => {
    var userDefault = this.user && this.user['vehicle_options'] && this.user['vehicle_options']['default_condition'] ? this.user['vehicle_options']['default_condition'] : null
    userDefault = userDefault && AppConstants.overallConditions[userDefault] ? userDefault : AppConstants['defaultOverallCondition']

    return {
      value: userDefault,
      label: `${AppConstants.overallConditions[userDefault]['title']} Condition`
    }
  }

  setVehiclesHomeRecentFilter = (filterValue) => {
    this.vehiclesHomeRecentFilter = filterValue
    this.persistData()
    this.emit('vehicles_home_scope_change')
  }

  setVehiclesAllFilter = (filterValue) => {
    this.vehiclesAllFilter = filterValue
    this.persistData()
    this.emit('all_vehicles_scope_change')
  }

  setWatchlistFilter = (filterValue) => {
    this.watchlistFilter = filterValue
    this.persistData()
    this.emit('watchlist_scope_change')
  }

  setScannerTorchModeOn = (isOn) => {
    this.scannerTorchModeOn = isOn
    this.persistData()
  }

  saveProviderDisplayMode = (providerKey, showAdjustments) => {
    const user = this.user
    if (!user) { return }
    if (global.isPartnerPreview) { return }
    var adjustmentsDisplay = [...user.adjustments_display]
    if (showAdjustments === true && !adjustmentsDisplay.includes(providerKey)) {
      adjustmentsDisplay.push(providerKey)
    } else if (showAdjustments === false && adjustmentsDisplay.includes(providerKey)) {
      adjustmentsDisplay = adjustmentsDisplay.filter((p) => p !== providerKey)
    }
    UserActionCreators.updateUser({adjustments_display: adjustmentsDisplay})
  }

  isShowingAdjustmentsForProvider = (providerKey) => {
    return this.user && this.user.adjustments_display && this.user.adjustments_display.includes(providerKey)
  }

  clearPersistedData() {
    for (let key of Object.keys(keyNames)) {
      if (keyNames[key]) {
        platformFunctions.removeLocalStorageValue(keyNames[key])
      }
    }
  }

  isAuthenticated = () => {
    let accessToken = this.accessToken
    let uid = this.uid
    let client = this.client
    return (accessToken !== null && accessToken !== undefined && uid !== null && uid !== undefined && client !== null && client !== undefined)
  }

  userUpdatedExternally = (user) => {
    // FIXME: probably not needed anymore since stripe stuff is in native now
    // Used by non-flux flows, such as cc update
    this.updateAndPersistUser(user)
    this.emit("user_data_changed")
    this.emit("user_updated_externally")
  }

  updateAndPersistUser = (updatedUser) => {
    // FIXME: need to reconcile card order with available providers
    // The server does not handle updating card order when a new provider is added.  So,
    // check what user has access to against card order and update user

    if (updatedUser?.errors) {
      return
    }

    this.user = updatedUser
    this.persistData()
    this.lastUserUpdatedAt = moment(updatedUser.updated)
  }

  needsTermsConditionsAcceptance = () => {
    return Boolean(this.isAuthenticated() && this.user && this.user.accepted_terms === false)
  }

  needsGlobalCostsEntry = () => {
    return this.user?.rooftop?.vehicle_costs_confirmed !== true && this.canEditGlobalCosts()
  }

  canEditGlobalCosts = () => {
    return this.user?.permissions?.edit_vehicle_costs === true
  }

  isAuxUser = () => {
    return this.featureAccess().includes('auxiliary')
  }

  hasAuthorizedCarfaxConnect = () => {
    return this.user && this.user.carfax_connect_authorized === true
  }

  isAutoCheckVisible = () => {
    return this.user?.see_autocheck === true
  }

  hasLinkedAutoCheck = () => {
    return (this.user && this.user.see_autocheck === true && this.user.autocheck_sid !== null)
  }

  hasLinkedAuctionEdge = () => {
    return Boolean(this.user?.permissions?.has_linked_edge_account === true)
  }

  notificationsPermissionsHasChanged = (status) => {
    this.notificationPermissionStatus = status
    this.emit("notification_permission_change")
  }

  getRegionalReportZipCode = () => {
    return this.regionalReportZipCode || this.user?.rooftop?.zip_code
  }

  getRegionalReportRadius = () => {
    return this.regionalReportRadius || 50
  }

  getRegionalReportDealerType = () => {
    return this.regionalReportDealerType || 'all'
  }

  setRegionalReportZipCode = (zipCode) => {
    this.regionalReportZipCode = zipCode
    this.persistData()
  }

  setRegionalReportRadius = (radius) => {
    this.regionalReportRadius = radius
    this.persistData()
  }

  setRegionalReportDealerType = (dealerType) => {
    this.regionalReportDealerType = dealerType
    this.persistData()
  }


  shouldShowExtensionPrompt = () => {
    const user = this.user
    if (!user || user.has_used_extension === true) { return false }

    const createdDate = moment(user.created)
    const currentDate = moment()
    const oldUserThreshold = moment('2021-04-23')
    const oldUserEnd = moment('2021-05-05')
    if (createdDate < oldUserThreshold && currentDate > oldUserEnd) {
      return false
    }

    var newCutoff = moment(createdDate).add(12, 'days')
    if (createdDate > oldUserThreshold && currentDate > newCutoff) {
      return false
    }

    return true
  }

  canOverrideZip = () => {
    return this.user.permissions.override_zip === true
  }

  userZipCode = () => {
    return this.user.zip_code
  }

  hasTeam = () => {
    return Boolean(this.user?.team_users?.length)
  }

  teamsEnabled = () => {
    return Boolean(this.user?.rooftop?.teams_enabled === true)
  }

  hasGlobalAuctionFilterAccess = () => {
    return false
  }

  globalAuctionFilterEnabled = () => {
    return Boolean(this.user?.global_listing_search_enabled)
  }

  hasMyLotAccess = () => {
    return this.featureAccess().includes('lot_sense')
  }

  userSubmittedApolloRooftopUrlData = () => {
    const { user_submitted_apollo_rooftop_url, user_submitted_apollo_rooftop_url_review_state } = this.user?.rooftop
    return {
      user_submitted_apollo_rooftop_url,
      user_submitted_apollo_rooftop_url_review_state,
    }
  }

  hasAuctionsPlus = () => {
    return this.featureAccess().includes('auctions_plus')
  }

  canUseCalculator = () => {
    return this.featureAccess().includes('calculator')
  }

  needsPaymentMethod = () => {
    return this.user?.account?.payment_method === 'none'
  }

  hasAccountOfferings = () => {
    return Boolean(this.user?.account?.account_offerings?.length > 0)
  }

  hasFeatureOfferings = () => {
    return this.user?.account?.account_offerings?.length > this.user?.appraisal_providers?.length
  }

  hasBookOfferings = () => {
    return this.user?.appraisal_providers?.length > 0
  }

  featureAccess = () => {
    return this.user?.feature_access || []
  }

  isAccountStatusRestricted = () => {
    // Only used by native due to navigation stack props limitations
    let canBeReinstated = false
    let needsCarblySubscription = false

    if (!this.shouldForcePaymentUpdate() && this.shouldShowAccountReactivation()) {
      canBeReinstated = true
    }

    if (this.isCanceled() && !this.hasAccountOfferings()) {
      needsCarblySubscription = true
    }

    return Boolean(canBeReinstated || needsCarblySubscription)
  }

  getActivityUsersById = memoize(() => {
    const systemUser = {
      uuid: null,
      first_name: 'Carbly',
      last_name: null,
      full_name: 'Carbly',
      avatar_color_hex: '#ddd'
    }

    if (!this.user.team_users) {
      return []
    }

    return this.user.team_users
    .concat([this.user.as_team_user, systemUser])
    .reduce((accum, user) => ({
      ...accum,
      [user.uuid]: user,
    }), {})
  }, () => this.user)


  getActivityUser = (userId) => {
    return this.getActivityUsersById()[userId]
  }

  // Authorization

  valuationsEnabled = () => {
    const user = this.user
    const p = AppConstants.permissionsVehiclesAccess
    return (user && user.permissions && user.permissions[p] === true)
  }

  valuationProvidersEnabled = () => {
    const user = this.user
    const p = AppConstants.permissionsValuationProvidersAccess
    return (user && user.permissions[p] === true)
  }

  iFrameAccessEnabled = () => {
    return this.featureAccess().includes('iframe')
  }

  hasLLMAccess = () => {
    return this.user?.active_cards?.includes('universe') || this.user?.trial_cards?.includes('universe')
  }

  hasMarketTrackerAccess = () => {
    return this.featureAccess().includes('market_tracker')
  }

  noValuationsMessage = () => {
    const user = this.user
    if (user) {
      return user.permissions.no_valuation_access_message
    }
  }

  valuationsTrialEndingDays = () => {
    const user = this.user
    if (user?.account?.state === 'trialing' && user?.account?.days_until_valuation_trial_expires > 0) {
      return user.account.days_until_valuation_trial_expires
    }

    return null
  }

  hasPurchasedSKU = (sku) => {
    return Boolean(this.user?.account?.account_offerings?.find((o) => o.sku === sku))
  }

  isSubscribedToProvider = (providerKey) => {
    return this.user.active_cards.includes(providerKey)
  }

  shouldShowAccountReactivation = () => {
    return Boolean(this.user?.account?.state === 'canceled' && this.user?.account.payment_method !== 'none' && this.hasAccountOfferings())
  }

  isTrialing = () => {
    return this.user?.account?.state === 'trialing'
  }

  isCanceled = () => {
    return this.user?.account?.state === 'canceled'
  }

  shouldForcePaymentUpdate = () => {
    const user = this.user
    const account = user?.account
    const accountState = account?.state
    const paymentMethod = account?.payment_method

    return Boolean(this.isAccountOwner() && ((accountState === 'trialing' && paymentMethod === 'invalid') || (accountState === 'past_due' && account?.past_grace_period === true)))
  }

  accountStateMessage = () => {
    const user = this.user
    if (user && user.account && user.account.state_message) {
      return user.account.state_message
    }
    return null
  }

  missingRequiredUserFields = () => {
    const user = this.user
    var missingUserFields = []
    var missingRooftopFields = []

    if (!user || this.isAuthenticated() === false) {
      return {missingUserFields, missingRooftopFields}
    }

    const userRequiredFields = AppConstants.userRequiredFields
    const rooftopRequiredFields = AppConstants.rooftopRequiredFields


    for (let field of userRequiredFields) {
      if (!user[field]) {
        missingUserFields.push(field)
      }
    }


    for (let field of rooftopRequiredFields) {
      if (!user.rooftop[field]) {
        missingRooftopFields.push(field)
      }
    }

    if (user.needs_password_reset === true) {
      missingUserFields.push('password')
    }

    return {missingUserFields, missingRooftopFields}
  }

  reconciledCardOrder = (user, newCardOrder) => {
    var hasModified = false
    var reconciledOrder = []

    // Exclude any providers that no longer have access
    for (let orderProviderKey of newCardOrder) {
      if (!user.trial_cards.includes(orderProviderKey) && !user.inactive_cards.includes(orderProviderKey)) {
        reconciledOrder.push(orderProviderKey)
      } else {
        hasModified = true
      }
    }


    // Ensure all providers are accounted for
    for (let appraisalProviderKey of user.appraisal_providers) {
      if (!reconciledOrder.includes(appraisalProviderKey) && !user.trial_cards.includes(appraisalProviderKey) && !user.inactive_cards.includes(appraisalProviderKey)) {
        reconciledOrder.push(appraisalProviderKey)
        hasModified = true
      }
    }

    // If the user qualifies for matchup (ignoring trial display), but it's not in their order
    // add at the beginning
    const qualifiesForMatchup = this.qualifiesForMatchup()
    if (qualifiesForMatchup && !reconciledOrder.includes('matchup_retail')) {
      reconciledOrder.unshift('matchup_retail')
      hasModified = true
    }
    if (qualifiesForMatchup &&!reconciledOrder.includes('matchup_wholesale')) {
      reconciledOrder.unshift('matchup_wholesale')
      hasModified = true
    }

    if (!reconciledOrder.includes('carfax')) {
      reconciledOrder.push('carfax')
      hasModified = true
    }

    if (!reconciledOrder.includes('vintel')) {
      reconciledOrder.push('vintel')
      hasModified = true
    }

    if (user?.see_autocheck === true && !reconciledOrder.includes('autocheck')) {
      // Add autocheck if persmissions have been added
      reconciledOrder.push('autocheck')
      hasModified = true
    } if (user?.see_autocheck === false && reconciledOrder.includes('autocheck')) {
      // Remove autocheck if persmissions have been removed
      reconciledOrder = reconciledOrder.filter((p) => p !== 'autocheck')
      hasModified = true
    }

    // Market Tracker (regional_report) is a feature, but also manifests as a card on the vehicle
    // details page.  So, if the user has purchased market tracker, but it's not in their card order, add it
    if (this.hasMarketTrackerAccess() && !reconciledOrder.includes('regional_report')) {
      // If reconciled_order includes universe, insert regional_reports after it
      if (reconciledOrder.includes('universe')) {
        var index = reconciledOrder.indexOf('universe')
        reconciledOrder.splice(index + 1, 0, 'regional_report')
      } else {
        reconciledOrder.push('regional_report')
      }
      hasModified = true
    } else if (!this.hasMarketTrackerAccess() && reconciledOrder.includes('regional_report')) {
      reconciledOrder = reconciledOrder.filter((p) => p !== 'regional_report')
      hasModified = true
    }

    return { hasModified: hasModified, reconciledOrder: reconciledOrder }
  }

  qualifiesForMatchup = () => {
    const user = this.user
    return user?.appraisal_providers_after_trial?.length > 1
  }

  authParams() {
    return {
      'access-token': this.accessToken,
      uid: this.uid,
      client: this.client
    }
  }

  defaultInitialTabName() {
    return "Valuations"
  }

  shouldShowNewsNotification = () => {
    const latestNewsVersion = this.news?.version
    const latestViewedNewsVersion = this.latestViewedNewsVersion

    if (this.news !== null && this.newsNotification !== null && latestNewsVersion !== null && latestViewedNewsVersion !== null && latestNewsVersion > latestViewedNewsVersion) {
      return true
    }
    return false
  }

  newsViewed = () => {
    const latestNewsVersion = this.news?.version
    if (latestNewsVersion) {
      this.latestViewedNewsVersion = latestNewsVersion
      this.persistData()
    }
    this.emit("news_notification_change")
  }

  navigateToNews = () => {
    this.emit("navigate_to_news")
  }

  forceUserActive = (newUser) => {
    if (newUser?.account?.state) {
      newUser.account.state = 'active'
    }
    if (newUser?.permissions?.access_vehicles) {
      newUser.permissions.access_vehicles = true
    }
    if (newUser?.account?.state_message) {
      newUser.account.state_message = null
    }
    return newUser
  }

  logout() {
    this.errorText = null
    this.accessToken = null
    this.uid = null
    this.client = null
    this.user = null
    this.lastSelectedTab = null
    this.vehiclesHomeRecentFilter = null
    this.vehiclesAllFilter = null
    this.watchlistFilter = null
    this.appraisalProvidersOrder = AppConstants.defaultProviderOrder
    this.news = null
    this.newsNotification = null
    this.hiddenMatchupWholesale = null
    this.hiddenMatchupRetail = null
    this.showCarfaxHistoryValue = false
    this.hiddenEmbeddedCalculator = false

    this.clearPersistedData()
  }

  authenticateWithJWTToken = (token) => {
    const decodedToken = decodeToken(token)
    this.handleLoggedInResponse(decodedToken['access-token'], decodedToken['uid'], decodedToken['client'])

    SessionActionCreators.refreshUser()
  }

  handleLoggedInResponse = (accessToken, uid, client) => {
    if (!accessToken || !uid || !client) {
      console.log("Invalid authentication parameters")
      return
    }
    this.accessToken = accessToken
    this.uid = uid
    this.client = client
    this.errorText = null
    this.lastSelectedTab = this.defaultInitialTabName()
    this.vehiclesHomeRecentFilter = RemoteConstants.vehicleScopeAll
    this.vehiclesAllFilter = RemoteConstants.vehicleScopeAll
    this.watchlistFilter = RemoteConstants.vehicleScopeAll
    global.isPartnerPreview = false
  }

  handleActions(action) {
    switch(action.type) {
      case ActionTypes.LOGIN_RESPONSE: {
        if (action.errors) {
          this.logout()
          this.errorText = action.errors
          this.emit("user_login_failed")
        } else if (action.response.headers && action.response.headers.get('access-token')) {
          const headers = action.response.headers

          this.handleLoggedInResponse(headers.get('access-token'), headers.get('uid'), headers.get('client'))



          var loggedInUser = Object.assign(action.user)
          this.updateAndPersistUser(loggedInUser)

          const reconciledOrder = this.reconciledCardOrder(loggedInUser, loggedInUser.active_cards)
          if (reconciledOrder.hasModified === true) {
            loggedInUser.ordered_cards = reconciledOrder.reconciledOrder
            setTimeout(() => {
              UserActionCreators.updateUser({ordered_cards: reconciledOrder.reconciledOrder})
            }, 200)
          }
        }
        this.emit("auth_state_changed")
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.FORGOT_PASSWORD_RECEIVED: {
        this.forgotPasswordErrors = action.errors
        this.emit("forgot_password_change")
        break
      }

      case ActionTypes.SET_PASSWORD_RECEIVED: {
        this.setNewPasswordErrors = action.errors
        this.emit("set_new_password_change")
        break
      }

      case ActionTypes.UNAUTHORIZED_USER: {
        this.logout()
        this.emit("auth_state_changed")
        break
      }

      case ActionTypes.INVALID_API_VERSION: {
        this.emit("invalid_api_version_changed")
        break
      }

      case ActionTypes.SERVER_MAINTENANCE_MODE: {
        this.maintenanceModeTitle = action.title
        this.maintenanceModeMessage = action.message
        this.emit("server_maintenance_mode_changed")
        break
      }

      case ActionTypes.LOGOUT: {
        this.logout()
        this.emit("auth_state_changed")
        break
      }

      case ActionTypes.ROOFTOP_UPDATED:
      case ActionTypes.USER_UPDATED: {
        // FIXME: this action name is confusing. It's when the user submitted
        // a change to their profile, and we got a response.  Not user refreshed.
        if (action.user) {
          const { errors } = action.user
          if (!errors) {
            this.updateAndPersistUser(action.user)
            this.emit("user_data_changed")
          }
          this.userErrors = errors
          this.emit("user_updated")
        }
        break
      }

      case ActionTypes.USER_AUCTION_FILTERS_UPDATED: {
        if (action.user) {
          this.updateAndPersistUser(action.user)
        }

        this.emit("user_updated")
        this.emit("user_data_changed")
        this.emit("user_auction_filters_changed")
        break
      }

      case ActionTypes.TOGGLE_USER_GLOBAL_AUCTION_FILTERS_UPDATED: {
        if (action.user) {
          this.updateAndPersistUser(action.user)
        }

        this.emit("user_updated")
        this.emit("user_data_changed")
        this.emit("user_auction_filters_changed")
        break
      }

      case ActionTypes.USER_ADDED: {
        const user = action.user
        if (user.errors) {
          this.addUserErrors = user.errors
        } else {
          this.addUserErrors = null
          this.updateAndPersistUser(user)
          this.emit("user_updated")
          this.emit("user_data_changed")
        }

        this.emit("user_added")
        break
      }

      case ActionTypes.ADDITIONAL_USER_PRICING_RECEIVED: {
        this.upgradePricing = action.pricing
        this.emit("additional_user_pricing_received")
        break
      }

      case ActionTypes.USER_REFRESH_RECEIVED: {
        const refreshedUser = action.user
        if (!refreshedUser) { return }
        const activeCards = refreshedUser.active_cards

        const reconciledOrder = this.reconciledCardOrder(refreshedUser, activeCards)
        if (reconciledOrder.hasModified === true) {
          refreshedUser.ordered_cards = reconciledOrder.reconciledOrder
          setTimeout(() => {
            UserActionCreators.updateUser({ordered_cards: reconciledOrder.reconciledOrder})
          }, 200)
        }

        this.updateAndPersistUser(refreshedUser)
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.RECLAIM_CONTROL_RECEIVED:
      case ActionTypes.HEARTBEAT_RECEIVED: {
        if (action.payload && action.payload.updated) {
          const lastServerUpdate = action.payload.updated
          if (this.lastUserUpdatedAt === null || moment(lastServerUpdate) > this.lastUserUpdatedAt) {
            console.log("User needs updating. Refreshing.......");
            SessionActionCreators.refreshUser()
          }
        }

        if (this.userHasSessionControl === true && action.hasSessionControl === false) {
          this.userHasSessionControl = false
          this.emit("user_session_control_changed")
        } else if (this.userHasSessionControl === false && action.hasSessionControl === true) {
          this.userHasSessionControl = true
          this.emit("user_session_control_changed")
        }
        break
      }

      case ActionTypes.NEWS_RECEIVED: {
        if (action.news) {
          this.news = action.news
          if (this.news?.items && this.news.items.length > 0) {
            var notificationItem = null
            for (let item of this.news.items) {
              if (item.should_notify === true) {
                notificationItem = item
                break
              }
            }
            this.newsNotification = notificationItem
          } else {
            this.newsNotification = null
          }
        }
        this.emit("news_received")
        this.emit("news_notification_change")
        break
      }

      case ActionTypes.ACCOUNT_DETAILS_RECEIVED: {
        this.accountDetails = action.accountDetails
        this.emit("account_details_received")
        break
      }

      case ActionTypes.AVAILABLE_OFFERINGS_RECEIVED: {
        this.availableOfferings = action.availableOfferings
        this.emit("available_offerings_received")
        break
      }

      case ActionTypes.PRICING_FOR_OFFERINGS_RECEIVED: {
        this.pricingForOfferings = action.pricingForOfferings
        this.pricingForOfferingsErrors = action.pricingForOfferings?.errors

        this.emit("pricing_for_offerings_received")
        break
      }

      case ActionTypes.OFFERINGS_ADDED: {
        if (action.user && !action.user.errors) {
          this.addOfferingsErrorMessage = null
          this.updateAndPersistUser(action.user)
          this.emit("user_data_changed")

          // FIXMWE: We really are only doing this because we don't have reconciliation of new books in this flow
          // Smarter way would be to reconcile from this returned user, not make another request
          SessionActionCreators.refreshUser()
        } else if (action.user?.errors) {
          this.addOfferingsErrorMessage = action.user.errors
        }
        this.emit("offerings_added")
        break
      }

      case ActionTypes.OFFERINGS_SLATE_RECEIVED: {
        if (action.offeringsSlate && !action.errors) {
          this.offeringsSlate = action.offeringsSlate
          this.emit("offerings_slate_changed")
        } else if (action.errors) {
          // FIXME:
        }
        break
      }

      case ActionTypes.REACTIVATE_ACCOUNT_RECEIVED: {
        if (action.user && !action.errors && !action.user.errors) {
          // We have an async process on backend that might not have updated account state.  Force it.
          var newUser = action.user
          newUser = this.forceUserActive(newUser)

          // The delay is a hack, because we don't have a way of knowning on backend when stripe
          // updates the subscription
          this.accountReactivationErrorMessage = null
          setTimeout(() => {
            this.updateAndPersistUser(newUser)
            this.emit("user_data_changed")
            this.emit("account_reactivation_received")
          }, 5000)

        } else if (action?.user?.errors) {
          this.accountReactivationErrorMessage = action.user.errors.join(' ')
          this.emit("account_reactivation_received")
        }
        break
      }

      case ActionTypes.UPDATE_BILLING_INTERVAL_RECEIVED: {
        if (action.user && !action.errors && !action.user.errors) {
          this.updateAndPersistUser(action.user)
          this.yearlyBillingSwitchErrorMessage = null
          this.emit("billing_interval_updated")
        } else if (action?.user?.errors) {
          this.yearlyBillingSwitchErrorMessage = action.user.errors.join(' ')
          this.emit("billing_interval_updated")
        }
        break
      }

      case ActionTypes.CC_UPDATED: {
        if (action.user && !action.errors && !action.user.errors) {

          var newUser = action.user
          if (action.user.account?.state === 'past_due' && action.user.account?.past_grace_period === true) {
            newUser = this.forceUserActive(newUser)
          }

          this.updateAndPersistUser(newUser)
          this.emit("cc_updated")
          this.emit("user_data_changed")
          this.updateCCErrorMessage = null

          setTimeout(() => {
            SessionActionCreators.refreshUser()
          }, 3000)
        } else if (!action.user || action.user?.errors) {
          this.updateCCErrorMessage = action.errors ? action.errors : "We were unable to update your payment method"
          this.emit("cc_updated")
        }
        break
      }

      case ActionTypes.CC_RETRIED: {
        // FIXME: does this need to key off of response code like web does through its standalone call??
        console.log("RESP: ", action);
        if (action.user && !action.user.errors) {
          this.updateAndPersistUser(action.user)
          this.emit("cc_retried")
          this.emit("user_data_changed")
          this.retryCCErrors = null
        } else if (action.user?.errors) {
          this.retryCCErrors = action.user.errors
          this.emit("cc_retried")
        }
        break
      }

      case ActionTypes.USER_CARFAX_CREDENTIALS_UPDATED:
      case ActionTypes.USER_CARFAX_CREDENTIALS_REMOVED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_carfax_data_changed")
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.USER_LOGOUT_CARFAX_CONNECT_RECEIVED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.LOGOUT_AUCTION_EDGE_RECEIVED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.CARFAX_AUTHORIZE_URL_RECEIVED: {
        this.carfaxConnectAuthorizeUrl = action.carfaxConnectAuthorizeUrl
        this.emit("carfax_connect_authorize_url_received")
        break
      }

      case ActionTypes.USER_AUTOCHECK_CREDENTIALS_UPDATED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_autocheck_data_changed")
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.USER_AUTOCHECK_CREDENTIALS_REMOVED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_autocheck_data_changed")
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.TAB_SELECTION_CHANGED: {
        this.lastSelectedTab = action.lastSelectedTab
        this.persistData()
        break
      }

      case ActionTypes.EDGE_AUTHORIZE_URL_RECEIVED: {
        this.edgeAuthorizeURL = action.url
        this.emit("edge_authorize_url_changed")
        break
      }

      case ActionTypes.AUCTION_LISTING_BIDS_RECEIVED: {
        const { listingBids, errors } = action
        this.auctionListingBids = new DotAuctionEventsCollection(listingBids, errors)
        this.emit("user_auction_listing_bids_changed")
        break
      }

      case ActionTypes.PERMISSIONS_UPDATED: {
        this.updateAndPersistUser(action.user)
        this.emit("user_permissions_changed")
        this.emit("user_data_changed")
        break
      }

      case ActionTypes.RECEIVED_APOLLO_CAMPAIGN_CONTACT_ID: {
        const { response, errors } = action
        if (response.token) {
          this.authenticateWithJWTToken(response.token)
        } else {
          window.location.replace('https://getcarbly.com')
        }
        break
      }

      default:
        break
    }
  }
}

const sessionStore = new SessionStore()
Dispatcher.register(sessionStore.handleActions.bind(sessionStore))

export default sessionStore
