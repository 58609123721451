import CalculatorBug from "../../components/common/CalculatorBug"
import AccountBug from "../../components/user/AccountBug"
import OnboardingPrompts from './OnboardingPrompts'
import useMyLotSettings from '../../components/myLot/util/useMyLotSettings'
import CheckCircleIcon from '../../components/common/icons/CheckCircleIcon'

const FullOnboarding = () => {
  const [
    {
      regionSettings: {
        zip,
        radius
      },
      lotInfo: {
        lotName,
      },
      urlReviewStateMessage,
      lotInventoryStatusMessage,
    },
  ] = useMyLotSettings()

  return (
    <div className="page-body-c body-content" style={{minHeight: '100%', marginLeft: '100px'}}>

      <div className="content-header">
        <h1>Let's Get Started</h1>

        <div className="global-tools">
          <AccountBug />
          <CalculatorBug />
        </div>
      </div>

      <div style={{marginTop: '20px'}}>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>

        <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
      </div>


      {
        urlReviewStateMessage &&
        <div style={{textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#eee', borderRadius: '6px', padding: '20px'}}>
          <div><CheckCircleIcon color='green' checked /> We have received your website link submission and are in the process of reviewing and activating it.  We will notify you when you are ready to go.</div>
          <div className='secondary-text'>{urlReviewStateMessage}</div>
        </div>
      }

      {
        lotInventoryStatusMessage &&
        <div style={{textAlign: 'center', border: '1px solid #ddd', backgroundColor: '#eee', borderRadius: '6px', padding: '20px'}}>
          <div><CheckCircleIcon color='green' checked /> We have received your request to connect your inventory management system.  We will notify you when you are ready to go.</div>
          <div className='secondary-text'>{lotInventoryStatusMessage}</div>
        </div>
      }

      {
        !lotInventoryStatusMessage && !urlReviewStateMessage &&
        <h2 style={{textAlign: 'center', marginTop: '80px'}}>Choose how you would like to get your inventory into Carbly</h2>
      }
      
      <div className='my-lot-full-onboarding-widgets-c'>
        <OnboardingPrompts showDismissAction={false} showManualVINEntry={!urlReviewStateMessage && !lotInventoryStatusMessage} />
      </div>
    </div>
  )
}

export default FullOnboarding
