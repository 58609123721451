import { useEffect, useState, useCallback } from 'react'
import ValuationStore from '../../../api/stores/ValuationStore'
import ValuationActionCreators from '../../../api/actions/ValuationActionCreators'

const useMyLotVehiclesDetails = () => {
  const [
    {
      data,
    },
    setState
  ] = useState({
    data: ValuationStore.myLotVehiclesDetails,
  })

  const onDataChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.myLotVehiclesDetails,
    }))
  }, [])

  useEffect(() => {
    const ev = 'my_lot_vehicles_details_change'
    ValuationStore.on(ev, onDataChange)

    return () => {
      ValuationStore.removeListener(ev, onDataChange)
    }
  }, [onDataChange])

  const load = useCallback(() => {
    ValuationActionCreators.loadMyLotVehiclesDetails()
  }, [])

  const getCountForState = useCallback((state) => {
    if (!data) return null
    return data.find(v => v.state === state)?.count || 0
  }, [data])

  return {
    getCountForState,
    load,
  }
}

export default useMyLotVehiclesDetails
