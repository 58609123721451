import VehiclesListContainer from './VehiclesListContainer'
import pluralize from 'pluralize'
import Header from './Header'
import Formatters from '../../api/constants/Formatters'

const VehiclesByStatus = ({ title, status, count, defaultOrder, havingAlerts, excludedSortOptions }) => {
  return (
    <div>
      <Header title={title} />
      <VehiclesListContainer
        status={status}
        havingAlerts={havingAlerts}
        defaultOrder={defaultOrder}
        subtitle={`${count != null ? `${Formatters.formatThousands(count)} ${pluralize('vehicle', count, false)}` : ''}`}
        excludedSortOptions={excludedSortOptions}
      />
    </div>
  )
}

export default VehiclesByStatus
