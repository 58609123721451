import { useRef, useState } from 'react'
import pluralize from 'pluralize'
import ClockIcon from '../common/icons/ClockIcon'
import PriceTagIcon from '../common/icons/PriceTagIcon'
import UpArrowIcon from '../common/icons/UpArrowIcon'
import DownArrowIcon from '../common/icons/DownArrowIcon'
import LockedIcon from '../common/icons/LockedIcon'
import Formatters from '../../api/constants/Formatters'
import { Tooltip } from 'reactstrap'
import AppConstants from '../../api/constants/AppConstants'

const Alert = ({ alert, classname, year, make, model, trim }) => {
  const ref = useRef()
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false)

  let element, tooltipText

  switch (alert.type) {
    case 'days_on_lot':
      element = (
        <>
          <ClockIcon width={14} height={14} />
          <div>{`${Formatters.formatThousands(alert.info.days_on_lot)} ${pluralize('day', alert.info.days_on_lot)} on lot`}</div>
        </>
      )
      tooltipText = `This vehicle's days on lot has exceeded the threshold you specified in your ${AppConstants.providerNames.my_lot} settings.`
      break
    case 'percent_of_market':
      element = (
        <>
          <PriceTagIcon width={16} height={16} />
          <div>{`${Formatters.formatThousands(alert.info.percent_of_market)}% of market`}</div>
        </>
      )
      tooltipText = `This vehicle is priced ${alert.info.threshold === 'high' ? 'above' : 'below' } your ${AppConstants.providerNames.my_lot} settings range for percent of market`
      break
    case 'wholesale_profit': {
      const profit = Formatters.formatCurrency(alert.info.profit)
      element = (
        <>
          <LockedIcon width={14} height={14} />
          <div>{`${profit} wholesale profit`}</div>
        </>
      )
      tooltipText = 'A profitable or break-even wholesale exit might be possible for this unit.'
      break
    }
    case 'retail_supply': {
      const Icon = alert.info.threshold === 'high' ? UpArrowIcon : DownArrowIcon
      element = (
        <>
          <Icon width={14} height={14} /> <span className='text-capitalize'>{alert.info.threshold + ' '}</span>&nbsp;supply
        </>
      )
      const ymmt = [year, make, model, trim].join(' ')
      tooltipText = `There are ${alert.info.threshold === 'high' ? 'more' : 'fewer' } ${ymmt} for sale than normal in your region.`
      break
    }
    default:
      throw new Error('Invalid alert type')
  }

  return (
    <>
      <div ref={ref} className={`my-lot-alert-capsule ${classname}`}>
        {element}
      </div>
      <Tooltip
        isOpen={tooltipIsOpen}
        target={ref}
        toggle={() => setTooltipIsOpen(!tooltipIsOpen)}>
        {tooltipText}
      </Tooltip>
    </>
  )
}

export default Alert
