import { memo } from 'react'
import Formatters from '../../api/constants/Formatters'
import { Scatter } from 'react-chartjs-2'
import { blendPlugin, makeScatterTooltipPluginOptions } from './util/chart'

const getChartProps = ({ data, highlightedData = [] }) => {
  const tooltipMaxItems = 3

  return {
    plugins: [blendPlugin],
    options: {
      plugins: {
        tooltip: {
          ...makeScatterTooltipPluginOptions(d => {
            return [
              Formatters.formatCurrency(d.price),
              '-',
              `${Formatters.formatThousands(d.mileage)} mi`,
            ].join(' ')
          }, tooltipMaxItems),
        },
      },
      scales: {
        x: {
          type: 'linear',
          ticks: {
            callback: Formatters.formatAbbreviatedNumberCurrency,
          },
        },
        y: {
          type: 'linear',
          ticks: {
            callback: Formatters.formatAbbreviatedNumber,
          },
        },
      },
      parsing: {
        xAxisKey: 'price',
        yAxisKey: 'mileage',
      },
      elements: {
        point: {
          borderWidth: 1,
          radius: 4,
          hoverRadius: 4,
          hoverBorderWidth: 1,
        },
      },
    },
    data: {
      datasets: [
        {
          data: highlightedData.slice(0),
          backgroundColor: 'rgba(255, 0, 0, 0.9)',
          borderColor: 'rgba(255, 0, 0, 0)',
          hoverBorderColor: 'rgba(255, 0, 0, 0.9)',
        },
        {
          data: data.slice(0),
          backgroundColor: 'rgba(44, 110, 142, 0.3)',
          borderColor: 'rgba(44, 110, 142, 0)',
          hoverBorderColor: 'rgba(44, 110, 142, 0.6)',
        },
      ],
    }
  }
}

const PriceMileageScatterChart = memo(({ containerStyle, ...rest }) => {
  const chartProps = getChartProps(rest)

  return (
    <div style={{width: '100%', height: '200px', position: 'relative', ...containerStyle}}>
      <Scatter {...chartProps} />
    </div>
  )
})

export default PriceMileageScatterChart
