import { useState, useCallback, useMemo, useEffect } from 'react'
import ValuationStore from '../../../api/stores/ValuationStore'
import ValuationActionCreators from '../../../api/actions/ValuationActionCreators'
import CommonFunctions from '../../../api/constants/CommonFunctions'

const useMyLotCompetitorsReport = (params = {}) => {
  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject(params)
  }, [params])

  const [
    {
      isLoading,
      data,
    },
    setState
  ] = useState({
    data: ValuationStore.getMyLotCompetitorsReportWithClientID(clientID),
    isLoading: ValuationStore.getIsLoadingMyLotCompetitorsReportWithClientID(clientID),
  })

  const onReportChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.getMyLotCompetitorsReportWithClientID(clientID),
    }))
  }, [clientID])

  const onIsLoadingChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: ValuationStore.getIsLoadingMyLotCompetitorsReportWithClientID(clientID),
    }))
  }, [clientID])

  useEffect(() => {
    const ev = `my_lot_competitors_report_change_${clientID}`
    const loadingEv = `is_loading_my_lot_competitors_report_change_${clientID}`
    ValuationStore.on(ev, onReportChange)
    ValuationStore.on(loadingEv, onIsLoadingChange)

    return () => {
      ValuationStore.removeListener(ev, onReportChange)
      ValuationStore.removeListener(loadingEv, onIsLoadingChange)
    }
  }, [onReportChange, onIsLoadingChange, clientID])

  useEffect(() => {
    ValuationActionCreators.loadMyLotCompetitorsReport({ ...params, clientID })
  }, [clientID, params])

  return {
    isLoading: isLoading || !data,
    data,
  }
}

export default useMyLotCompetitorsReport
