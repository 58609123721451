import Formatters from '../../../api/constants/Formatters'
import pluralize from 'pluralize'
import moment from 'moment'
import get from 'lodash/get'

const secondTrendLineProps = {
  borderColor: 'rgba(233, 86, 86, 1)',
  backgroundColor: 'rgba(233, 86, 86, 1)',
  hoverBackgroundColor: 'rgba(233, 86, 86, 1)',
  pointHoverBackgroundColor: 'rgba(233, 86, 86, 1)',
  pointHoverBorderColor: 'rgba(233, 86, 86, 1)',
  pointBorderColor: 'rgba(0, 0, 0, 0)',
  fill: {
    above: 'rgba(233, 86, 86, 0.1)',
    target: 'origin',
  },
}

const defaultBarChartDatasetProps = {
  categoryPercentage: 1,
  parsing: {
    yAxisKey: 'value',
    xAxisKey: 'category',
  },
}

const barChartSingleDatasetProps = data => ({
  labels: data.map(d => d.category),
  datasets: [
    {
      ...defaultBarChartDatasetProps,
      data,
    },
  ],
})

const stackedBarChartDOLProps = data => {
  const dolStructure = data[0].dol_groups
  return {
    stacked: true,
    displayTooltipColors: true,
    datalabelFormatter: Formatters.formatThousands,
    data: {
      labels: data.map(d => d.category),
      datasets: Array.from({ length: dolStructure.length }).map((_x, index) => {
        const i = dolStructure.length - index - 1 // reverse order
        return {
          ...defaultBarChartDatasetProps,
          backgroundColor: dolColors[i],
          label: dolStructure[i].category,
          data: data.map(d => {
            return {
              ...d.dol_groups[i],
              category: d.category,
              total: d.value,
            }
          }),
        }
      }),
    },
  }
}

const dolColors = ['#7ead42', '#a5d46a', '#ffdf80', '#ffc080', '#ffa080']

const componentProps = ((allData, type, options = {}) => {
  const data = get(allData, type)

  switch (type) {
    case 'analytics.snapshot_inventory_levels':
    case 'analytics.snapshot_num_price_changes':
    case 'analytics.snapshot_num_sold':
      return {
        format: Formatters.formatThousands,
        data,
      }
    case 'analytics.snapshot_days_on_lot':
      return {
        format: v => v?.toFixed(1),
        data,
      }
    case 'analytics.snapshot_retail_values':
      return {
        format: Formatters.formatAbbreviatedNumberCurrency,
        data,
      }

    case 'analytics.days_on_lot_groupings':
      return {
        chartProps: {
          data: barChartSingleDatasetProps(data),
          backgroundColor: dolColors,
          tooltipTitleCallback: items => {
            return `${items[0].label} days on lot`
          },
          tooltipLabelCallback: ctx => {
            return `${Formatters.formatThousands(ctx.raw.value)} ${pluralize('vehicles', ctx.raw.value)} for sale`
          },
        },
      }

    case 'analytics.percent_of_market': {
      return {
        chartProps: {
          data: barChartSingleDatasetProps(data),
          tooltipTitleCallback: items => {
            return `${items[0].label}% of market`
          },
          tooltipLabelCallback: ctx => {
            return `${Formatters.formatThousands(ctx.raw.value)} ${pluralize('vehicles', ctx.raw.value)} for sale`
          },
          xFormatFunction: v => `${v}%`,
        },
      }
    }

    case 'analytics.num_sold_by_body_style': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        getVehiclesListProps: el => {
          return {
            title: `${el.raw.category}s sold`,
            status: 'sold',
            bodyStyle: el.raw.category,
            excludedSortOptions: ['last_alerted_at'],
          }
        },
        chartProps: {
          ...stackedBarChartDOLProps(sortedData),
          tooltipLabelCallback: ctx => {
            return `${ctx.dataset.label} days: ${Formatters.formatThousands(ctx.raw.value)} ${pluralize('vehicles', ctx.raw.value)} sold`
          },
        },
      }
    }

    case 'analytics.days_on_lot_by_body_style': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        getVehiclesListProps: el => {
          return {
            title: `${el.raw.category}s sold`,
            status: 'sold',
            bodyStyle: el.raw.category,
            excludedSortOptions: ['last_alerted_at'],
          }
        },
        chartProps: {
          data: barChartSingleDatasetProps(sortedData),
          tooltipLabelCallback: ctx => {
            return [
              ctx.raw.value == null ? '' : `${ctx.raw.value.toFixed(1)} days on lot`,
              `${Formatters.formatThousands(ctx.raw.num_sold)} ${pluralize('vehicles', ctx.raw.num_sold)} sold`,
            ]
          },
        },
      }
    }

    case 'analytics.current_inventory_levels_by_body_style': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        getVehiclesListProps: el => {
          return {
            title: `${el.raw.category}s in Inventory`,
            isInInventory: true,
            bodyStyle: el.raw.category,
            excludedSortOptions: ['sold_at', 'last_alerted_at'],
          }
        },
        chartProps: {
          ...stackedBarChartDOLProps(sortedData),
          tooltipLabelCallback: ctx => {
            return `${ctx.dataset.label} days: ${Formatters.formatThousands(ctx.raw.value)} ${pluralize('vehicles', ctx.raw.value)} for sale`
          },
        },
      }
    }

    case 'analytics.trends.days_on_lot':
    case 'analytics.trends.retail_values':
    case 'analytics.trends.inventory_levels':
    case 'analytics.trends.num_price_changes':
    case 'analytics.trends.num_sold': {
      const showHistorical = Boolean(options.showHistorical)
      const values = data.map(d => d.value)

      const yFormatFunction = (() => {
        if (type === 'analytics.avg_list_price_trend') {
          return Formatters.formatCurrency
        } else if (type === 'analytics.trends.retail_values') {
          return Formatters.formatAbbreviatedNumberCurrency
        } else if (type === 'analytics.trends.days_on_lot') {
          return v => v.toFixed(1)
        } else {
          return Formatters.formatThousands
        }
      })()

      const allValues = values

      const chartProps = {
        options: {
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              formatter: v => yFormatFunction(v),
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: ctx => yFormatFunction(ctx.raw),
              },
            },
          },
          scales: {
            y: {
            },
          },
        },
        data: {
          labels: data.map(d => moment(d.date).format('M/DD')),
          datasets: [
            {
              label: 'This year',
              data: values,
            },
          ],
        },
      }

      if (showHistorical) {
        const historicalValues = get(allData, type.replace('trends', 'trends_historical')).map(d => d.value)
        chartProps.options.plugins.tooltip.callbacks.label = ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`
        chartProps.options.plugins.tooltip.displayColors = true
        chartProps.options.plugins.legend.display = true
        allValues.push(...historicalValues)
        chartProps.data.datasets[1] = {
          label: 'Last year',
          data: historicalValues,
          ...secondTrendLineProps,
        }
      }

      chartProps.options.scales.y.min = Math.min(...allValues) * 0.75

      return {
        chartProps,
      }
    }

    case 'rankings_trend': {
      const sorted = data.sort((a, b) => a.date.localeCompare(b.date))
      const averageDaysOnLotValues = sorted.map(d => d.avg_days_on_lot.rank)
      const inventoryValues = sorted.map(d => d.inventory.rank)
      const inventoryValueValues = sorted.map(d => d.inventory_value.rank)
      const numSoldValues = sorted.map(d => d.num_sold.rank)
      const yFormatFunction = Formatters.formatThousands

      return {
        chartProps: {
          options: {
            plugins: {
              datalabels: {
                formatter: yFormatFunction,
              },
              tooltip: {
                displayColors: true,
                callbacks: {
                  label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
                },
              },
            },
            scales: {
              y: {
                min: Math.min(...averageDaysOnLotValues, ...inventoryValues, ...inventoryValueValues, ...numSoldValues) * 0.75,
              },
            },
          },
          data: {
            labels: sorted.map(d => moment(d.date).format('M/DD')),
            datasets: [
              {
                label: 'Days on lot',
                data: averageDaysOnLotValues,
                borderColor: 'rgba(44, 110, 142, 1)',
                backgroundColor: 'rgba(44, 110, 142, 1)',
                hoverBackgroundColor: 'rgba(44, 110, 142, 1)',
                pointHoverBackgroundColor: 'rgba(44, 110, 142, 1)',
                pointHoverBorderColor: 'rgba(44, 110, 142, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Inventory levels',
                data: inventoryValues,
                borderColor: 'rgba(131, 87, 125, 1)',
                backgroundColor: 'rgba(131, 87, 125, 1)',
                hoverBackgroundColor: 'rgba(131, 87, 125, 1)',
                pointHoverBackgroundColor: 'rgba(131, 87, 125, 1)',
                pointHoverBorderColor: 'rgba(131, 87, 125, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Inventory value',
                data: inventoryValueValues,
                borderColor: 'rgba(135, 92, 61, 1)',
                backgroundColor: 'rgba(135, 92, 61, 1)',
                hoverBackgroundColor: 'rgba(135, 92, 61, 1)',
                pointHoverBackgroundColor: 'rgba(135, 92, 61, 1)',
                pointHoverBorderColor: 'rgba(135, 92, 61, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
              {
                label: 'Vehicles sold',
                data: numSoldValues,
                borderColor: 'rgba(63, 112, 77, 1)',
                backgroundColor: 'rgba(63, 112, 77, 1)',
                hoverBackgroundColor: 'rgba(63, 112, 77, 1)',
                pointHoverBackgroundColor: 'rgba(63, 112, 77, 1)',
                pointHoverBorderColor: 'rgba(63, 112, 77, 1)',
                pointBorderColor: 'rgba(0, 0, 0, 0)',
                fill: false,
              },
            ],
          },
        },
      }
    }

    case 'region.avg_days_on_lot_trend': {
      const values = data.map(d => d.value)
      const lotValues = get(allData, 'analytics.trends.days_on_lot').map(d => d.value)
      const yFormatFunction = v => v.toFixed(1)

      return {
        chartProps: {
          options: {
            plugins: {
              legend: {
                display: true,
              },
              datalabels: {
                formatter: v => yFormatFunction(v),
              },
              tooltip: {
                displayColors: true,
                callbacks: {
                  label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
                },
              },
            },
            scales: {
              y: {
                min: Math.min(...values, ...lotValues) * 0.75,
              },
            },
          },
          data: {
            labels: data.map(d => moment(d.date).format('M/DD')),
            datasets: [
              {
                data: values,
                label: 'Region',
              },
              {
                label: 'Lot',
                data: lotValues,
                ...secondTrendLineProps,
              },
            ],
          },
        },
      }
    }

    case 'analytics.price_mileage':
    case 'analytics.current_days_on_lot':
      return {
        chartProps: {
          data,
        },
      }

    case 'region.inventory_levels_by_body_style': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        chartProps: {
          data: barChartSingleDatasetProps(sortedData),
        },
        getVehiclesListProps: el => {
          return {
            listComponentType: 'regional',
            title: `${el.raw.category}s In Your Region`,
            regionalListingsParams: {
              zip: allData.meta.zip,
              radius: allData.meta.radius,
              end_date: allData.meta.end_date,
              bodyStyle: el.raw.category,
            },
          }
        },
      }
    }

    case 'region.inventory_levels_by_make': {
      const sortedData = data.slice(0).sort((a, b) => b.value - a.value || a.category.localeCompare(b.category))

      return {
        chartProps: {
          data: barChartSingleDatasetProps(sortedData),
        },
        getVehiclesListProps: el => {
          return {
            listComponentType: 'regional',
            title: `${el.raw.category} In Your Region`,
            regionalListingsParams: {
              zip: allData.meta.zip,
              radius: allData.meta.radius,
              end_date: allData.meta.end_date,
              make: el.raw.category,
            },
          }
        },
      }
    }

    default:
      return data
  }
})

export default componentProps
