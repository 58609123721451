import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import moment from 'moment'
import Formatters from '../../api/constants/Formatters'

const PriceChangesModal = ({ onCancel, data }) => {
  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '500px'}}>
      <ModalHeader>
        Price changes
      </ModalHeader>
      <ModalBody>
        {
          data.length === 0 ? 'No price changes' :
          data.map(({ date, amount_cents }, i) => (
            <div key={i}>
              {Formatters.formatCurrencyCents(amount_cents)} on {moment(date).format('MMM D, YYYY')}
            </div>
          ))
        }
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onCancel}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default PriceChangesModal
