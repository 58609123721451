import { memo } from 'react'
import { Bar } from 'react-chartjs-2'

const getChartProps = ({
  data,
  tooltipLabelCallback,
  tooltipTitleCallback,
  onClick,
  backgroundColor,
  stacked = false,
  displayTooltipColors = false,
  datalabelFormatter,
  xFormatFunction,
}) => {
  const result = {
    options: {
      layout: {
        padding: {
          top: stacked ? 18 : 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      onClick: (_e, items) => {
        if (!onClick) return
        const [item] = items
        if (!item) return
        onClick(item.element.$context)
      },
      interaction: {
        intersect: false,
        mode: 'index',
        axis: 'x',
      },
      plugins: {
        datalabels: stacked && {
          padding: 0,
          display: true,
          anchor: 'end',
          align: 'top',
          formatter: (item, ctx) => {
            if (ctx.datasetIndex === ctx.chart.data.datasets.length - 1) {
              return datalabelFormatter(item.total)
            } else {
              return ''
            }
          }
        },
        tooltip: {
          itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
          usePointStyle: true,
          displayColors: displayTooltipColors,
          boxWidth: 9,
          boxHeight: 9,
          boxPadding: 4,
          callbacks: {
            title: tooltipTitleCallback,
            label: tooltipLabelCallback,
          },
        },
      },
      scales: {
        x: {
          type: 'category',
          stacked,
          ticks: {
            font: {
              size: 11,
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          stacked,
        },
      },
      elements: {
        bar: {
          backgroundColor: backgroundColor || 'rgba(44, 110, 142, 1)',
        },
      },
    },
    data,
  }

  if (xFormatFunction) {
    result.options.scales.x.ticks.callback = function(v) {
      return xFormatFunction(this.getLabelForValue(v))
    }
  }

  return result
}

const BarChart = memo(props => {
  const chartProps = getChartProps(props)

  return (
    <div style={{width: '100%', height: '100%', position: 'relative'}}>
      <Bar {...chartProps} />
    </div>
  )
})

export default BarChart
