import React from 'react'

export default class UpArrowIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 17
    const height = this.props.height || 21
    const color = this.props.color || '#111'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 34 42" style={{...this.props.style}}>
        <path fillRule="evenodd" d="M32.401,19.029 C31.044,20.386 28.843,20.386 27.486,19.029 L20.324,11.867 L20.324,38.231 C20.324,40.150 18.768,41.706 16.849,41.706 C14.929,41.706 13.373,40.150 13.373,38.231 L13.373,11.867 L6.212,19.029 C4.855,20.386 2.654,20.386 1.297,19.029 C-0.060,17.672 -0.060,15.471 1.297,14.114 L13.584,1.826 C14.470,0.940 15.713,0.644 16.849,0.915 C17.984,0.644 19.227,0.940 20.113,1.826 L32.401,14.114 C33.758,15.471 33.758,17.672 32.401,19.029 Z"/>
      </svg>
    )
  }
}