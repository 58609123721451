import React from 'react'
import { Link } from 'react-router-dom'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import SessionStore from '../../api/stores/SessionStore'
import DeleteIcon from '../common/icons/DeleteIcon'
import ClipboardIcon from '../common/icons/ClipboardIcon'
import CommonFunctions from '../../api/constants/CommonFunctions'
import FolderAssignmentPopover from './FolderAssignmentPopover'
import ValuationRowNotesPopover from './ValuationRowNotesPopover'
import Avatar from '../user/Avatar'
import Formatters from '../../api/constants/Formatters'
import platformFunctions from '../../platformFunctions'
import AppConstants from '../../api/constants/AppConstants'
import moment from 'moment'
import TargetIcon from '../common/icons/TargetIcon'
import GearIcon from '../common/icons/GearIcon'
import LotStateButton from '../myLot/LotStateButton'
import VehicleTransactionDataFormModal from '../myLot/VehicleTransactionDataFormModal'

// FIXME: @JB - Handle lot vehicle update/delete from modal

const photoURLBase = platformFunctions.photoURLBase()

export default class ValuationRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShowingMorePopover: false,
      user: SessionStore.user,
      isShowingLotVehicleTransactionDataModal: false,
    }
  }

  render() {
    const valuation = this.props.valuation
    const { accountStatusRestricted } = this.props
    const { isShowingMorePopover, user } = this.state
    const lotVehicle = valuation.lot_vehicle

    // Legacy URL:
    // http://localhost:3001/vehicles/show/Honda-10007
    // https://web.getcarbly.com/vehicles/show/Toyota-46494

    const url = CommonFunctions.valuationURL(valuation)
    const titleString = valuation.year !== null || valuation.make !== null  || valuation.model !== null ? `${valuation.year} ${valuation.make} ${valuation.model !== null ? valuation.model : ''}` : 'Unknown Vehicle'

    const photo = valuation.primary_vehicle_photo
    const showPhoto = photo && photo.preview_url
    const vehicleOwner = valuation.user
    const isVehicleOwner = vehicleOwner && vehicleOwner.uuid === user.uuid

    return (
      <div className='vehicle-row-c'>
        <div className="card-list-row" style={{marginRight: 0}}>
          <Link to={url} className="vehicle-row" key={`recent-v-${valuation.uuid}`}>
            {
              showPhoto &&
              <div className="thumbnail">
                <img src={`${photoURLBase}${photo.preview_url}`} alt="Vehicle" />
              </div>
            }

            <div className="overview">
              <h3>{titleString}</h3>
              <div className="trim secondary-text">{Formatters.seriesStyleString(valuation.series, valuation.style)}</div>
            </div>
            <div className="details">
              <div className="vin">
                {valuation.vin}
                {
                  !isVehicleOwner &&
                  <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Avatar user={vehicleOwner} size={AppConstants.avatarSizes.xsmall} />
                    <div className="secondary-text" style={{marginLeft: '5px'}}> {vehicleOwner.full_name}</div>
                  </div>
                }
              </div>
              <div className="mileage">{valuation.mileage ? `${Formatters.formatThousands(valuation.mileage)} miles` : 'No mileage'} </div>

              <div className="created">{moment(valuation.created).format('M/D')}</div>

              {
                accountStatusRestricted !== true &&
                <div className={`folder${valuation.vehicle_folder ? ' assigned-folder' : ''}`}>
                  <FolderAssignmentPopover valuation={valuation} clientID={this.props.clientID} isRow disabled={!isVehicleOwner} />
                </div>
              }

              <div className="target-notes-c">
                {
                  valuation.structured_data?.target_bid_price &&
                  <div title='Target bid'><TargetIcon /> {Formatters.formatCurrency(valuation.structured_data.target_bid_price)}</div>
                }
                {
                  accountStatusRestricted !== true &&
                  <ValuationRowNotesPopover valuation={valuation} />
                }
              </div>



              {
                this.props.onDeleteValuation && accountStatusRestricted !== true &&
                <span className="more-button" onClick={(e) => e.preventDefault()} id={`more_popover_${valuation.uuid}`}>
                  <div>
                  <GearIcon width="1rem" height="1rem" />
                  <Popover placement="left" isOpen={isShowingMorePopover} trigger="legacy" target={`more_popover_${valuation.uuid}`} toggle={() => this.setState({ isShowingMorePopover: !isShowingMorePopover })}>
                    <PopoverHeader>Vehicle Actions</PopoverHeader>
                    <PopoverBody className='popover-med vehicle-row-actions' style={{maxHeight: '400px'}}>
                      {
                        valuation.vin &&
                        <CopyToClipboard
                          text={valuation.vin}
                          onCopy={() => {
                            toast.success("VIN Copied to Clipboard")
                          }}
                          >
                          <a href="#clip" style={{cursor: 'pointer', color: '#e95656'}}>
                            <ClipboardIcon /> Copy VIN
                          </a>
                        </CopyToClipboard>
                      }
                      {
                        isVehicleOwner &&
                        <a href="#delete" onClick={(e) => {e.preventDefault(); this.props.onDeleteValuation(valuation)}}><DeleteIcon /> Delete vehicle</a>
                      }
                    </PopoverBody>
                  </Popover></div>
                </span>

              }
            </div>
          </Link>
          {
            this.state.isShowingLotVehicleTransactionDataModal &&
            <VehicleTransactionDataFormModal onCancel={() => this.setState({ isShowingLotVehicleTransactionDataModal: false })} vehicle={{ ...valuation, lot_vehicle: { ...lotVehicle, uuid: valuation.uuid }}} onDelete={() => this.setState({ isShowingTransactionDataModal: false})} clientID={this.props.clientID} />
          }
        </div>
        {
          lotVehicle &&
          <div style={{marginLeft: '20px', paddingBottom: '10px'}}>
            <LotStateButton lotVehicleState={lotVehicle.state} onClick={() => this.setState({ isShowingLotVehicleTransactionDataModal: true })} />
          </div>
        }
      </div>
    )
  }
}
