import { memo, useCallback, useState } from 'react'
import componentProps from './util/componentProps'
import VehiclesListModal from './VehiclesListModal'
import RegionalListingsModal from '../../components/retail/ListingsModal'

const listComponentMap = {
  'lot': VehiclesListModal,
  'regional': RegionalListingsModal,
}

const ChartSection = memo(({ style, data, dataKey, title, subtitle, Chart, height, options }) => {
  const { chartProps, getVehiclesListProps } = data ? componentProps(data, dataKey, options) : {}

  const [
    {
      isShowingVehiclesListModal,
      vehiclesListProps,
      ListComponent,
    },
    setState
  ] = useState({
    isShowingVehiclesListModal: false,
    vehiclesListProps: {},
    ListComponent: null,
  })

  const onClick = useCallback(el => {
    if (getVehiclesListProps) {
      const { listComponentType = 'lot', ...rest } = getVehiclesListProps(el)
      setState(prev => ({
        ...prev,
        ListComponent: listComponentMap[listComponentType],
        isShowingVehiclesListModal: true,
        vehiclesListProps: rest,
      }))
    }
  }, [getVehiclesListProps])

  const onModalClose = useCallback(() => {
    setState(prev => ({
      ...prev,
      isShowingVehiclesListModal: false,
    }))
  }, [])

  if (!chartProps) return null

  return (
    <>
      <div style={style}>
        <div style={{borderBottom: '1px solid #888', marginBottom: '10px'}}>
          <div style={{fontWeight: 'bold', color: '#555'}}>{title}</div>
          {
            Boolean(subtitle) &&
            <div className='secondary-text' style={{marginTop: '-5px', fontSize: '0.8rem'}}>{subtitle}</div>
          }
        </div>

        <div style={{width: '100%', height}}>
          <Chart { ...chartProps } onClick={onClick} />
        </div>
      </div>
      {
        isShowingVehiclesListModal &&
        <ListComponent
          onClose={onModalClose}
          {...vehiclesListProps}
        />
      }
  </>
  )
})

export default ChartSection
