import React, { useMemo } from 'react'
import { Input } from 'reactstrap'
import { IMaskMixin } from 'react-imask'

const maskDefaults = {
  mask: Number,
  thousandsSeparator: ',',
}

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => {
  return (
    <Input
      {...props}
      innerRef={inputRef}
      type='text'
      className='field'
      autoComplete='off'
    />
  )
})

const NumberInputField = ({ value: propsValue, onChange, innerRef, style, acceptDecimal = false, max = 999999, min, unmask = true, ...rest }) => {
  const value = useMemo(() => {
    return propsValue != null ? propsValue.toLocaleString() : ''
  }, [propsValue])

  const mask = useMemo(() => {
    let _mask = {
      ...maskDefaults,
      max,
      min,
    }

    if (acceptDecimal) {
      _mask = {
        ..._mask,
        radix: '.',
        padFractionalZeros: true,
      }
    }

    return [_mask]
  }, [acceptDecimal, max])

  return (
    <MaskedInput
      {...rest}
      style={{ marginBottom: 0, ...style }}
      value={value}
      unmask={unmask}
      mask={mask}
      inputRef={innerRef}
      onAccept={onChange}
    />
  )
}

export default NumberInputField
