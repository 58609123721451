import moment from 'moment'
import pluralize from 'pluralize'
import Formatters from '../../../api/constants/Formatters'
import { ticks } from 'd3-array' // dependency of nivo

const inventoryLevelsChartProps = {
  inventory_levels_low: {
    color: 'hsl(69, 70%, 50%)',
  },
  inventory_levels_high: {
    color: '#e95656',
  },
}
const onLotLabel = 'For sale'
const soldLabel = 'Sold'

const pluralizeDOL = ({ avg_days_on_lot }) => pluralize('day', avg_days_on_lot.toFixed(1), true)
const componentProps = ((data, type) => {
  switch (type) {
    case 'region_summary':
      return {
        dealers: Formatters.formatThousands(data.snapshot_dealers),
        avgCarsOnLot: Formatters.formatThousands(data.snapshot_avg_cars_on_lot),
        avgListPrice: Formatters.formatCurrency(data.snapshot_avg_list_price),
        avgDaysOnLot: data.snapshot_avg_days_on_lot.toFixed(1),
      }

    case 'inventory_levels_low':
    case 'inventory_levels_high':
      return {
        ...data,
        summaryProps: {
          currentInventory: Formatters.formatThousands(data.current_inventory),
          averageInventory: Formatters.formatThousands(data.average_inventory),
          mostDealerName: data.most_dealer_name,
          make: data.make,
          model: data.model,
        },
        chartProps: {
          yFormatFunction: Formatters.formatThousands,
          data: [{
            ...inventoryLevelsChartProps[type],
            id: type,
            data: data.historic_inventory.map(d => {
              return {
                x: moment(d.date).format('M/DD'),
                y: d.num_on_lot,
              }
            }),
          }],
        },
      }

    case 'avg_days_on_lot_trend':
    case 'avg_list_price_trend':
      return {
        chartProps: {
          yMin: Math.min(...data.map(d => d.value)) * 0.75,
          yFormatFunction: (() => {
            if (type === 'avg_list_price_trend') {
              return Formatters.formatCurrency
            } else if (type === 'avg_days_on_lot_trend') {
              return v => v.toFixed(1)
            } else {
              return Formatters.formatThousands
            }
          })(),
          data: [{
            lineWidth: 4,
            pointSize: 5,
            id: type,
            color: 'hsl(69, 70%, 50%)',
            data: data.map(d => {
              return {
                x: moment(d.date).format('M/DD'),
                y: d.value,
              }
            }),
          }],
        },
      }

    case 'inventory_levels_trend':
      return {
        chartProps: {
          yMin: 0,
          yFormatFunction: Formatters.formatThousands,
          enableSlices: 'x',
          sliceTooltip: el => {
            return (
              <div className="graph-tooltip">
                <div>{el.slice.points[0].data.xFormatted}</div>
                {
                  el.slice.points.map((point) => {
                    return <div key={point.id}>{`${point.data.yFormatted} ${point.serieId}`}</div>
                  })
                }
              </div>
            )
          },
          data: [
            {
              id: soldLabel,
              color: '#2c6e8e',
              lineWidth: 4,
              pointSize: 5,
              data: data.map(d => {
                return {
                  x: moment(d.date).format('M/DD'),
                  y: d.num_sold,
                }
              }),
            },
            {
              id: onLotLabel,
              color: "#e95656",
              data: data.map(d => {
                return {
                  x: moment(d.date).format('M/DD'),
                  y: d.num_on_lot,
                }
              }),
            },
          ],
        },
      }

    case 'avg_price_by_year':
      const avgPriceByYearTickValues = (() => {
        const min = 0
        const max = Math.max(...data.map(d => d.avg_price))
        const numTicks = 5
        return ticks(min, max, numTicks)
      })();

      return {
        chartProps: {
          valueFormat: Formatters.formatCurrency,
          valueAxisFormat: Formatters.formatAbbreviatedNumberCurrency,
          tickValues: avgPriceByYearTickValues,
          data: data.map(d => {
            return {
              id: d.year,
              value: d.avg_price,
              numListings: d.num_listings,
            }
          }),
          axisBottom: {
            tickSize: 0,
          },
          tooltip: el => {
            return (
              <div className="graph-tooltip">
                <div>Model year {el.indexValue}</div>
                <div>Average price {el.formattedValue}</div>
                <div>{`${Formatters.formatThousands(el.data.numListings)} ${pluralize('vehicles', el.data.numListings)} for sale`}</div>
              </div>
            )
          },
        }
      }

    case 'highest_selling_vehicles':
      return {
        primary: `${data.make} ${data.model}`,
        secondary: `${pluralizeDOL(data)} on lot`,
        value: `${Formatters.formatThousands(data.num_sold)} sold`,
      }

    case 'lowest_days_on_lot_vehicles':
      return {
        primary: `${data.make} ${data.model}`,
        secondary: `${Formatters.formatThousands(data.num_sold)} sold`,
        value: pluralizeDOL(data),
      }

    case 'highest_demand_vehicles':
      return {
        primary: `${data.make} ${data.model}`,
        secondary: `${Formatters.formatThousands(data.num_sold)} sold, ${pluralizeDOL(data)} on lot`,
        value: `${data.demand_score.toFixed(1)} `,
      }

    case 'most_active_dealers':
      return {
        primary: data.dealer_name,
        value: `${Formatters.formatThousands(data.num_sold)} sold`,
      }

    case 'lowest_days_on_lot_dealers':
      return {
        primary: data.dealer_name,
        value: pluralizeDOL(data),
      }

    case 'current_highest_inventory_value_dealers':
      return {
        primary: data.dealer_name,
        value: Formatters.formatCurrency(data.inventory_value),
      }

    case 'current_highest_inventory_dealers':
      return {
        primary: data.dealer_name,
        value: Formatters.formatThousands(data.inventory),
      }

    default:
      return data
  }
})

export default componentProps
