import React from 'react'

export default class KeyIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 26
    const height = this.props.width || 26
    const color = this.props.color || 'currentColor'

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 196.74 359.74" style={{...this.props.style}} className={this.props.className}>
        <path fillRule="evenodd" stroke={color} strokeWidth="13.16px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M95.022,13.580 C140.001,13.580 176.464,50.043 176.464,95.022 C176.464,125.283 159.960,151.689 135.462,165.731 C135.462,165.817 135.580,298.580 135.580,298.580 L95.580,336.580 L53.580,294.580 L70.580,274.580 L55.580,254.580 L70.580,234.580 L54.580,215.580 L71.580,194.580 L55.580,178.580 C55.580,178.580 54.812,169.631 54.701,165.798 C30.137,151.775 13.580,125.332 13.580,95.022 C13.580,50.043 50.043,13.580 95.022,13.580 Z"/>
        <path fillRule="evenodd" stroke={color} strokeWidth="13.16px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M95.580,57.580 C105.521,57.580 113.580,65.639 113.580,75.580 C113.580,85.521 105.521,93.580 95.580,93.580 C85.639,93.580 77.580,85.521 77.580,75.580 C77.580,65.639 85.639,57.580 95.580,57.580 Z"/>
      </svg>
    )
  }
}
