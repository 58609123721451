import { useMemo, useState, useEffect, useCallback } from 'react'
import Lightbox from 'react-image-lightbox'
import pluralize, { plural } from 'pluralize'
import moment from 'moment'
import Formatters from '../../api/constants/Formatters'
import PriceMileageScatterChart from '../myLot/PriceMileageScatterChart'
import PriceChangesModal from '../myLot/PriceChangesModal'
import ListingImage from '../common/ListingImage'
import VehicleTransactionDataFormModal from '../myLot/VehicleTransactionDataFormModal'
import LineChart from '../myLot/LineChart'
import ValuationStore from '../../api/stores/ValuationStore'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import useMyLotSettings from '../myLot/util/useMyLotSettings'
import Alert from '../myLot/Alert'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import LotStateButton from '../myLot/LotStateButton'
import AppConstants from '../../api/constants/AppConstants'
import LotHistoryModal from '../myLot/LotHistoryModal'
import Scorecard from '../retail/Scorecard'
import DateFunctions from '../../api/constants/DateFunctions'
import maxBy from 'lodash/maxBy'

const LotVehicle = ({ valuation }) => {
  const {
    lot_report: {
      model_stats,
      region,
      uber,
      prices_dol,
    },
    lot_vehicle: vehicle,
  } = valuation
  const scorecard = region.model_scorecard
  const [isShowingPriceChangesModal, setIsShowingPriceChangesModal] = useState(false)
  const [isShowingTransactionDataModal, setIsShowingTransactionDataModal] = useState(false)
  const [isShowingTrimOnlyLocalComps, setIsShowingTrimOnlyLocalComps] = useState(true)
  const [currentLightboxIndex, setCurrentLightboxIndex] = useState(0)
  const [isShowingLightbox, setIsShowingLightbox] = useState(false)
  const [isShowingLotHistoryModal, setIsShowingLotHistoryModal] = useState(false)
  const [lotVehicle, setLotVehicle] = useState(vehicle)
  const [{ regionSettings }] = useMyLotSettings()
  const [hoveredPricesDolPoint, setHoveredPricesDolPoint] = useState(null)

  const now = moment()
  const clientID = `${lotVehicle.uuid || valuation.uuid}`

  const pricesDolMessage = useMemo(() => {
    const data = hoveredPricesDolPoint || maxBy(prices_dol, 'final_price')

    if (!data) return null
    return [
      'In order to sell this vehicle in',
      `${pluralize('day', data.days_on_lot, true)},`,
      'you should initially list it for',
      Formatters.formatCurrency(data.price),
      `(${Math.round(data.portion_of_market * 100)}% of market)`,
      'and be prepared to drop the list price',
      Formatters.formatCurrency(data.price_drop),
      'for a final list price of',
      Formatters.formatCurrency(data.final_price),
    ].join(' ')
  }, [hoveredPricesDolPoint])

  useEffect(() => {
    const lotVehicleEv = `lot_vehicle_updated_${clientID}`
    const lotVehicleDeletedEv = `lot_vehicle_deleted_${clientID}`
    ValuationStore.on(lotVehicleEv, onDataChange)
    ValuationStore.on(lotVehicleDeletedEv, onLotVehicleDeleted)

    return () => {
      ValuationStore.removeListener(lotVehicleEv, onDataChange)
      ValuationStore.removeListener(lotVehicleDeletedEv, onLotVehicleDeleted)
    }
  }, [onDataChange, clientID, lotVehicle])

  const onDataChange = useCallback(() => {
    const data = ValuationStore.myLotVehiclesUpdated.dataWithID(clientID)
    setLotVehicle({...lotVehicle, ...data})
  }, [clientID])

  const onLotVehicleDeleted = useCallback(() => {
    setLotVehicle({...lotVehicle, isDeleted: true})
  }, [clientID])

  const onUnsnoozeAlertsClick = useCallback(e => {
    e.preventDefault()
    const params = {
      alert_snooze_until: null,
      uuid: valuation.uuid,
    }
    ValuationActionCreators.updateLotVehicle(params, clientID)
  }, [valuation, clientID])

  const wholesaleValuesChartProps = useMemo(() => {
    const data = uber.filter(v => v.wholesale != null)
    const labels = data.map(v => moment(v.date).format('M/DD'))
    const values = data.map(v => v.wholesale)

    return {
      data: {
        labels,
        datasets: [
          {
            data: values,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: Formatters.formatCurrency,
          },
          tooltip: {
            callbacks: {
              label: ctx => Formatters.formatCurrency(ctx.raw),
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 30,
            bottom: 0,
            left: 30,
          },
        },
        scales: {
          y: {
            min: Math.min(...values) * 0.95,
          },
        },
      },
    }
  }, [uber])

  const pricesDolChartProps = useMemo(() => {
    const data = prices_dol

    const interaction = {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    }

    return {
      options: {
        scales: {
          x: {
            type: 'linear',
            ticks: {
              callback: Formatters.formatCurrency,
            },
          },
          y: {
            display: true,
            type: 'category',
            labels: data.map(v => v.days_on_lot).reverse(),
            ticks: {
              callback: function(v) { return this.getLabelForValue(v) },
            },
          },
        },
        parsing: {
          xAxisKey: 'price',
          yAxisKey: 'days_on_lot',
        },
        plugins: {
          tooltip: {
            interaction,
            filter: ctx => ctx.datasetIndex === 0 || ctx.dataIndex === 0,
            callbacks: {
              title: () => null,
              label: ctx => {
                const { chart } = ctx
                const { dataPoints } = chart.tooltip
                const index = dataPoints.indexOf(ctx)

                let lines
                if (ctx.datasetIndex === 1) {
                  lines = [
                    `Listed price: ${Formatters.formatCurrency(ctx.raw.price)}`
                  ]
                } else {
                  lines = [
                    `Days on lot: ${ctx.raw.days_on_lot}`,
                    `Initial list price: ${Formatters.formatCurrency(ctx.raw.price)}`,
                    `Expected price drop: ${Formatters.formatCurrency(ctx.raw.price_drop)}`,
                    `Final list price: ${Formatters.formatCurrency(ctx.raw.final_price)}`,
                    `Percent of market: ${Math.round(ctx.raw.portion_of_market * 100)}%`,
                  ]
                }

                if (index < dataPoints.length - 1) {
                  lines.push('') // space between items
                }

                return lines
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
        interaction,
        events: ['mousemove', 'mouseout'],
        onHover: function(ctx) {
          const active = ctx.chart._active.find(v => v.datasetIndex === 0)
          if (active) {
            setHoveredPricesDolPoint(active.element.$context.raw)
          }
        },
      },
      plugins: [{
        id: 'eventHandler',
        beforeEvent(_chart, args) {
          if (args.event.type === 'mouseout') {
            setHoveredPricesDolPoint(null)
          }
        }
      }],
      data: {
        datasets: [
          {
            fill: false,
            data,
          },
          {
            fill: false,
            hidden: !lotVehicle.price,
            pointRadius: 0,
            pointHoverRadius: 0,
            borderColor: 'rgba(255, 0, 0, 1)',
            data: data.reduce((acc, v, idx) => {
              if (idx === 0 || idx === data.length - 1) {
                return acc.concat({ days_on_lot: v.days_on_lot, price: lotVehicle.price })
              } else {
                return acc
              }
            }, []),
          },
        ],
      },
    }
  }, [prices_dol, lotVehicle.price])

  const dolFinalPriceChartProps = useMemo(() => {
    const data = prices_dol

    const interaction = {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    }

    return {
      options: {
        scales: {
          x: {
            type: 'category',
            labels: data.map(v => v.days_on_lot),
            ticks: {
              callback: function(v) { return this.getLabelForValue(v) },
            },
          },
          y: {
            display: true,
            type: 'linear',
            ticks: {
              callback: Formatters.formatCurrency,
            },
          },
        },
        parsing: {
          xAxisKey: 'days_on_lot',
          yAxisKey: 'final_price',
        },
        plugins: {
          tooltip: {
            interaction,
            callbacks: {
              title: () => null,
              label: ctx => {
                const { chart } = ctx
                const { dataPoints } = chart.tooltip
                const index = dataPoints.indexOf(ctx)

                const lines = [
                  `Days on lot: ${ctx.raw.days_on_lot}`,
                  `Initial list price: ${Formatters.formatCurrency(ctx.raw.price)}`,
                  `Expected price drop: ${Formatters.formatCurrency(ctx.raw.price_drop)}`,
                  `Final list price: ${Formatters.formatCurrency(ctx.raw.final_price)}`,
                  `Percent of market: ${Math.round(ctx.raw.portion_of_market * 100)}%`,
                ]

                if (index < dataPoints.length - 1) {
                  lines.push('') // space between items
                }

                return lines
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
      },
      data: {
        datasets: [
          {
            fill: false,
            data,
          },
        ],
      },
    }
  }, [prices_dol])

  const turnTimeDollarsPerDayChartProps = useMemo(() => {
    const data = prices_dol.map(v => ({ ...v, dollars_per_day: v.days_on_lot === 0 ? null : Math.round(v.final_price / v.days_on_lot) }))

    const interaction = {
      intersect: false,
      mode: 'nearest',
      axis: 'x',
    }

    return {
      options: {
        scales: {
          x: {
            type: 'category',
            labels: data.map(v => v.days_on_lot),
            ticks: {
              callback: function(v) { return this.getLabelForValue(v) },
            },
          },
          y: {
            display: true,
            type: 'linear',
            ticks: {
              callback: Formatters.formatCurrency,
            },
          },
        },
        parsing: {
          xAxisKey: 'days_on_lot',
          yAxisKey: 'dollars_per_day',
        },
        plugins: {
          tooltip: {
            interaction,
            callbacks: {
              title: () => null,
              label: ctx => {
                const { chart } = ctx
                const { dataPoints } = chart.tooltip
                const index = dataPoints.indexOf(ctx)

                const lines = [
                  `Days on lot: ${ctx.raw.days_on_lot}`,
                  `Dollars per day: ${Formatters.formatCurrency(ctx.raw.dollars_per_day)}`,
                  `Initial list price: ${Formatters.formatCurrency(ctx.raw.price)}`,
                  `Expected price drop: ${Formatters.formatCurrency(ctx.raw.price_drop)}`,
                  `Final list price: ${Formatters.formatCurrency(ctx.raw.final_price)}`,
                  `Percent of market: ${Math.round(ctx.raw.portion_of_market * 100)}%`,
                ]

                if (index < dataPoints.length - 1) {
                  lines.push('') // space between items
                }

                return lines
              },
            },
          },
          datalabels: {
            display: false,
          },
        },
      },
      data: {
        datasets: [
          {
            fill: false,
            data,
          },
        ],
      },
    }
  }, [prices_dol])

  const retailValuesChartProps = useMemo(() => {
    const data = uber.filter(v => v.retail != null)
    const labels = data.map(v => moment(v.date).format('M/DD'))
    const values = data.map(v => v.retail)

    return {
      data: {
        labels,
        datasets: [
          {
            data: values,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            formatter: Formatters.formatCurrency,
          },
          tooltip: {
            callbacks: {
              label: ctx => Formatters.formatCurrency(ctx.raw),
            },
          },
        },
        layout: {
          padding: {
            top: 20,
            right: 30,
            bottom: 0,
            left: 30,
          },
        },
        scales: {
          y: {
            min: Math.min(...values) * 0.95,
          },
        },
      },
    }
  }, [uber])

  const uberChartProps = useMemo(() => {
    const yFormatFunction = Formatters.formatCurrency

    const data = {
      retail: uber.map(v => v.retail),
      wholesale: uber.map(v => v.wholesale),
      askingPrice: uber.map(v => v.asking),
      acv: uber.map(v => v.acv),
      retailForecast: uber.map(v => v.retail_forecast),
      retailDolForecast: uber.map(v => v.retail_dol_forecast),
      wholesaleForecast: uber.map(v => v.wholesale_forecast),
    }

    const allData = Object.values(data).flat()

    const colors = {
      retail: 'rgb(66, 133, 244)',
      wholesale: 'rgb(234, 66, 54)',
      retailForecast: 'rgb(252, 188, 0)',
      retailDolForecast: 'rgb(52, 167, 84)',
      wholesaleForecast: 'rgb(255, 109, 1)',
      askingPrice: 'rgb(70, 190, 198)',
      acv: 'rgb(123, 169, 247)',
    }

    return {
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: true,
          },
          tooltip: {
            displayColors: true,
            itemSort: (a, b) => b.raw - a.raw,
            callbacks: {
              title: ctx => {
                const date = moment(uber[ctx[0].dataIndex].date).startOf('day')
                const now = moment().startOf('day')
                const daysDiff = date.diff(now, 'days')
                return daysDiff > 0 ? `${pluralize('day', daysDiff, true)} from now` : date.format('M/DD')
              },
              label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
            },
          },
        },
        scales: {
          y: {
            display: true,
            suggestedMin: Math.min(...allData.filter(v => v !== null)) * 0.95,
            ticks: {
              callback: yFormatFunction,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      data: {
        labels: uber.map(d => moment(d.date).format('M/DD')),
        datasets: [
          {
            label: 'Retail',
            data: data.retail,
            borderColor: colors.retail,
            backgroundColor: colors.retail,
            hoverBackgroundColor: colors.retail,
            pointHoverBackgroundColor: colors.retail,
            pointHoverBorderColor: colors.retail,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'Wholesale',
            data: data.wholesale,
            borderColor: colors.wholesale,
            backgroundColor: colors.wholesale,
            hoverBackgroundColor: colors.wholesale,
            pointHoverBackgroundColor: colors.wholesale,
            pointHoverBorderColor: colors.wholesale,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'Retail Forecast',
            data: data.retailForecast,
            borderColor: colors.retailForecast,
            backgroundColor: colors.retailForecast,
            hoverBackgroundColor: colors.retailForecast,
            pointHoverBackgroundColor: colors.retailForecast,
            pointHoverBorderColor: colors.retailForecast,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'Retail Aged',
            data: data.retailDolForecast,
            borderColor: colors.retailDolForecast,
            backgroundColor: colors.retailDolForecast,
            hoverBackgroundColor: colors.retailDolForecast,
            pointHoverBackgroundColor: colors.retailDolForecast,
            pointHoverBorderColor: colors.retailDolForecast,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'Wholesale Forecast',
            data: data.wholesaleForecast,
            borderColor: colors.wholesaleForecast,
            backgroundColor: colors.wholesaleForecast,
            hoverBackgroundColor: colors.wholesaleForecast,
            pointHoverBackgroundColor: colors.wholesaleForecast,
            pointHoverBorderColor: colors.wholesaleForecast,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'Asking Price',
            data: data.askingPrice,
            borderColor: colors.askingPrice,
            backgroundColor: colors.askingPrice,
            hoverBackgroundColor: colors.askingPrice,
            pointHoverBackgroundColor: colors.askingPrice,
            pointHoverBorderColor: colors.askingPrice,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
          {
            label: 'ACV',
            data: data.acv,
            borderColor: colors.acv,
            backgroundColor: colors.acv,
            hoverBackgroundColor: colors.acv,
            pointHoverBackgroundColor: colors.acv,
            pointHoverBorderColor: colors.acv,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            fill: false,
          },
        ],
      },
      yFormatFunction,
    }
  }, [uber])

  const dolPriceForecastChartProps = useMemo(() => {
    const yFormatFunction = Formatters.formatCurrency
    // assumes sorted by date asc
    const firstForecast = uber.find(v => v.retail_forecast != null)
    const firstForecastIndex = uber.indexOf(firstForecast)
    const data = uber.slice(firstForecastIndex)
    const acv = data[0]?.acv

    const datasets = {
      askingPrice: data.map(v => v.asking),
      acv: data.map(v => acv),
      retailForecast: data.map(v => v.retail_forecast),
      wholesaleForecast: data.map(v => v.wholesale_forecast),
    }

    const colors = {
      retail: 'rgb(66, 133, 244)',
      wholesale: 'rgb(234, 66, 54)',
      retailForecast: 'rgb(252, 188, 0)',
      retailDolForecast: 'rgb(52, 167, 84)',
      wholesaleForecast: 'rgb(255, 109, 1)',
      askingForecast: 'rgb(70, 190, 198)',
      acv: 'rgb(123, 169, 247)',
    }

    const dataDatasets = [
      {
        label: 'Retail Forecast',
        data: datasets.retailForecast,
        borderColor: colors.retailForecast,
        backgroundColor: colors.retailForecast,
        hoverBackgroundColor: colors.retailForecast,
        pointHoverBackgroundColor: colors.retailForecast,
        pointHoverBorderColor: colors.retailForecast,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        fill: false,
      },
      {
        label: 'Wholesale Forecast',
        data: datasets.wholesaleForecast,
        borderColor: colors.wholesaleForecast,
        backgroundColor: colors.wholesaleForecast,
        hoverBackgroundColor: colors.wholesaleForecast,
        pointHoverBackgroundColor: colors.wholesaleForecast,
        pointHoverBorderColor: colors.wholesaleForecast,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        fill: false,
      },
      {
        label: 'ACV',
        data: datasets.acv,
        borderColor: colors.acv,
        backgroundColor: colors.acv,
        hoverBackgroundColor: colors.acv,
        pointHoverBackgroundColor: colors.acv,
        pointHoverBorderColor: colors.acv,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        fill: false,
      },
    ]

    // Forecast asking price line
    const initialAskingPrice = lotVehicle.first_price_cents !== null ? lotVehicle.first_price_cents / 100 : null

    if (initialAskingPrice !== null) {
      const firstPriceDol = data[0].retail_dol_forecast
      const askingForecastDataInterpolated = data.map(v => {
        const price = initialAskingPrice - (firstPriceDol - v.retail_dol_forecast)
        return { ...v, asking_forecast: price }
      })

      datasets.askingForecast = askingForecastDataInterpolated.map(v => v.asking_forecast)
      dataDatasets.push({
        label: 'Aged Asking Price',
        data: datasets.askingForecast,
        borderColor: colors.askingForecast,
        backgroundColor: colors.askingForecast,
        hoverBackgroundColor: colors.askingForecast,
        pointHoverBackgroundColor: colors.askingForecast,
        pointHoverBorderColor: colors.askingForecast,
        pointBorderColor: 'rgba(0, 0, 0, 0)',
        fill: false,
      })
    }

    const allData = Object.values(datasets).flat()

    return {
      options: {
        // spanGaps: true,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: true,
          },
          tooltip: {
            displayColors: true,
            itemSort: (a, b) => b.raw - a.raw,
            callbacks: {
              title: ctx => {
                const date = moment(data[ctx[0].dataIndex].date).startOf('day')
                const daysDiff = date.diff(firstForecast.date, 'days')
                return daysDiff > 0 ? `${pluralize('day', daysDiff, true)} from now` : date.format('M/DD')
              },
              label: ctx => `${ctx.dataset.label}: ${yFormatFunction(ctx.raw)}`,
            },
          },
        },
        scales: {
          y: {
            display: true,
            suggestedMin: Math.min(...allData.filter(v => v !== null)) * 0.95,
            ticks: {
              callback: yFormatFunction,
            },
          },
        },
        elements: {
          point: {
            radius: 0,
            hoverRadius: 0,
          },
        },
        layout: {
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      data: {
        labels: firstForecast?.date ? data.map(d => moment(d.date).diff(firstForecast.date, 'days') + lotVehicle.days_on_lot) : [],
        datasets: dataDatasets,
      },
      yFormatFunction,
    }
  }, [uber, prices_dol, lotVehicle])

  const { vin: valuationVin, series: valuationTrim } = valuation

  const localComps = useMemo(() => {
    return valuation.lot_report.region.listings
  }, [valuation])

  const filteredLocalComps = useMemo(() => {
    return isShowingTrimOnlyLocalComps ? localComps.filter(l => l.trim === valuationTrim) : localComps
  }, [localComps, valuationTrim, isShowingTrimOnlyLocalComps])

  const priceMileageScatterProps = useMemo(() => {
    return {
      data: filteredLocalComps,
      highlightedData: filteredLocalComps.filter(l => l.vin === valuationVin),
    }
  }, [valuationVin, filteredLocalComps])

  const photoUrls = lotVehicle.photo_urls

  const photoUrlsPreview = useMemo(() => {
    return photoUrls.slice(0, 4)
  }, [photoUrls])

  if (lotVehicle.isDeleted) {
    return null
  }

  return (
    <>

      <section className="lot-vehicle-vdp">
        <div className='split-row'>
          <div className='split-row split-row-left' style={{columnGap: '5px', marginBottom: '10px'}}>
            <LotStateButton lotVehicleState={lotVehicle.state} onClick={() => setIsShowingTransactionDataModal(true)} className='lot-vehicle-state-button-large' />
            {lotVehicle.alerts.map((alert, i) => <Alert key={i} alert={alert} classname='my-lot-alert-capsule-large' year={valuation.year} make={valuation.make} model={valuation.model} trim={valuationTrim} />)}
            {
              lotVehicle.is_alert_snoozed &&
              <a href='#unsnooze-alerts' onClick={onUnsnoozeAlertsClick}>Unsnooze alerts</a>
            }
          </div>
          {
            lotVehicle.stock_number &&
            <div style={{fontWeight: 'bold', fontSize: '1.2rem'}}>Stock #: {lotVehicle.stock_number}</div>
          }
        </div>



        <div className='split-row split-row-left' style={{flexGrow: 1, flexShrink: 1, columnGap: '10px', marginBottom: '10px', alignItems: 'stretch'}}>

          <div className='headlines'>
            <div className='transactions-c'>
              <div className='split-row'>
                <div className='headline'>
                  <div className='headline-title'>Listed Price</div>
                  <div className='headline-feature'>
                    {lotVehicle.price_cents == null ? 'N/A' : Formatters.formatCurrencyCents(lotVehicle.price_cents)}
                  </div>
                  {
                    lotVehicle.portion_of_market != null &&
                    <div className='headline-secondary'>{Math.round(lotVehicle.portion_of_market * 100)}% of market</div>
                  }
                </div>

                <div className='headline'>
                  <div className='headline-title'>ACV</div>
                  <div className='headline-feature'>{lotVehicle.acv == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.acv)} </div>
                </div>
              </div>

              <div className='split-row'>
                <div className='headline'>
                  <div className='headline-title'>Costs</div>
                  <div className='headline-feature'>{lotVehicle.costs == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.costs)} </div>
                </div>

                <div className='headline'>
                  <div className='headline-title'>{`Profit ${lotVehicle.sale_price_cents ? '' : 'Potential'}`}</div>
                  <div className='headline-feature'>{lotVehicle.profit == null ? 'N/A' : Formatters.formatCurrency(lotVehicle.profit)} </div>
                </div>
              </div>

              <button onClick={(e) => {
                  e.preventDefault()
                  setIsShowingTransactionDataModal(true)
                }}>Edit Vehicle</button>
            </div>

            <div style={{flexGrow: 1, flexShrink: 1, height: '100%', display: 'flex', flexDirection: 'column'}}>
              <div className='stats-c'>
                <div className='headline'>
                  <div className='headline-title'>Days on lot</div>
                  <div className='headline-feature'>
                    <span style={{color: MyLotFunctions.colorForDaysOnLot(lotVehicle.days_on_lot)}}>{lotVehicle.days_on_lot === null ? '-' : Formatters.formatThousands(lotVehicle.days_on_lot)}</span>
                  </div>

                  {
                    region.model_stats.stats?.avg_days_on_lot != null &&
                    <div className='headline-secondary'>
                      Region average is {pluralize('day', region.model_stats.stats.avg_days_on_lot.toFixed(1), true)} for this model
                    </div>
                  }
                  {
                    model_stats.num_sold > 1 &&
                    <div className='headline-secondary' style={{marginTop: region.model_stats.stats?.avg_days_on_lot != null ? '10px' : 'inherit'}}>
                      You average {pluralize('day', model_stats.avg_days_on_lot_sold.toFixed(1), true)} with this model
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>Price Changes</div>
                    <div className='headline-feature'>
                      { lotVehicle.num_price_changes }
                    </div>

                    {
                      lotVehicle.num_price_changes > 0 &&
                      <div>
                        <div className='headline-secondary'>
                          Total change {lotVehicle.total_price_change_amount_cents == null ? '-' : Formatters.formatCurrencyCents(lotVehicle.total_price_change_amount_cents)} (
                            {((lotVehicle.total_price_change_amount_cents / lotVehicle.price_cents)*100).toFixed(0)}%)
                        </div>

                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          Last change {lotVehicle.last_price_change_date == null ? '-' : `${pluralize('day', now.diff(lotVehicle.last_price_change_date, 'days'), true)} ago`}
                          {
                            lotVehicle.last_price_change_amount_cents != null && lotVehicle.num_price_changes > 1 &&
                            <span> ({Formatters.formatCurrencyCents(lotVehicle.last_price_change_amount_cents)})</span>
                          }
                        </div>
                      </div>
                    }
                  </div>
                  {
                    lotVehicle.num_price_changes > 0 &&
                    <div className='headline-footer'>
                      <a href="#price-changes" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={(e) => {
                        e.preventDefault()
                        setIsShowingPriceChangesModal(true)}
                        }>View price history</a>
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>SOLD PREVIOUSLY</div>
                    <div className='headline-feature'>
                      { Formatters.formatThousands(model_stats.num_sold) }
                    </div>

                    <div>
                      {/* FIXME: Filter for num_sold > 1? Doing so doesn't mean more than 1 had non-null profit though. */}
                      {
                        model_stats.avg_profit_sold != null &&
                        <div className='headline-secondary'>
                          {Formatters.formatCurrency(model_stats.avg_profit_sold)} average profit
                        </div>
                      }

                      {
                        model_stats.num_sold > 1 &&
                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          {pluralize('day', model_stats.avg_days_on_lot_sold.toFixed(1), true)} on lot average
                        </div>
                      }

                      {/* FIXME: Filter for num_sold > 1? Doing so doesn't mean more than 1 had non-null avg_original_portion_of_market_sold though. */}
                      {
                        model_stats.avg_original_portion_of_market_sold != null &&
                        <div className='headline-secondary' style={{marginTop: '10px'}}>
                          {`${Math.round(model_stats.avg_original_portion_of_market_sold * 100)}% average original % of market`}
                        </div>
                      }
                    </div>
                  </div>

                  {
                    model_stats.num_sold > 0 &&
                    <div className='headline-footer'>
                      <a href="#lot-history" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={(e) => {
                          e.preventDefault()
                          setIsShowingLotHistoryModal(true)
                        }}>View lot history</a>
                    </div>
                  }
                </div>


                <div className='headline'>
                  <div className='headline-body'>
                    <div className='headline-title'>Vehicle Photos</div>
                    {
                      photoUrls.length === 0 &&
                      <div className='secondary-text' style={{marginTop: '10px'}}>No photos available</div>
                    }
                    <div className='thumbnails'>

                      {
                        photoUrlsPreview.map((url, i) => (
                          <div
                            key={i}
                            onClick={() => {
                              setCurrentLightboxIndex(i)
                              setIsShowingLightbox(true)
                            }}
                          >
                            <ListingImage src={url} style={{ width: '100%', height: 'auto' }}/>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  {
                    photoUrls.length > photoUrlsPreview.length &&
                    <div className='headline-footer'>
                      <a href="#view-all-photos" style={{display: 'block', lineHeight: '1rem', marginTop: '10px'}} onClick={e => {
                        e.preventDefault()
                        setIsShowingLightbox(true)
                      }}>View all photos</a>
                    </div>
                  }
                </div>
              </div>

              <div className='headline' style={{marginTop: '10px', flexGrow: 0, flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '2px 10px'}}>

                {
                  !Boolean(lotVehicle.purchased_at) && !Boolean(lotVehicle.sold_at) &&
                  <div className='secondary-text'>No purchase or sale data</div>
                }

                {
                  Boolean(lotVehicle.purchased_at) &&
                  <div className='split-row' style={{justifyContent: 'center', columnGap: '20px'}}>
                    <div><strong>Purchased</strong> {moment(DateFunctions.dateOnlyStringToISODate(lotVehicle.purchased_at)).format('MMM D, YYYY')}</div>
                    {
                      Boolean(lotVehicle.purchase_price_cents) &&
                      <div>{Formatters.formatCurrencyCents(lotVehicle.purchase_price_cents)}</div>
                    }
                    <div>{AppConstants.myLotPurchaseTypes[lotVehicle.purchase_type]}</div>
                  </div>
                }

                {
                  Boolean(lotVehicle.sold_at) &&
                  <div className='split-row' style={{justifyContent: 'center', columnGap: '20px'}}>
                    <div><strong>Sold</strong> {moment(DateFunctions.dateOnlyStringToISODate(lotVehicle.sold_at)).format('MMM D, YYYY')}</div>
                    {
                      Boolean(lotVehicle.sale_price_cents) &&
                      <div>{Formatters.formatCurrencyCents(lotVehicle.sale_price_cents)}</div>
                    }
                    <div>Retail</div>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>



        <div className='section-header' style={{marginTop: '40px'}}>
          <h3>Initial list price + Days on lot</h3>
        </div>

        <div>{pricesDolMessage}</div>

        <div style={{width: '100%', height: 220}}>
          <LineChart {...pricesDolChartProps} />
        </div>

        <div className='section-header' style={{marginTop: '40px'}}>
          <h3>Days on lot + Final list price</h3>
        </div>

        <div style={{width: '100%', height: 220}}>
          <LineChart {...dolFinalPriceChartProps} />
        </div>

        <div className='section-header' style={{marginTop: '40px'}}>
          <h3>Turn time + Dollars per day</h3>
        </div>

        <div style={{width: '100%', height: 220}}>
          <LineChart {...turnTimeDollarsPerDayChartProps} />
        </div>

        <div className='section-header' style={{marginTop: '40px'}}>
          <h3>UBER Graph</h3>
        </div>

        <div style={{width: '100%', height: 420}}>
          <LineChart {...uberChartProps} />
        </div>

        <div className='section-header' style={{marginTop: '40px'}}>
          <h3>Days on lot + Price forecast</h3>
        </div>

        <div style={{width: '100%', height: 300}}>
          <LineChart {...dolPriceForecastChartProps} />
        </div>

        {
          lotVehicle?.state !== 'sold' &&
          <>
            <div className='section-header' style={{marginTop: '40px'}}>
              <h3>Regional Data for {valuation.make} {valuation.model} - Powered by Market Tracker</h3>
            </div>

            <Scorecard
              zip={regionSettings.zip}
              radius={regionSettings.radius}
              year={valuation.year}
              make={valuation.make}
              model={valuation.model}
              trim={valuationTrim}
              scorecard={scorecard}
            />

            <div className='split-row' style={{columnGap: '20px'}}>
              <div style={{width: '50%'}}>
                <div className='section-header'>
                  <h3>Retail Values Trend for {valuation.make} {valuation.model} FIXME: review need for this after UBER is solid</h3>
                </div>
                <LineChart {...retailValuesChartProps} />
              </div>

              <div style={{width: '50%'}}>
                <div className='section-header'>
                  <h3>Wholesale Values Trend for {valuation.make} {valuation.model} FIXME: review need for this after UBER is solid</h3>
                </div>
                <LineChart {...wholesaleValuesChartProps} />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
              <div>
                <div className='section-header' style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                  <h3>{Formatters.formatThousands(filteredLocalComps.length)} {plural('Vehicle', filteredLocalComps.length, false)} for Sale Near You Now FIXME: why does this number differ from scorecard above?</h3>
                  {
                    valuationTrim && !(!isShowingTrimOnlyLocalComps && filteredLocalComps.length === 0) &&
                    <button className='link' onClick={(() => setIsShowingTrimOnlyLocalComps(!isShowingTrimOnlyLocalComps) )}>
                      {isShowingTrimOnlyLocalComps ? 'Show all trims' : `Show only ${valuationTrim} trim`}
                    </button>
                  }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                  <div>
                    <div className='secondary-text'>This vehicle is highlighted</div>
                    <PriceMileageScatterChart
                      {...priceMileageScatterProps}
                      containerStyle={{height: '140px'}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </section>

      {
        isShowingPriceChangesModal &&
        <PriceChangesModal
          onCancel={() => setIsShowingPriceChangesModal(false)}
          data={lotVehicle.price_changes}
        />
      }
      {
        isShowingTransactionDataModal &&
        <VehicleTransactionDataFormModal onCancel={() => setIsShowingTransactionDataModal(false)} vehicle={{ uuid: lotVehicle.uuid || valuation.uuid, year: valuation.year, make: valuation.make, model: valuation.model, lot_vehicle: {...lotVehicle, uuid: valuation.uuid} }} onDelete={() => setIsShowingTransactionDataModal(false)} clientID={clientID} />
      }

      {
        isShowingLightbox &&
        <Lightbox
          reactModalStyle={{overlay: {zIndex: 10000}}}
          mainSrc={photoUrls[currentLightboxIndex]}
          nextSrc={photoUrls[(currentLightboxIndex + 1) % photoUrls.length]}
          prevSrc={photoUrls[(currentLightboxIndex + photoUrls.length - 1) % photoUrls.length]}
          onCloseRequest={() => {
            setCurrentLightboxIndex(0)
            setIsShowingLightbox(false)
          }}
          onMovePrevRequest={() => setCurrentLightboxIndex((currentLightboxIndex + photoUrls.length - 1) % photoUrls.length)}
          onMoveNextRequest={() => setCurrentLightboxIndex((currentLightboxIndex + 1) % photoUrls.length)}
        />
      }

      {
        isShowingLotHistoryModal &&
        <LotHistoryModal
          onClose={() => setIsShowingLotHistoryModal(false)}
          year={valuation.year}
          make={valuation.make}
          model={valuation.model}
          trim={valuationTrim}
          modelStats={model_stats}
        />
      }
    </>
  )
}

export default LotVehicle
