
export const blendPlugin = {
  id: 'blendPlugin',
  beforeDatasetDraw: function(chart, options) {
    if (options.index === 1) {
      chart.ctx.globalCompositeOperation = 'multiply';
    }
  },
  afterDatasetDraw: function(chart) {
    chart.ctx.globalCompositeOperation = 'source-over';
  },
}

export const makeScatterTooltipPluginOptions = (textFn, maxItems) => {
  return {
    yAlign: 'center',
    usePointStyle: true,
    displayColors: true,
    padding: 5,
    bodySpacing: 0,
    boxWidth: 9,
    boxHeight: 9,
    boxPadding: 4,
    bodyFont: {
      size: 12,
    },
    itemSort: (a, b) => {
      // Put highlighted first
      return a.datasetIndex - b.datasetIndex ||
        // TODO: Secondary sort?
        a.dataIndex - b.dataIndex
    },
    callbacks: {
      label: (item) => {
        const { chart, raw: d } = item
        const { dataPoints } = chart.tooltip
        const index = dataPoints.indexOf(item)

        if (index >= maxItems) return null

        const ymm = [
          d.year,
          d.make,
          d.model,
        ].join(' ')

        const text = textFn(d)

        const lines = [
          ymm,
          text,
        ]

        if (index < Math.min(dataPoints.length, maxItems) - 1) {
          lines.push('') // space between items
        }

        return lines
      },
      footer: (items) => {
        if (items.length > maxItems) {
          return `+${items.length - maxItems} more`
        }
      },
    },
  }
}
