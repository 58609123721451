import { EventEmitter } from 'events'
import Dispatcher from '../actions/Dispatcher'
import ValuationActionCreators from '../actions/ValuationActionCreators'
import AuctionStore from './AuctionStore'
import LimitedCollection from '../constants/LimitedCollection'
import PaginatedCollection from '../constants/PaginatedCollection'
import RemoteConstants from '../constants/RemoteConstants'
import AppConstants from '../constants/AppConstants'
var ActionTypes = RemoteConstants.ActionTypes

class ValuationStore extends EventEmitter {
  constructor() {
    super()
    this.setMaxListeners(17)
    this.vehiclesHomeData = null
    this.valuations = new LimitedCollection()
    this.valuationPreviews = new LimitedCollection()
    this.vehiclePreview = null // Only used for choice resets
    this.valuationPreviewNotes = new LimitedCollection()
    this.valuationPartnerActions = new LimitedCollection()
    this.mileageFamilies = new LimitedCollection()
    this.newValuationTargetFolder = null
    this.folders = []
    this.folderInfo = new LimitedCollection()
    this.vehiclesHomeAreLoading = false

    this.recentValuations = new LimitedCollection()

    this.valuationsCorpus = null
    this.valuationsSearchResults = null

    this.valuationShares = new LimitedCollection()
    this.isSharedValuation = false
    this.sharedValuation = null
    this.sharedValuationErrors = null

    this.pmrSampleDetails = new LimitedCollection()
    this.pmrAllSamples = new LimitedCollection()

    this.deletedPhotoID = null

    this.paginationCount = -1
    this.carfaxSummaries = new LimitedCollection()
    this.carfaxReports = new LimitedCollection()
    this.carfaxIsLoadingTrackers = new LimitedCollection()
    this.autoCheckIsLoadingTrackers = new LimitedCollection()
    this.autoCheckSummaries = new LimitedCollection()
    this.autoCheckReport = null
    this.vintelIsLoadingTrackers = new LimitedCollection()
    this.vintelReports = new LimitedCollection()

    this.autoCheckReports = new LimitedCollection()

    this.searchVehiclesScope = RemoteConstants.vehicleScopeAll
    this.allVehiclesOrder = RemoteConstants.orderByViewed

    this.vehicleActivitiesData = new LimitedCollection()
    this.llmSampleDetail = null
    this.llmTrimSamples = null

    this.vehicleReports = new LimitedCollection()

    this.myLotReport = null
    this.isLoadingMyLotReport = false

    this.myLotCompetitorsReport = new LimitedCollection()
    this.myLotCompetitorsReportLoadingTrackers = new LimitedCollection()

    this.myLotVehicles = new LimitedCollection()
    this.myLotVehiclesDetails = null
    this.myLotVehiclesUpdated = new LimitedCollection()

    this.regionalReport = null

    this.regionalListings = new LimitedCollection()
    this.regionalListingsLoadingTrackers = new LimitedCollection()

    this.regionalModelStats = new LimitedCollection()
    this.regionalModelStatsLoadingTrackers = new LimitedCollection()

    this.regionalModelScorecard = new LimitedCollection()
    this.regionalModelScorecardLoadingTrackers = new LimitedCollection()
  }

  getMyLotCompetitorsReportWithClientID(clientID) {
    return this.myLotCompetitorsReport.dataWithID(clientID)
  }

  getIsLoadingMyLotCompetitorsReportWithClientID(clientID) {
    return this.myLotCompetitorsReportLoadingTrackers.dataWithID(clientID) ?? false
  }

  getRegionalModelStatsWithClientID(clientID) {
    return this.regionalModelStats.dataWithID(clientID)
  }

  getRegionalModelStatsIsLoadingWithClientID(clientID) {
    return this.regionalModelStatsLoadingTrackers.dataWithID(clientID) ?? false
  }

  getRegionalModelScorecardWithClientID(clientID) {
    return this.regionalModelScorecard.dataWithID(clientID)
  }

  getRegionalModelScorecardIsLoadingWithClientID(clientID) {
    return this.regionalModelScorecardLoadingTrackers.dataWithID(clientID) ?? false
  }

  getRegionalListingsWithClientID(clientID) {
    return this.regionalListings.dataWithID(clientID)
  }

  getRegionalListingsIsLoadingWithClientID(clientID) {
    return this.regionalListingsLoadingTrackers.dataWithID(clientID) ?? false
  }

  setSearchVehiclesScope(scope) {
    this.searchVehiclesScope = scope
    this.emit('search_vehicles_scope_change')
  }

  setAllVehiclesOrder(scope) {
    this.allVehiclesOrder = scope
    this.emit('all_vehicles_order_change')
  }

  valuationNew(folder = null) {
    // Web only, sends notifiction to show modal
    if (folder) {
      this.newValuationTargetFolder = folder
    }
    this.emit('new_valuation')
  }

  vinFoundOnClipboard = (vin) => {
    this.vinOnClipboard = vin
    this.emit('vin_on_clipboard')
  }

  promptNewValuation(vin, additionalNewVehicleParams) {
    this.vinOnClipboard = vin
    if (vin && additionalNewVehicleParams) {
      // We're stuffing this in the store to aid in callback later, instead of passing this around to various views
      this.additionalNewVehicleParams = {forVIN: vin, ...additionalNewVehicleParams}
    } else {
      this.additionalNewVehicleParams = null
    }

    this.emit('pop_to_vehicles_root')
    setTimeout(() => {
      this.emit('new_valuation_prompt')
    }, 200)

    setTimeout(() => {
      this.additionalNewVehicleParams = null
    }, 5000)
  }

  folderNew() {
    this.emit('new_folder')
  }

  valuationPreviewWithClientID(clientID) {
    return this.valuationPreviews.dataWithID(clientID)
  }

  valuationWithClientID(clientID) {
    return this.valuations.dataWithID(clientID)
  }

  valuationShareWithClientID(clientID) {
    return this.valuationShares.dataWithID(clientID)
  }

  pmrSampleDetailWithClientID(clientID) {
    return this.pmrSampleDetails.dataWithID(clientID)
  }

  pmrAllSamplesWithClientID(clientID) {
    return this.pmrAllSamples.dataWithID(clientID)
  }

  showValuationShare() {
    // Nav item doesn't have local context for native, so use this to notify
    this.emit('show_valuation_share')
  }

  carfaxSummaryWithID(vehicleUUID) {
    return this.carfaxSummaries.dataWithID(vehicleUUID)
  }

  carfaxReportWithID(vehicleUUID) {
    return this.carfaxReports.dataWithID(vehicleUUID)
  }

  carfaxIsLoadingForVehicle(vehicleUUID) {
    return this.carfaxIsLoadingTrackers.dataWithID(vehicleUUID)
  }

  autoCheckIsLoadingForVehicle(vehicleUUID) {
    return this.autoCheckIsLoadingTrackers.dataWithID(vehicleUUID)
  }

  vintelReportWithID(vehicleUUID) {
    return this.vintelReports.dataWithID(vehicleUUID)
  }

  vintelIsLoadingForVehicle(vehicleUUID) {
    return this.vintelIsLoadingTrackers.dataWithID(vehicleUUID)
  }

  autoCheckSummaryWithID(vehicleUUID) {
    return this.autoCheckSummaries.dataWithID(vehicleUUID)
  }

  autoCheckReportWithID(vehicleUUID) {
    return this.autoCheckReports.dataWithID(vehicleUUID)
  }

  vehicleReportWithClientID(clientID) {
    return this.vehicleReports.dataWithID(clientID)
  }

  mileageFamilyWithID(vehicleUUID) {
    return this.mileageFamilies.dataWithID(vehicleUUID)
  }

  deleteValuation(vehicleUUID, clientID) {
    // Iterate all "recent" collections
    for (const { data: paginatedCollection } of this.recentValuations.collection) {
      if (paginatedCollection) {
        paginatedCollection.collection = paginatedCollection.collection.filter((v) => {
          return v.uuid !== vehicleUUID
        })
      }
    }
    this.emit('recent_valuations_change')

    // Remove from home -> recents
    if (this.vehiclesHomeData?.recent?.data) {
      this.vehiclesHomeData.recent.data = this.vehiclesHomeData.recent.data.filter((v) => {
        return v.uuid !== vehicleUUID
      })
    }

    // Remove from home folders
    if (this.vehiclesHomeData?.folders) {
      for (let homeFolder of this.vehiclesHomeData.folders) {
        if (homeFolder.vehicles) {
          homeFolder.vehicles = homeFolder.vehicles.filter((v) => {
            return v.uuid !== vehicleUUID
          })
        }
      }
    }
    this.emit('vehicles_home_change')
    ValuationActionCreators.deleteValuation(vehicleUUID, clientID)
  }

  changeHomeValuationFolder(valuation, existingFolderID, targetFolder) {
    // Direct manipulation to show immediate changes
    var homeData = Object.assign(this.vehiclesHomeData)
    var newFolder = homeData.folders.find((f) => f.id === targetFolder.id)
    var oldFolder = null
    if (existingFolderID) {
      oldFolder = homeData.folders.find((f) => f.id === existingFolderID)
    }

    if (oldFolder) {
      for (let i = 0; i < oldFolder.vehicles.length; i++) {
        if (oldFolder.vehicles[i].uuid === valuation.uuid) {
          oldFolder.vehicles.splice(i, 1)
          break
        }
      }
    }

    if (!newFolder.vehicles) {
      newFolder['vehicles'] = []
    }

    valuation.vehicle_folder = newFolder
    if (!newFolder.vehicles) {
      newFolder['vehicles'] = []
    }
    newFolder.vehicles.push(valuation)

    this.vehiclesHomeData = homeData
    this.emit("vehicles_home_change")
  }

  changeRecentsValuationFolder(valuation, existingFolderID, targetFolder, clientID) {
    // Direct manipulation to show immediate changes
    const paginatedCollection = this.recentValuations.dataWithID(clientID)
    const newCollection = [...paginatedCollection.collection]
    const targetRecent = newCollection.find((r) => r.uuid === valuation.uuid)
    if (targetRecent && targetRecent.vehicle_folder && targetRecent.vehicle_folder.id !== targetFolder.id) {
      targetRecent.vehicle_folder = targetFolder
      paginatedCollection.collection = newCollection
      this.recentValuations.addToCollection(clientID, paginatedCollection)
      this.emit('recent_valuations_change')
    }

  }

  changeFolderValuationsFolder(valuation, existingFolderID, targetFolder, clientID) {
    // We're viewing a folder, so remove it from the list
    // Direct manipulation to show immediate changes
    var paginatedCollection = this.recentValuations.dataWithID(clientID)
    var newCollection = [...paginatedCollection.collection]
    for (let i = 0; i < newCollection.length; i++) {
      if (newCollection[i].uuid === valuation.uuid) {
        newCollection.splice(i, 1)
        paginatedCollection.collection = newCollection
        this.recentValuations.addToCollection(clientID, paginatedCollection)
        this.emit('recent_valuations_change')
        break
      }
    }
  }

  hideHUD = () => {
    this.emit('hud_should_hide')
  }

  showHUD = () => {
    this.emit('hud_should_show')
  }

  handleActions(action) {
    switch(action.type) {
      case ActionTypes.RECEIVED_VALUATION_PREVIEW: {
        const clientID = action.clientID
        this.valuationPreviews.addToCollection(clientID, action.preview)
        this.errors = action.errors
        this.emit(`valuation_preview_change_${clientID}`)

        break
      }
      case ActionTypes.RECEIVED_EXISTING_VALUATION:
      case ActionTypes.RECEIVED_VALUATION: {
        // FIXME: not called with latest refactor until we implemen the create cycle
        const clientID = action.clientID
        this.valuations.addToCollection(clientID, action.valuation)
        this.errors = action.errors
        this.emit(`valuation_change_${clientID}`)

        if (action.type !== ActionTypes.RECEIVED_EXISTING_VALUATION) {
          this.emit('existing_valuation_received')
        }

        if (action.isNew === true) {
          this.emit('valuation_created')
        }

        // If we created a valuation with a default folder, need to reload folders
        if (action.valuation && action.valuation.vehicle_folder) {
          ValuationActionCreators.loadFolders()
          this.emit('valuation_folder_updated')
        }

        break
      }

      case ActionTypes.RECEIVED_PUBLIC_VALUATION: {
        this.publicValuation = action.valuation
        this.publicValuationErrors = action.errors
        this.emit("public_valuation_received")
        break
      }

      case ActionTypes.RECEIVED_UPDATE_VALUATION: {
        const clientID = action.clientID

        this.valuations.addToCollection(clientID, action.valuation)
        this.emit('valuation_folder_updated')
        this.emit(`valuation_change_${clientID}`)

        if (action.valuation && action.valuation.runlist_listing) {
          AuctionStore.listingWasUpdated(action.valuation.runlist_listing, true)
        }

        break
      }

      case ActionTypes.RECEIVED_LINKED_VALUATION_PARTNER: {
        const valuation = action.valuation
        const clientID = action.clientID
        this.valuationPartnerActions.addToCollection(clientID, valuation['errors'] ? valuation : valuation['partners'])
        this.emit(`vehicle_partners_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_UPDATE_VALUATION_CHOICE: {
        const clientID = action.clientID
        this.valuations.addToCollection(clientID, action.valuation)
        this.emit(`valuation_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_DELETE_VALUATION: {
        this.emit('valuation_deleted')
        ValuationActionCreators.loadFolders()
        break
      }

      case ActionTypes.VALUATION_NOTE_CREATED: {
        const clientID = action.clientID
        this.valuations.addToCollection(clientID, action.valuation)
        this.emit("valuation_folder_updated")
        this.emit(`valuation_change_${clientID}`)
        this.emit("valuation_note_created")

        if (action.valuation && action.valuation.runlist_listing) {
          AuctionStore.listingWasUpdated(action.valuation.runlist_listing)
        }
        break
      }

      case ActionTypes.RECEIVED_VALUATION_NOTES: {
        const clientID = action.clientID
        this.valuationPreviewNotes.addToCollection(clientID, action.notes)
        this.emit(`valuation_notes_received_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_PREVIEW_FOR_VEHICLE: {
        this.vehiclePreview = action.preview
        this.emit('vehicle_preview_change')
        break
      }

      case ActionTypes.RECEIVED_UPDATE_VALUATION_CHOICES: {
        this.emit('vehicle_choices_change')
        break
      }

      case ActionTypes.LOAD_VEHICLES_HOME: {
        this.vehiclesHomeAreLoading = true
        this.emit("vehicles_home_loading_change")
        break
      }

      case ActionTypes.LOAD_RECENT_VALUATIONS: {
        break
      }

      case ActionTypes.RECEIVED_VEHICLES_HOME: {
        const errors = action.errors
        if (errors) {
          this.vehiclesHomeData = {errors: errors}
        } else {
          this.vehiclesHomeData = action.data
        }

        this.vehiclesHomeAreLoading = false
        this.emit("vehicles_home_loading_change")
        this.emit("vehicles_home_change")
        break
      }

      case ActionTypes.RECEIVED_PMR_SAMPLE: {
        const clientID = action.clientID
        this.pmrSampleDetails.addToCollection(clientID, action.sample)
        this.emit(`pmr_sample_received_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_ALL_PMR_SAMPLES: {
        const clientID = action.clientID
        this.pmrAllSamples.addToCollection(clientID, action.samples)
        this.emit(`pmr_all_samples_received_${clientID}`)
        break
      }

      case ActionTypes.FOLDER_CREATED: {
        this.emit("folder_created")
        break
      }

      case ActionTypes.FOLDER_UPDATED: {
        this.emit("folder_updated")
        break
      }

      case ActionTypes.FOLDERS_ORDER_UPDATED: {
        this.emit("folders_order_updated")
        break
      }

      case ActionTypes.FOLDER_DELETED: {
        this.emit("folder_deleted")
        ValuationActionCreators.loadFolders()
        break
      }

      case ActionTypes.RECEIVED_FOLDERS: {
        this.folders = action.folders
        this.emit("folders_change")
        break
      }

      case ActionTypes.RECEIVED_FOLDER_INFO: {
        const clientID = action.clientID
        const folder = action.folder

        this.folderInfo.addToCollection(clientID, folder)
        this.emit(`valuation_folder_info_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_RECENT_VALUATIONS: {
        const { clientID, errors, listResponseType, order, valuations: data } = action

        switch (listResponseType) {
          case RemoteConstants.ListResponseTypes.CUTOFF:
            this.recentValuations.dataWithID(clientID).setLastPage(data, errors)
            break
          default:
            this.recentValuations.addToCollection(clientID, new PaginatedCollection(data, errors, order, 'uuid'))
            break
        }

        this.emit("recent_valuations_change")
        break
      }

      case ActionTypes.RECEIVED_VALUATIONS_CORPUS: {
        this.valuationsCorpus = action.corpus
        this.emit('valuation_corpus_change')
        break
      }

      case ActionTypes.RECEIVED_VALUATIONS_SEARCH: {
        const { errors, listResponseType, order, results } = action

        switch (listResponseType) {
          case RemoteConstants.ListResponseTypes.CUTOFF:
            this.valuationsSearchResults.setLastPage(results, errors)
            break
          default:
            this.valuationsSearchResults = new PaginatedCollection(results, errors, order, 'uuid')
            break
        }

        this.emit('valuation_search_change')
        break
      }

      case ActionTypes.LOAD_CARFAX_SUMMARY: {
        const vehicleUUID = action.vehicleUUID
        this.carfaxIsLoadingTrackers.addToCollection(vehicleUUID, true)
        this.emit(`carfax_summary_is_loading_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.RECEIVED_CARFAX_SUMMARY: {
        const vehicleUUID = action.vehicleUUID
        this.carfaxIsLoadingTrackers.addToCollection(vehicleUUID, false)
        if (!action.summary) {
          this.carfaxSummaries.addToCollection(vehicleUUID, {errors: `Error fetching ${AppConstants.providerNames.carfax}`})
        } else if (action.summary.errors) {
          this.carfaxSummaries.addToCollection(vehicleUUID, {errors: action.summary.errors})
        } else {
          this.carfaxSummaries.addToCollection(vehicleUUID, action.summary)
        }
        this.emit(`carfax_summary_is_loading_changed_${vehicleUUID}`)
        this.emit(`carfax_summary_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.RECEIVED_AUTOCHECK_SUMMARY: {
        const vehicleUUID = action.vehicleUUID
        this.autoCheckIsLoadingTrackers.addToCollection(vehicleUUID, false)
        if (!action.summary) {
          this.autoCheckSummaries.addToCollection(vehicleUUID, {errors: `Error fetching ${AppConstants.providerNames.autocheck}`})
        } else if (action.summary.errors) {
          this.autoCheckSummaries.addToCollection(vehicleUUID, {errors: action.summary.errors})
        } else {
          this.autoCheckSummaries.addToCollection(vehicleUUID, action.summary)
        }
        this.emit(`autocheck_summary_is_loading_changed_${vehicleUUID}`)
        this.emit(`autocheck_summary_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.LOAD_AUTOCHECK_SUMMARY: {
        const vehicleUUID = action.vehicleUUID
        this.autoCheckIsLoadingTrackers.addToCollection(vehicleUUID, true)
        this.emit(`autocheck_summary_is_loading_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.LOAD_VINTEL_REPORT: {
        const vehicleUUID = action.vehicleUUID
        this.vintelIsLoadingTrackers.addToCollection(vehicleUUID, true)
        this.emit(`vintel_report_is_loading_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.RECEIVED_VINTEL_REPORT: {
        const { report, vehicleUUID } = action
        this.vintelIsLoadingTrackers.addToCollection(vehicleUUID, false)
        if (!report) {
          this.vintelReports.addToCollection(vehicleUUID, {errors: `Error fetching ${AppConstants.providerNames.vintel}`})
        } else if (report.errors) {
          this.vintelReports.addToCollection(vehicleUUID, {errors: report.errors})
        } else {
          this.vintelReports.addToCollection(vehicleUUID, report)
        }
        this.emit(`vintel_report_is_loading_changed_${vehicleUUID}`)
        this.emit(`vintel_report_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.RECEIVED_AUTOCHECK_REPORT: {
        this.autoCheckReport = action.report
        this.emit('autocheck_report_changed')
        break
      }

      case ActionTypes.RECEIVED_MILEAGE_FAMILY: {
        const vehicleUUID = action.vehicleUUID
        this.mileageFamilies.addToCollection(vehicleUUID, action.mileageFamily)
        this.emit(`mileage_family_changed_${vehicleUUID}`)
        break
      }

      case ActionTypes.VALUATION_SHARE_CREATED: {
        const clientID = action.clientID
        this.valuationShares.addToCollection(clientID, action.valuationShare)

        this.emit(`valuation_share_created_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_SHARED_VALUATION: {
        this.sharedValuation = action.valuation
        if (action.errors) {
          this.sharedValuationErrors = action.errors
        } else {
          this.sharedValuationErrors = null
        }

        this.emit('shared_valuation_change')
        break
      }

      case ActionTypes.PHOTO_DELETED: {
        this.deletedPhotoID = action.deletedPhotoID

        this.emit('valuation_photo_deleted')
        // The server seems to async change the primary photo after primary is deleted
        // Give it a little time to do so before triggering events that update activity
        setTimeout(() => {
          this.emit(`valuation_change_${action.clientID}`)
          this.emit("valuation_folder_updated")
        }, 2000);

        break
      }

      case ActionTypes.PHOTO_UPDATED: {
        this.emit('valuation_photo_updated')
        this.emit(`valuation_change_${action.clientID}`)
        this.emit("valuation_folder_updated")
        break
      }

      case ActionTypes.LOAD_VEHICLE_ACTIVITIES: {
        break
      }

      case ActionTypes.RECEIVED_VEHICLE_ACTIVITIES: {
        const { errors, data, listResponseType, clientID, order } = action

        switch (listResponseType) {
          case RemoteConstants.ListResponseTypes.CUTOFF:
            this.vehicleActivitiesData.dataWithID(clientID).setLastPage(data, errors)
            break
          default:
            this.vehicleActivitiesData.addToCollection(clientID, new PaginatedCollection(data, errors, order))
            break
        }

        this.emit(`vehicle_activities_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_LLM_SAMPLE_DETAIL: {
        // FIXME: error handling
        this.llmSampleDetail = action.sampleDetail
        this.emit('llm_sample_detail_change')
        break
      }

      case ActionTypes.RECEIVED_LLM_TRIM_SAMPLES: {
        this.llmTrimSamples = action.samples
        this.emit('llm_trim_samples_change')
        break
      }

      case ActionTypes.RECEIVED_MY_LOT_VEHICLES : {
        const { errors, data, clientID, page = 1 } = action
        if (errors) {
          this.myLotVehicles.addToCollection(clientID, { errors })
        } else {
          const isLastPage = !data.length
          if (page === 1) {
            this.myLotVehicles.addToCollection(clientID, { data, page, isLastPage })
          } else {
            const nextData = [...(this.myLotVehicles.dataWithID(clientID)?.data || []), ...data]
            this.myLotVehicles.addToCollection(clientID, { data: nextData, page, isLastPage } )
          }
        }
        this.emit(`my_lot_vehicles_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_MY_LOT_VEHICLES_DETAILS : {
        this.myLotVehiclesDetails = action.data
        this.emit('my_lot_vehicles_details_change')
        break
      }

      case ActionTypes.RECEIVED_MY_LOT_REPORT : {
        this.myLotReport = action.data
        this.isLoadingMyLotReport = false
        this.emit('my_lot_report_change')
        this.emit('is_loading_my_lot_report_change')
        break
      }

      case ActionTypes.LOAD_MY_LOT_REPORT : {
        this.isLoadingMyLotReport = true
        this.emit('is_loading_my_lot_report_change')
        break
      }

      case ActionTypes.RECEIVED_MY_LOT_COMPETITORS_REPORT : {
        const { data, clientID } = action
        this.myLotCompetitorsReport.addToCollection(clientID, data)
        this.myLotCompetitorsReportLoadingTrackers.addToCollection(clientID, false)
        this.emit(`my_lot_competitors_report_change_${clientID}`)
        this.emit(`is_loading_my_lot_competitors_report_change_${clientID}`)
        break
      }

      case ActionTypes.LOAD_MY_LOT_COMPETITORS_REPORT : {
        const { clientID } = action
        this.myLotCompetitorsReportLoadingTrackers.addToCollection(clientID, true)
        this.emit(`is_loading_my_lot_competitors_report_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_REGIONAL_LISTINGS: {
        const { clientID, data, page = 1 } = action
        const isLastPage = !data.length
        const nextData = page === 1 ? data : [...this.regionalListings.dataWithID(clientID)?.data, ...data]
        this.regionalListings.addToCollection(clientID, { data: nextData, page, isLastPage } )
        this.emit(`regional_listings_change_${clientID}`)
        break
      }

      case ActionTypes.LOAD_REGIONAL_MODEL_STATS: {
        const { clientID } = action
        this.regionalModelStatsLoadingTrackers.addToCollection(clientID, true)
        this.emit(`regional_model_stats_is_loading_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_REGIONAL_MODEL_STATS: {
        const { clientID, data } = action
        this.regionalModelStats.addToCollection(clientID, data)
        this.regionalModelStatsLoadingTrackers.addToCollection(clientID, false)
        this.emit(`regional_model_stats_is_loading_change_${clientID}`)
        this.emit(`regional_model_stats_change_${clientID}`)
        break
      }

      case ActionTypes.LOAD_REGIONAL_MODEL_SCORECARD: {
        const { clientID } = action
        this.regionalModelScorecardLoadingTrackers.addToCollection(clientID, true)
        this.emit(`regional_model_scorecard_is_loading_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_REGIONAL_MODEL_SCORECARD: {
        const { clientID, data } = action
        this.regionalModelScorecard.addToCollection(clientID, data)
        this.regionalModelScorecardLoadingTrackers.addToCollection(clientID, false)
        this.emit(`regional_model_scorecard_is_loading_change_${clientID}`)
        this.emit(`regional_model_scorecard_change_${clientID}`)
        break
      }

      case ActionTypes.RECEIVED_REGIONAL_REPORT : {
        this.regionalReport = action.data
        this.emit('regional_report_change')
        break
      }

      case ActionTypes.RECEIVED_MOST_RECENT_RETAIL_LISTING: {
        this.mostRecentRetailListing = action.mostRecentRetailListing
        this.emit('most_recent_retail_listing_change')
        break
      }

      case ActionTypes.RECEIVED_VEHICLE_REPORT: {
        const { errors, vehicleReport, clientID } = action
        this.vehicleReports.addToCollection(clientID, vehicleReport)

        this.emit(`vehicle_report_change_${clientID}`)
        break
      }

      case ActionTypes.VEHICLE_REPORT_PURCHASED: {
        const { errors, vehicleReport, clientID } = action
        this.vehicleReports.addToCollection(clientID, vehicleReport)

        this.emit('valuation_report_purchased')
        this.emit(`vehicle_report_change_${clientID}`)
        break
      }

      case ActionTypes.LOT_VEHICLE_UPDATED: {
        const { errors, lotVehicle, clientID, params } = action

        this.myLotVehiclesUpdated.addToCollection(clientID, lotVehicle)
        this.emit(`lot_vehicle_updated_${clientID}`)

        const { uuid, alert_snooze_until, ...restParams } = params

        if (Object.keys(restParams).length) {
          this.emit('lot_vehicle_updated')
        }

        if (alert_snooze_until !== undefined) {
          this.emit('lot_vehicle_snooze_updated')
        }
        break
      }

      case ActionTypes.LOT_VEHICLE_DELETED: {
        const { errors, clientID } = action

        this.emit(`lot_vehicle_deleted_${clientID}`)
        this.emit('lot_vehicle_updated')
        break
      }

      default:
        break
    }
  }

}

const valuationStore = new ValuationStore()
Dispatcher.register(valuationStore.handleActions.bind(valuationStore))

export default valuationStore
