import { useCallback, useState, } from 'react'
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap'
import moment from 'moment'
import { Link } from 'react-router-dom'
import CloseIcon from '../../components/common/icons/CloseIcon'
import ListingImage from '../../components/common/ListingImage'
import Alert from '../../components/myLot/Alert'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import MyLotFunctions from '../../api/constants/MyLotFunctions'

const snoozeOptions = [
  { label: '4 days', valueFn: () => moment().add(4, 'days') },
  { label: 'Forever', valueFn: () => 'infinity' },
]

const AlertVehiclePreview = ({ vehicle }) => {
  const { uuid } = vehicle
  const [snoozePopoverIsShown, setSnoozePopoverIsShown] = useState(false)

  const onSnoozeClick = useCallback((e, valueFn) => {
    e.preventDefault()
    const params = {
      alert_snooze_until: valueFn(),
      uuid,
    }
    ValuationActionCreators.updateLotVehicle(params, uuid)
    setSnoozePopoverIsShown(false)
  }, [uuid])

  const snoozeId = `snooze-${uuid}`
  const url = MyLotFunctions.myLotVehicleURL(vehicle)

  return (
    <div className='my-lot-alert-vehicle-preview'>
      <Link to={url}>
        <div className="thumbnail">
          <ListingImage src={vehicle.photo_urls[0]} />
        </div>
        <div className='info'>
          <div className='year'>{vehicle.year}</div>
          <div className='make-model'>{`${vehicle.make} ${vehicle.model}`}</div>
          <div className='alerts'>
            {
              vehicle.alerts.map((alert, i) => (
                <div key={i}>
                  <Alert alert={alert} year={vehicle.year} make={vehicle.make} model={vehicle.model} trim={vehicle.trim} />
                </div>
              ))
            }
          </div>
        </div>
        <div className='snooze'>
          <button
            className='text-button'
            id={snoozeId}
            onClick={(e) => e.preventDefault()}
          >
            <CloseIcon color='#aaa' />
          </button>
        </div>
      </Link>
      <Popover
        placement='bottom-start'
        fade={false}

        isOpen={snoozePopoverIsShown}
        trigger='legacy'
        target={snoozeId}
        toggle={() => setSnoozePopoverIsShown(!snoozePopoverIsShown)}
      >
        <PopoverHeader>Snooze Alerts</PopoverHeader>
        <PopoverBody className='popover-sm'>
          {
            snoozeOptions.map((option, i) => (
              <a
                key={i}
                className='popover-item'
                href={`#snooze-${i}`}
                onClick={(e) => onSnoozeClick(e, option.valueFn)}
              >
                {option.label}
              </a>
            ))
          }
        </PopoverBody>
      </Popover>
    </div>
  )
}

export default AlertVehiclePreview
