import { cloneElement, useState } from 'react'
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import AppConstants from '../../api/constants/AppConstants'

const optionGroups = [
  [
    'created_at',
    'sold_at',
  ],
  [
    'price_cents',
    'purchase_price_cents',
    'profit_cents',
  ],
  [
    'days_on_lot',
  ],
  [
    'num_price_changes',
  ],
  [
    'mileage',
    'year',
    'make',
  ],
  [
    'last_alerted_at',
  ],
]

const MyLotVehiclesSortControl = ({ selected, setSelected, excludedOptions = [] }) => {
  const [sortDropdownIsOpen, setSortDropdownIsOpen] = useState(false)

  const dropdownItemForSort = (sort, enabled = true) => {
    return (
      <DropdownItem active={selected === sort} onClick={() => setSelected(sort)} disabled={!enabled}>
        { AppConstants.myLotSortParams[sort] }
      </DropdownItem>
    )
  }

  const options = optionGroups.reduce((acc, group) => {
    const result = []
    group.forEach(sort => {
      if (!excludedOptions.includes(sort)) {
        result.push(...['DESC', 'ASC'].map(direction => dropdownItemForSort(`${sort} ${direction}`)))
      }
    })

    return result.length ? [...acc, result] : acc
  }, [])
  .flatMap(group => [...group.flat(), <DropdownItem divider />]).slice(0, -1)
  .map((item, i) => cloneElement(item, { key: i }))

  return (
    <Dropdown size="sm" group isOpen={sortDropdownIsOpen} toggle={() => setSortDropdownIsOpen(!sortDropdownIsOpen)} value={selected}>
      <DropdownToggle tag="div" caret className='dropdown-contained'>
        { AppConstants.myLotSortParams[selected] }
      </DropdownToggle>
      <DropdownMenu>
        { options }
      </DropdownMenu>
    </Dropdown>
  )
}


export default MyLotVehiclesSortControl
