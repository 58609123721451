import { createRoot } from 'react-dom/client'
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react'
import * as Sentry from "@sentry/react"
import Modal from 'react-modal'
import ConnectivityStore from './api/stores/ConnectivityStore'
import Layout from './pages/Layout'
import ShareLayout from './pages/ShareLayout'
import ExtensionLayout from './pages/ExtensionLayout'
import IFrameLayout from './pages/IFrameLayout'
import withAccessControl from './hoc/withAccessControl'
import packageJson from '../package.json'
import * as serviceWorker from './serviceWorker'
import './style/index.scss'
import './charts/setup'

import { ApolloProvider } from '@apollo/client'
import dotAuctionClientConfig from './dotAuctionClientConfig'
import createDotAuctionClient from './api/bidding/createDotAuctionClient'

ConnectivityStore.setApiRoot(process.env.REACT_APP_API_ROOT)
ConnectivityStore.setApolloApiRoot(process.env.REACT_APP_APOLLO_API_ROOT)
ConnectivityStore.setNewsJSONUrl(process.env.REACT_APP_NEWS_JSON_URL)
ConnectivityStore.setNewsHTMLUrl(process.env.REACT_APP_NEWS_HTML_URL)

global.isExtensionMode = window.name === 'carbly-extension'
global.isIFrameMode = window.name === 'carbly-iframe'

Sentry.init({
  dsn: "https://c6370c8a4aca4545bca90b052ebf79ad@o509292.ingest.sentry.io/5603752",
  release: `carbly-desktop@${packageJson.version}`,
  environment: process.env.REACT_APP_ENV_NAME,
  autoSessionTracking: true
});


Modal.setAppElement('#root')

let View

if (window.location.href.indexOf('/shared/') > -1) {
  View = ShareLayout
} else if (global.isExtensionMode) {
  View = withAccessControl(ExtensionLayout)
} else if (global.isIFrameMode || global.isPartnerPreview) {
  View = withAccessControl(IFrameLayout)
} else {
  View = withAccessControl(Layout)
}

const container = document.getElementById('root');
const root = createRoot(container)
root.render(
  <ApolloProvider client={createDotAuctionClient(dotAuctionClientConfig)}>
    <View />
  </ApolloProvider>
)

serviceWorker.unregister();
