import { useCallback, useMemo, useRef, useState } from 'react'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ListingsList from './ListingsList'
import CloseIcon from '../common/icons/CloseIcon'

const makeListingsSortOptions = params => {
  return [
    {
      label: '',
      options: [
        { value: 'mileage', label: 'Mileage (low to high)' },
        { value: '-mileage', label: 'Mileage (high to low)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'price', label: 'Price (low to high)' },
        { value: '-price', label: 'Price (high to low)' },
      ],
    },
    params.year == null && {
      label: '',
      options: [
        { value: 'year', label: 'Year (old to new)' },
        { value: '-year', label: 'Year (new to old)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'days_on_lot', label: 'Days on lot (low to high)' },
        { value: '-days_on_lot', label: 'Days on lot (high to low)' },
      ],
    },
    {
      label: '',
      options: [
        { value: 'num_price_changes', label: 'Price changes (low to high)' },
        { value: '-num_price_changes', label: 'Price changes (high to low)' },
      ],
    },
  ].filter(Boolean)
}

const reactSelectGroupStyles = {
  option: (provided) => ({
    ...provided,
    padding: '5px 10px'
  }),
  group: (provided) => ({
    ...provided,
    borderBottom: '1px solid #aaa',
  }),
  control: (provided) => ({
    ...provided,
    width: '100%',
    marginBottom: '5px'
  })
}

const ListingsModal = ({ regionalListingsParams, onClose, title }) => {
  const ref = useRef(null)
  const listingsSortOptions = useMemo(() => {
    return makeListingsSortOptions(regionalListingsParams )
  }, [regionalListingsParams])

  const [selectedSortOption, setSelectedSortOption] = useState(listingsSortOptions[0].options[0])
  const order = selectedSortOption.value

  const getScrollParent = useCallback(() => {
    return ref.current
  }, [])

  // FIXME: For pagination, moved scroll to parent, so footer close button is not visible

  return (
    <Modal innerRef={ref} isOpen={true} zIndex={10000} toggle={onClose} centered backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '840px'}}>
      <ModalHeader className='modal-header' style={{display: 'block'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div>{title ?? 'Nearby Retail Listings'}</div>
          <a href='#close' style={{fontSize: '2rem', color: '#fff'}} onClick={onClose}><CloseIcon width={32} height={32} /></a>
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          <div className="split-row split-row-left" style={{marginBottom: '10px'}}>
            <div style={{marginRight: '10px'}}>Sort by</div>
            <div style={{ width: 280 }}>
              <Select
                styles={reactSelectGroupStyles}
                name='listings-sort'
                className='react-select'
                value={selectedSortOption}
                onChange={setSelectedSortOption}
                options={listingsSortOptions}
                isSearchable={false}
              />
            </div>
          </div>
          <ListingsList
            regionalListingsParams={regionalListingsParams}
            order={order}
            getScrollParent={getScrollParent}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ListingsModal
