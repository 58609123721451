import React from 'react'
import { Input, Button } from 'reactstrap'
import CurrencyInputField from '../common/CurrencyInputField'
import DisclosureIcon from '../common/icons/DisclosureIcon'
import SessionStore from '../../api/stores/SessionStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import UserActionCreators from '../../api/actions/UserActionCreators'
import AuctionFunctions from '../../api/constants/AuctionFunctions'
import AppConstants from '../../api/constants/AppConstants'

const CR_COSTS_KEYS = [
  'vehicle_condition_zero_cost_cents',
  'vehicle_condition_one_cost_cents',
  'vehicle_condition_two_cost_cents',
  'vehicle_condition_three_cost_cents',
  'vehicle_condition_four_cost_cents',
  'vehicle_condition_five_cost_cents',
  'vehicle_condition_none_cost_cents'
]


export default class GlobalCostsEntry extends React.Component {

  state = {
    step: 0,
    modifiedCRCosts: [],
    modifiedTransportCost: 2,
    modifiedAuctionFees: 250,
    modifiedOtherCosts: 0,
    crCostsAreSequenced: true
  }

  componentDidMount() {
    const rooftop = SessionStore.user?.rooftop
    let costs = []
    costs.push(rooftop[CR_COSTS_KEYS[0]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[1]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[2]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[3]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[4]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[5]] / 100)
    costs.push(rooftop[CR_COSTS_KEYS[6]] / 100)

    if (rooftop) {
      this.setState({
        modifiedCRCosts: costs,
        modifiedTransportCost: rooftop.vehicle_transportation_cost_cents / 100,
        modifiedAuctionFees: rooftop.vehicle_auction_fees_cost_cents / 100,
        modifiedOtherCosts: rooftop.vehicle_misc_fees_cost_cents / 100
      })
    }
  }

  renderConditionCost = (condition) => {
    const isNoCR = condition === 6
    const rowStyle = isNoCR ?
      {display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', maxWidth: '320px', marginTop: '10px'} :
      {maxWidth: '90px', textAlign: 'center'}
      
    const fieldStyle = isNoCR ? {maxWidth: '90px'} : {}
    const labelStyle = isNoCR ? {marginRight: '10px'} : {}

    return (
      <div style={rowStyle} key={`c-${condition}`}>
        <CurrencyInputField
          style={{margin: 0, ...fieldStyle}}
          onChange={(cost) => {
            let sanitizedCost = CommonFunctions.stripNonNumericNoDecimal(cost)
            let newModifiedCRCosts = [...this.state.modifiedCRCosts]
            newModifiedCRCosts[condition] = sanitizedCost
            this.setState({
              modifiedCRCosts: newModifiedCRCosts,
              crCostsAreSequenced: AuctionFunctions.crCostsAreSequenced(newModifiedCRCosts)
            })
          }}
          maxLength={6}
          value={this.state.modifiedCRCosts[condition]}
        />
        <div style={labelStyle}>{isNoCR ? 'Vehicles with no CR score' : `CR ${condition}`}</div>
      </div>
    )
  }

  saveCosts = () => {
    if (this.props.onSave) {
      this.props.onSave()
    }
    
    const { modifiedCRCosts, modifiedTransportCost, modifiedAuctionFees, modifiedOtherCosts } = this.state
    UserActionCreators.updateRooftop(
      {
        [CR_COSTS_KEYS[0]]: modifiedCRCosts[0] * 100,
        [CR_COSTS_KEYS[1]]: modifiedCRCosts[1] * 100,
        [CR_COSTS_KEYS[2]]: modifiedCRCosts[2] * 100,
        [CR_COSTS_KEYS[3]]: modifiedCRCosts[3] * 100,
        [CR_COSTS_KEYS[4]]: modifiedCRCosts[4] * 100,
        [CR_COSTS_KEYS[5]]: modifiedCRCosts[5] * 100,
        [CR_COSTS_KEYS[6]]: modifiedCRCosts[6] * 100,
        vehicle_transportation_cost_cents: modifiedTransportCost * 100,
        vehicle_auction_fees_cost_cents: modifiedAuctionFees * 100,
        vehicle_misc_fees_cost_cents: modifiedOtherCosts * 100,
        vehicle_costs_confirmed: true
      }
    )
    UserActionCreators.createMetric(AppConstants.metricNames['global_costs_save'])
  }


  render() {
    const { step, modifiedCRCosts, modifiedTransportCost, modifiedAuctionFees, modifiedOtherCosts, crCostsAreSequenced } = this.state

    let isReconSubmittable = true
    for (let cost of modifiedCRCosts) {
      if (cost === null || cost === undefined || cost === '') {
        isReconSubmittable = false
        break
      }
    }

    let isFormSubmitable = true
    if (!modifiedTransportCost || modifiedTransportCost === '' || !modifiedAuctionFees || modifiedAuctionFees === '') {
      isFormSubmitable = false
    }

    switch (step) {
      case 0:
      // Recon Estimates
      return (
        <div className='profit-enable'>
          <img src="/images/wrench-bg.png" style={{width: '200px', height: '200px', position: 'absolute', right: '0', top: '0', opacity: 0.12, pointerEvents: 'none'}} />
          <h3 className="subsection-title">Recon Estimates</h3>
          <div style={{lineHeight: '1.3rem'}}>Enter approximate reconditioning costs for each Condition Report score to help us determine possible vehicle profits.  You can always change these costs later on the settings tab. You can also override these numbers for any particular vehicle.</div>
  
          <div className='split-row' style={{marginTop: '10px', maxWidth: '630px', opacity: '0.45'}}>
            <div style={{minWidth: '130px'}}>Poor condition</div>
            <div style={{width: '100%', flexGrow: 1, flexShrink: 1}}>
              <div style={{height: '1px', backgroundColor: '#fff', width: '100%', position: 'relative'}}>
                <div style={{position: 'absolute', right: 0, width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderLeft: '10px solid #fff', marginTop: '-7.5px'}} />
                <div style={{position: 'absolute', left: 0, width: 0, height: 0, borderTop: '8px solid transparent', borderBottom: '8px solid transparent', borderRight: '10px solid #fff', marginTop: '-7.5px'}} />
              </div>
            </div>
            <div style={{minWidth: '140px', textAlign: 'right'}}>Clean condition</div>
          </div>
          <div className='split-row' style={{marginTop: '10px', maxWidth: '630px'}}>
            { this.renderConditionCost(0) }
            { this.renderConditionCost(1) }
            { this.renderConditionCost(2) }
            { this.renderConditionCost(3) }
            { this.renderConditionCost(4) }
            { this.renderConditionCost(5) }
          </div>


          { this.renderConditionCost(6) }


          <div style={{height: '30px'}}>
            {
              !crCostsAreSequenced && isFormSubmitable &&
              <div style={{color: '#fff', textAlign: 'center', backgroundColor: 'rgba(255,255,255,0.25', borderRadius: '4px', marginTop: '10px'}}>Please enter recon costs in order from Poor to Clean</div>
            }
          </div>
          <div style={{textAlign: 'right', marginTop: '20px'}}>
            <Button color='primary' disabled={!isReconSubmittable || !crCostsAreSequenced} onClick={() => this.setState({ step: 1 })}>These Recon Estimates Look Good <DisclosureIcon style={{marginTop: '-4px'}} /></Button>
          </div>
        </div>
      )

      case 1:
        // Other Costs
      return (
        <div className='profit-enable'>
          <img src="/images/wrench-bg.png" style={{width: '200px', height: '200px', position: 'absolute', right: '0', top: '0', opacity: 0.12, pointerEvents: 'none'}} />
          <h3 className="subsection-title">Other Costs</h3>
          <div>Enter approximate costs per vehicle for each field.  These costs will help us determine profit potential for each vehicle.</div>

          
          <div className='split-row' style={{marginTop: '10px'}}>
            
            <div style={{textAlign: 'center'}}>
              <div>Transport cost per mile</div>
              <CurrencyInputField
                style={{margin: 0, width: '100px' }}
                onChange={(cost) => {
                  this.setState({ modifiedTransportCost: cost })
                }}
                value={modifiedTransportCost}
                max={9.99}
                acceptCents
              />
            </div>

            <div style={{textAlign: 'center'}}>
              <div>Auction Fees</div>
              <CurrencyInputField
                style={{margin: 0, width: '100px' }}
                onChange={(cost) => {
                  this.setState({ modifiedAuctionFees: cost })
                }}
                value={modifiedAuctionFees}
              />
            </div>

            <div style={{textAlign: 'center'}}>
              <div>Other Costs</div>
              <CurrencyInputField
                style={{margin: 0, width: '100px' }}
                onChange={(cost) => {
                  this.setState({ modifiedOtherCosts: cost })
                }}
                value={modifiedOtherCosts}
              />
            </div>
            
            
          </div>
          <div style={{textAlign: 'right', marginTop: '20px'}}>
            <Button disabled={!isFormSubmitable} color='primary' onClick={this.saveCosts}>Finish and Save</Button>
          </div>
        </div>
      )
    
      default:
        break;
    }

    
    
  }

}