import React from 'react'
import { Router, Route, Redirect, Switch, NavLink } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { detect } from 'detect-browser'
import { ToastContainer, Slide, toast } from 'react-toastify'
import DashboardPage from './DashboardPage'
import VehiclesPage from './VehiclesPage'
import AuctionsPage from './AuctionsPage'
import LoginPage from './LoginPage'
import AccountCannotBeReinstated from './AccountCannotBeReinstated'
import IncompatibleBrowserPage from './IncompatibleBrowserPage'
import SettingsPage from './SettingsPage'
import ExtensionPage from './ExtensionPage'
import ExtensionIcon from '../components/common/icons/ExtensionIcon'
import MobileDownloadPage from './MobileDownloadPage'
import VehicleActivitiesPage from './VehicleActivitiesPage'
import PaymentFormModal from '../components/settings/PaymentFormModal'
import SessionExclusion from '../components/common/SessionExclusion'
import UserVerificationModal from '../components/common/UserVerificationModal'
import SessionActionCreators from '../api/actions/SessionActionCreators'
import SessionStore from '../api/stores/SessionStore'
import VersionChecker from '../components/common/VersionChecker'
import AutoCheckReport from '../components/valuations/AutoCheckReport'
import AppConstants from '../api/constants/AppConstants'
import QueryParamsFunctions from '../api/constants/QueryParamsFunctions'
import NewsItem from '../components/user/NewsItem'
import withAuctionEdgeSubscription from '../api/bidding/hoc/withSubscription'
import platformFunctions from '../platformFunctions'
import HelpIcon from '../components/common/icons/HelpIcon'
import RegionalReportsPage from './regionalReports/Page'
import RegionalReportsIcon from '../components/common/icons/RegionalReportsIcon'
import MyLot from './myLot/Page'

const browser = detect()
const dashEnabled = false

class Layout extends React.Component {
  state = {
    windowIsTooSmall: false,
    incompatibleBrowser: false,
    shouldShowNewsNotification: SessionStore.shouldShowNewsNotification()
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize)
    SessionStore.on('news_notification_change', this.newsNotificationDidChange)

    this.updateWindowSize()
    if (browser && browser.name && browser.name === 'ie' && browser.version) {
      if (parseFloat(browser.version) < 11) {
        this.setState({
          incompatibleBrowser: true
        })
      }
    }
    this.notifyCarfaxConnectAuthStatus()
    setTimeout(() => {
      this.checkForNews()
    }, AppConstants.initialNewsCheckDelay)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize)
    SessionStore.removeListener('news_notification_change', this.newsNotificationDidChange)
  }

  checkForNews = () => {
    SessionActionCreators.checkForNews()
    setTimeout(() => {
      this.checkForNews()
    }, AppConstants.newsCheckPeriod)
  }

  newsNotificationDidChange = () => {
    const shouldShow = SessionStore.shouldShowNewsNotification()
    this.setState({ shouldShowNewsNotification: shouldShow})
  }

  notifyCarfaxConnectAuthStatus = () => {
    const paramsObj = QueryParamsFunctions.toObject()
    const authStatus = paramsObj.carfax_connect_authorize_status
    if (authStatus) {
      const messageBase = `${AppConstants.providerNames['carfax']} authorization`
      if (authStatus === '1') {
        toast.success(`${messageBase} success`)
      } else if (authStatus === '0') {
        toast.error(`${messageBase} failed`)
      }
      const queryString = QueryParamsFunctions.delete('carfax_connect_authorize_status')
      this.props.history.replace({ ...this.props.history, search: `?${queryString}` })
    }
  }

  updateWindowSize = () => {
    const isMobile = window.innerWidth < 800
    if (isMobile !== this.state.windowIsTooSmall) {
      this.setState({
        windowIsTooSmall: isMobile
      })
    }
  }

  renderActiveIcon = () => (<svg height="24" width="10" className="active-indicator"><polygon points="0,0 10,12 0,24" /></svg>)

  render() {
    const { history, shouldShowSettingsBadge, isLoadingUser, isAuthenticated, accountIsActive, canBeReinstated, needsCarblySubscription, accountStatusRestricted, accountStateMessage, accountUpdateStatus, isAccountOwner, forcePaymentUpdate } = this.props
    const { incompatibleBrowser, windowIsTooSmall, shouldShowNewsNotification } = this.state

    if (windowIsTooSmall && isAuthenticated === true) {
      return (
        <MobileDownloadPage accountStateMessage={accountStateMessage} accountUpdateStatus={accountUpdateStatus} forcePaymentUpdate={forcePaymentUpdate} isAccountOwner={isAccountOwner} />
      )
    }

    if (isLoadingUser) {
      return ( null )
    }

    if (isAuthenticated === false) {
      return (
        <LoginPage />
      )
    }

    const loc = window.location.href
    if (loc.indexOf('/autocheck_report/') > -1) {
      return (
        <AutoCheckReport />
      )
    }

    if (incompatibleBrowser === true) {
      return <IncompatibleBrowserPage />
    }

    if (accountIsActive === false && canBeReinstated === false) {
      return <AccountCannotBeReinstated message={accountStateMessage}/>
    }

    // Reactivation is handled at the page level to allow auctions to be accessible

    return (
      <Router history={history}>
        <DndProvider backend={HTML5Backend}>
          <div className="page-c">
            {
              shouldShowNewsNotification &&
              <NewsItem newsItem={SessionStore.newsNotification} notification />
            }

            <nav className="primary-nav">
              <img src="/images/carbly-nav-logo.png" alt="Carbly" className="nav-logo" />
              <div className="primary-nav-items">
                {
                  dashEnabled &&
                  <NavLink to="/" exact activeClassName="active">
                    <img src="/images/nav-logo.png" alt="Home" style={{width: '34px', height: '34px'}} />
                    <div className="label">Home</div>
                    { this.renderActiveIcon() }
                  </NavLink>
                }

                <NavLink to="/vehicles" activeClassName="active">
                  <img src="/images/vehicles-icon.png" alt="Vehicles" style={{width: '32px', height: '22px'}} />
                  <div className="label">Vehicles</div>
                  { this.renderActiveIcon() }
                </NavLink>

                <NavLink to="/my-lot" activeClassName="active">
                  {/* <div style={{position: 'absolute', backgroundColor: 'red', color: '#fff', top: '8px', right: '8px', borderRadius: '20px', padding: '2px 4px', fontSize: '0.8rem'}}>22</div> */}
                  <img src="/images/my-lot-icon.png" alt="My Lot" style={{width: '44px', height: '49px'}} />
                  <div className="label">{AppConstants.providerNames['my_lot']}</div>
                  { this.renderActiveIcon() }
                </NavLink>

                <NavLink to="/auctions" activeClassName="active">
                  <div>
                    <div className="icon">
                      <img src="/images/auctions-icon.png" alt="Vehicles" style={{width: '32px', height: '26px'}} />
                    </div>
                    <div className="label">Auctions</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>

                <NavLink to="/market-tracker" activeClassName="active">
                  <div>
                    <div className="icon regional-reports-icon">
                      <RegionalReportsIcon />
                    </div>
                    <div className="label regional-reports-label">Market Tracker</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>
              </div>

              {
                SessionStore.shouldShowExtensionPrompt() &&
                <NavLink to="/extension" activeClassName="active">
                  <div>
                    <div className="icon extension-icon" style={{color: '#fff'}}>
                      <ExtensionIcon />
                    </div>
                    <div className="label">Extension</div>
                    { this.renderActiveIcon() }
                  </div>
                </NavLink>
              }

              <NavLink to="/settings" activeClassName="active">
                <div>
                  <div className="icon">
                    <img src="/images/settings-icon.png" alt="Settings" style={{width: '28px', height: '28px'}} />
                  </div>
                  <div className="label">Settings</div>
                  {
                    shouldShowSettingsBadge === true &&
                    <div className="badge"><div>!</div></div>
                  }
                  { this.renderActiveIcon() }
                </div>
              </NavLink>

              <a href="#help" onClick={(e) => {
                e.preventDefault()
                window.Intercom('show') }}>
                <div className="icon"><HelpIcon /></div>
                <div className="label">Help</div>
              </a>
            </nav>

            <VersionChecker />

            <Switch>
            {
              dashEnabled &&
              <Route
                path='/'
                exact
                children={({ match }) => (
                  <DashboardPage navMatch={match} history={history} />
                )} />
            }

            <Route
              path='/vehicles'
              children={({ match }) => (
                <VehiclesPage navMatch={match} history={history} canBeReinstated={canBeReinstated} needsCarblySubscription={needsCarblySubscription} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/my-lot'
              children={({ match }) => (
                <MyLot navMatch={match} history={history} />
              )} />

            <Route
              path='/auctions'
              children={({ match }) => (
                <AuctionsPage navMatch={match} history={history} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/market-tracker'
              children={({ match }) => (
                <RegionalReportsPage navMatch={match} history={history} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/settings'
              children={({ match }) => (
                <SettingsPage navMatch={match} history={history} canBeReinstated={canBeReinstated} accountStatusRestricted={accountStatusRestricted} accountStateMessage={accountStateMessage} />
              )} />

            <Route
              path='/extension'
              children={({ match }) => (
                <ExtensionPage navMatch={match} history={history} />
              )} />

            <Route
              path='/activity'
              component={ VehicleActivitiesPage }
            />
            </Switch>

            {
              !dashEnabled &&
              <Switch>
                <Route exact strict path="/" render={() => <Redirect to="/vehicles" />} />
              </Switch>
            }

            {
              forcePaymentUpdate &&
              <PaymentFormModal message={accountStateMessage} isAccountOwner={isAccountOwner} />
            }

            {
              !forcePaymentUpdate &&
              <SessionExclusion />
            }

            <UserVerificationModal />


            <ToastContainer
              autoClose={2000}
              draggable={false}
              transition={Slide}
              toastClassName='toast-c'
              progressClassName='toast-progress'
              />
          </div>
        </DndProvider>
      </Router>
    )
  }
}

const subscriptionOptions = {
  onError: (err) => {
    const message = err?.errors?.[0]?.message
    platformFunctions.logAnalyticsEvent("AuctionEdgeSubscriptionError", message)
  }
}

export default withAuctionEdgeSubscription(Layout, subscriptionOptions)
