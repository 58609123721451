import { useCallback, useEffect, useMemo, useState } from 'react'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import ValuationStore from '../../api/stores/ValuationStore'
import CommonFunctions from '../../api/constants/CommonFunctions'
import Spinner from '../common/Spinner'
import useMyLotSettings from '../myLot/util/useMyLotSettings'
import Scorecard from '../retail/Scorecard'
import AppConstants from '../../api/constants/AppConstants'

const ValuationScorecard = ({ valuation }) => {
  const {
    year,
    make,
    model,
    series: trim,
  } = valuation

  const [{ regionSettings: { zip, radius } }] = useMyLotSettings()

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({
      year,
      make,
      model,
      trim,
      zip,
      radius,
    })
  }, [year, trim, make, model, zip, radius])

  const [
    {
      isLoading,
      data,
    },
    setState,
  ] = useState({
    isLoading: ValuationStore.getRegionalModelScorecardIsLoadingWithClientID(clientID),
    data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
    isLoading: false,
  })

  const onDataChanged = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.getRegionalModelScorecardWithClientID(clientID),
    }))
  }, [clientID])

  const onIsLoadingChanged = useCallback(() => {
    setState(prev => ({ ...prev, isLoading: false }))
  }, [clientID])

  useEffect(() => {
    const loadingEv = `regional_model_scorecard_is_loading_change_${clientID}`
    const dataEv = `regional_model_scorecard_change_${clientID}`

    ValuationStore.on(dataEv, onDataChanged)
    ValuationStore.on(loadingEv, onIsLoadingChanged)

    return () => {
      ValuationStore.removeListener(dataEv, onDataChanged)
      ValuationStore.removeListener(loadingEv, onIsLoadingChanged)
    }
  }, [onDataChanged, onIsLoadingChanged, clientID])

  useEffect(() => {
    if (!data) {
      ValuationActionCreators.loadRegionalModelScorecard({ year, make, model, trim, zip, radius, clientID })
    }
  }, [zip, radius, year, make, model, trim, clientID])

  const renderBody = () => {
    if (isLoading) return <Spinner />
    return (
      <Scorecard
        zip={zip}
        radius={radius}
        year={year}
        make={make}
        model={model}
        trim={trim}
        scorecard={data}
      />
    )
  }

  return (
    <section className="card valuation-section">
      <h2>{AppConstants.providerNames['my_lot']}</h2>
      <h3>{[year, make, model, trim].join(' ')}</h3>
      {renderBody()}
    </section>
  )
}

export default ValuationScorecard

