import React from 'react'
import { Route, NavLink, Link, Switch } from 'react-router-dom'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import ValuationStore from '../api/stores/ValuationStore'
import ValuationActionCreators from '../api/actions/ValuationActionCreators'
import VehiclesHome from '../components/valuations/VehiclesHome'
import RecentValuations from '../components/valuations/RecentValuations'
import NewValuation from '../components/valuations/NewValuation'
import ValuationShow from '../components/valuations/ValuationShow'
import FolderShow from '../components/valuations/FolderShow'
import NewFolderModal from '../components/valuations/NewFolderModal'
import FoldersReorderModal from '../components/valuations/FoldersReorderModal'
import ValuationsSearch from '../components/valuations/ValuationsSearch'
import CommonFunctions from '../api/constants/CommonFunctions'
import DroppableFolder from '../components/valuations/DroppableFolder'
import AppConstants from '../api/constants/AppConstants'
import Spinner from '../components/common/Spinner'
import platformFunctions from '../platformFunctions'
import AccountNeedsAction from '../components/user/AccountNeedsAction'
import SessionStore from '../api/stores/SessionStore'
import UserActionCreators from '../api/actions/UserActionCreators'

export default class VehiclesPage extends React.Component {
  state = {
    folders: null,
    isShowingNewValuationModal: false,
    isShowingNewFolderModal: false,
    isShowingFoldersMoreDropdown: false,
    isShowingFolderReorderModal: false,
    isLoadingFolders: true
  }

  componentDidMount() {
    ValuationStore.on("folders_change", this.foldersReceived)
    ValuationStore.on("folder_created", this.reloadFolders)
    ValuationStore.on("folder_updated", this.reloadFolders)
    ValuationStore.on("valuation_folder_updated", this.reloadFolders)
    ValuationStore.on("new_valuation", this.showNewValuation)
    ValuationStore.on("new_folder", this.showNewFolder)
    ValuationActionCreators.loadFolders()
  }

  componentDidUpdate(prevProps, prevState) {
    if (window.location.href.indexOf('/vehicles/new') > -1) {
      const queryString = require('query-string')
      const parsedQueryString = queryString.parse(window.location.search)
      if (parsedQueryString.vin) {
        this.defaultVIN = parsedQueryString.vin
        if (this.defaultVIN && this.defaultVIN.length > AppConstants.VINLength) {
          this.defaultVIN = this.defaultVIN.substring(0,17)
        }

        var additionalNewVehicleParams = null
        if (parsedQueryString.pid) {
          // Partner ID
          const pid = parsedQueryString.pid
          additionalNewVehicleParams = {}
          additionalNewVehicleParams['partner_code'] = pid
          platformFunctions.logAnalyticsEvent("Valuations", "Partner Valuation Link")

          if (parsedQueryString.pvid) {
            const pvid = parsedQueryString.pvid
            // FIXME: change server param to partner_vehicle_id
            additionalNewVehicleParams['partner_vehicle_id'] = pvid
            platformFunctions.logAnalyticsEvent("Valuations", "Partner Vehicle ID")
          }
        }

        this.additionalNewVehicleParams = additionalNewVehicleParams
      }
      if (parsedQueryString.mileage) {
        this.defaultMileage = parsedQueryString.mileage
      }

      if (parsedQueryString.condition) {
        this.defaultOverallCondition = parsedQueryString.condition
      }

      if (parsedQueryString.zip) {
        this.defaultZipCode = parsedQueryString.zip
      }

      this.props.history.replace('/vehicles')
      this.setState({
        isShowingNewValuationModal: true
      })
    } else {
      this.defaultVIN = null
      this.defaultMileage = null
      this.additionalNewVehicleParams = null
    }

  }

  componentWillUnmount() {
    ValuationStore.removeListener("folders_change", this.foldersReceived)
    ValuationStore.removeListener("folder_created", this.reloadFolders)
    ValuationStore.removeListener("folder_updated", this.reloadFolders)
    ValuationStore.removeListener("valuation_folder_updated", this.reloadFolders)
    ValuationStore.removeListener("new_valuation", this.showNewValuation)
    ValuationStore.removeListener("new_folder", this.showNewFolder)
  }

  showNewValuation = () => {
    this.setState({
      isShowingNewValuationModal: true
    })
  }

  showNewFolder = () => {
    this.setState({
      isShowingNewFolderModal: true
    })
  }

  foldersReceived = () => {
    this.setState({
      folders: ValuationStore.folders,
      isLoadingFolders: false
    })
  }

  reloadFolders = () => {
    ValuationActionCreators.loadFolders()
  }

  onFolderDrop = (e, folder) => {
    const droppedValuationUUID = e.dataTransfer.getData('vehicleUUID')
    const targetFolderID = folder.id

    if (droppedValuationUUID && targetFolderID) {
      UserActionCreators.createMetric(AppConstants.metricNames['vehicle_assign_folder'])
      ValuationActionCreators.updateValuation(droppedValuationUUID, {vehicle_folder_id: targetFolderID}, this.clientID)
    }
  }

  valuationCreated = (newValuation) => {
    this.setState({
      isShowingNewValuationModal: false
    })
    // Put the new valuation in the store with a special key for retrieval
    // after the valuation.js component loads
    if (newValuation?.uuid) {
      ValuationStore.valuations.addToCollection(`new-valuation-${newValuation.uuid}`, newValuation)
      this.props.history.push(CommonFunctions.valuationURL(newValuation))
    }
  }

  quickVINEntered = (vin) => {
    this.defaultVIN = vin
    this.setState({
      isShowingNewValuationModal: true
    })
  }

  render() {
    const { folders, isShowingNewValuationModal, isShowingNewFolderModal, isShowingFoldersMoreDropdown, isShowingFolderReorderModal, isLoadingFolders } = this.state
    const { accountStateMessage } = this.props
    const hasFolders = folders && folders.length > 0
    const accountStatusRestricted = this.props.accountStatusRestricted === true
    const accountNeedsActionComponent = accountStatusRestricted ? <AccountNeedsAction showCompact className='account-action-page-header' /> : null

    return (
      <div style={{height: '100%', display: this.props.navMatch ? 'inherit' : 'none'}}>
        {
          accountStateMessage &&
          <div className="body-with-nav account-warnings"><div className="icon">!</div><div className="message">{accountStateMessage}</div></div>
        }
        <nav className="secondary-nav">
          <div className="secondary-nav-links">
            <section>
              <NavLink to="/vehicles" exact activeClassName="active">Home</NavLink>
              <NavLink to="/vehicles/recent" exact activeClassName="active">All Vehicles</NavLink>
              <NavLink to="/vehicles/search" exact activeClassName="active">Search Vehicles</NavLink>

              {
                !accountStatusRestricted &&
                <Link to="/vehicles/new" style={{display: 'flex', alignItems: 'center'}}><img src="/images/plus-icon.png" alt="Add" style={{width: '14px', height: '14px', marginRight: '6px', opacity: '0.2'}} /> Add a Vehicle</Link>
              }
            </section>

            <section>
              <h3 className="with-control">
                <div>Folders</div>
                {
                  hasFolders && !isLoadingFolders && !accountStatusRestricted &&
                  <Dropdown isOpen={isShowingFoldersMoreDropdown} toggle={() => this.setState({ isShowingFoldersMoreDropdown: !isShowingFoldersMoreDropdown })} tag="div">
                    <DropdownToggle className="more-button" tag="div">
                      <img src="/images/more-button.png" alt="Folder options" title="Folder options" />
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => this.setState({ isShowingFolderReorderModal: true})}>Change Folder Order</DropdownItem>
                      <DropdownItem onClick={() => ValuationStore.folderNew()}>Add a Folder</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                }
                {
                  isLoadingFolders &&
                  <div style={{display: 'flex', alignItems: 'flex-end', width: '34px', opacity: 0.4, marginRight: '10px'}}>
                    <Spinner className="spinner-secondary-nav" />
                  </div>
                }


              </h3>
              {
                hasFolders &&
                folders.map(folder => {
                  let folderURL = CommonFunctions.folderURL(folder)
                  return (
                    <DroppableFolder className='split' key={`home-folder-${folder.id}`} folder={folder} enabled={accountStatusRestricted !== true}>
                      <NavLink to={folderURL} exact activeClassName="active" key={`folder-${folder.id}`} onDrop={(e) => this.onFolderDrop(e, folder)} >
                        <div>{folder.name}</div>
                        <div>{folder.vehicles_count}</div>
                      </NavLink>
                    </DroppableFolder>
                  )
                })
              }
              {
                !hasFolders && !isLoadingFolders &&
                <div style={{marginLeft: '30px'}}>No folders</div>
              }
              {
                !accountStatusRestricted &&
                <a href="#new-folder" onClick={(e) => {e.preventDefault(); ValuationStore.folderNew()}} style={{marginTop: '10px', display: 'flex', alignItems: 'center'}}><img src="/images/plus-icon.png" alt="Add" style={{width: '14px', height: '14px', marginRight: '6px', opacity: '0.2'}} /> Add a Folder</a>
              }
            </section>

          </div>
          {
            !accountStatusRestricted &&
            <div className="secondary-nav-footer">
              <Link to="/vehicles/new" className='new-valuation-button'>
              Add a Vehicle
              <div className='new-valuation-round-button'>
                <img src="/images/add-plus-icon.png" alt="Add Vehicle" style={{width: '20px', height: '20px', margin: '0 auto'}} />
              </div>
              </Link>
            </div>
          }
        </nav>

        <div className="page-body-c body-with-nav" style={{height: '100%'}}>
          <Switch>
            <Route
              exact
              path='/vehicles'
              children={({ match }) => (
                <div>
                  { accountNeedsActionComponent }
                  <VehiclesHome navMatch={match} onQuickVINEntered={this.quickVINEntered} accountStatusRestricted={accountStatusRestricted} />
                </div>
              )} />
            <Route
              exact
              path='/vehicles/new'
              children={({ match }) => (
                <VehiclesHome navMatch={match} onQuickVINEntered={this.quickVINEntered} />
              )} />

            <Route
              exact
              path='/vehicles/recent'
              children={({ match }) => (
                <div>
                  { accountNeedsActionComponent }
                  <RecentValuations navMatch={match} onQuickVINEntered={this.quickVINEntered} accountStatusRestricted={accountStatusRestricted} />
                </div>
              )} />

            <Route
              exact
              path='/vehicles/folder/:id'
              children={({ match }) => (
                <div>
                  { accountNeedsActionComponent }
                  <FolderShow navMatch={match} history={this.props.history} accountStatusRestricted={accountStatusRestricted} />
                </div>
              )} />

            <Route
              exact
              path='/vehicles/search'
              children={({ match }) => (
                <div>
                  { accountNeedsActionComponent }
                  <ValuationsSearch navMatch={match} accountStatusRestricted={accountStatusRestricted} />
                </div>
              )} />
            <Route
              exact
              path='/vehicles/show/:id'
              children={({ match }) => (
                <ValuationShow navMatch={match} />
              )} />
          </Switch>
        </div>



        {
          isShowingNewValuationModal &&
          <NewValuation
            onDone={this.valuationCreated}
            onCancel={() => this.setState({ isShowingNewValuationModal: false })}
            defaultVIN={this.defaultVIN}
            defaultMileage={this.defaultMileage}
            defaultOverallCondition={this.defaultOverallCondition}
            defaultZipCode={this.defaultZipCode}
            additionalNewVehicleParams={this.additionalNewVehicleParams}
            history={this.props.history}
            useModal />
        }

        {
          isShowingNewFolderModal &&
          <NewFolderModal onCancel={() => this.setState({ isShowingNewFolderModal: false })}/>
        }

        {
          isShowingFolderReorderModal &&
          <FoldersReorderModal onCancel={() => this.setState({ isShowingFolderReorderModal: false })} />
        }



      </div>
    )
  }
}
