import pluralize from 'pluralize'
import React from 'react'
import { toast } from 'react-toastify'
import Formatters from '../../api/constants/Formatters'
import ValuationFunctions from '../../api/constants/ValuationFunctions'
import ConnectivityStore from '../../api/stores/ConnectivityStore'
import LLMSamplesMap from './LLMSamplesMap'
import ExternalLinkIcon from '../common/icons/ExternalLinkIcon'
import CalendarIcon from '../common/icons/CalendarIcon'
import ClipboardIcon from '../common/icons/ClipboardIcon'
import moment from 'moment'
import AppConstants from '../../api/constants/AppConstants'
import CopyToClipboard from 'react-copy-to-clipboard'

export default class LLMSampleDetail extends React.Component {

  render() {
    const { sampleDetail, selectedDetailId, selectedDetailDistance, additionalSamples } = this.props

    const exteriorColor = ValuationFunctions.normalizedColor(sampleDetail?.exterior_color)
    const interiorColor = ValuationFunctions.normalizedColor(sampleDetail?.interior_color)

    var mapSamples = additionalSamples
    if (!mapSamples?.length && sampleDetail.coordinates) {
      mapSamples = [sampleDetail]
    }

    var formattedPriceChanges = null
    if (sampleDetail?.price_changes?.length > 1) {
      formattedPriceChanges = sampleDetail.price_changes.map((priceChange, index) => {
        let newChange = {...priceChange}
        if (index > 0) {
          newChange['changeAmount'] = priceChange.price - sampleDetail.price_changes[index-1].price
        } else {
          newChange['changeAmount'] = null
        }
        return newChange
      })
    }

    const showMap = Boolean(mapSamples && !global.isExtensionMode && !global.isIFrameMode)
    const lastSeenDaysAgo = moment().diff(moment(sampleDetail.last_seen), 'days')

    return (
      <div>
        <img src="/images/provider-live_local_market.png" alt={AppConstants.providerNames.universe} style={{height: '36px', width: 'auto', marginBottom: '15px'}} />
        <div style={{width: '100%', height: '300px', display: 'flex', flexDirection: 'row', marginBottom: '20px'}}>
          {
            sampleDetail.primary_photo_url &&
            <div style={{width: showMap ? '400px' : '100%', height: '300px', flexGrow: 0, flexShrink: 0, marginRight: showMap ? '10px' : 0, borderRadius: '10px', overflow: 'hidden'}}>
              <div style={{backgroundImage: `url("${ConnectivityStore.apolloApiRoot}${sampleDetail.primary_photo_url}")`, backgroundSize: 'cover', width: '100%', height: '100%'}} />

            </div>
          }
          {
            showMap &&
            <LLMSamplesMap samples={mapSamples} defaultSelectedId={selectedDetailId} onClick={(sampleID) => {
              if (this.props.showSampleDetail) {
                this.props.showSampleDetail(sampleID)
              }
            }} />
          }
        </div>
        <div style={{borderBottom: '1px solid #ddd', paddingBottom: '5px', marginBottom: '5px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start'}}>
          <div>
            {
              sampleDetail.dealer_name &&
              <h2 style={{marginTop: 0}}>{sampleDetail.dealer_name}</h2>
            }
            {
              sampleDetail.dealer_city && sampleDetail.dealer_state &&
              <div className='secondary-text'>{sampleDetail.dealer_city}, {sampleDetail.dealer_state} {selectedDetailDistance ? `- ${pluralize('mile', selectedDetailDistance, true)} away` : ''}</div>
            }
            {
              sampleDetail.listing_url &&
              <div>
                <a href={sampleDetail.listing_url} target='_blank'>View listing on dealer website <ExternalLinkIcon /></a>
              </div>
            }
          </div>
          {
            lastSeenDaysAgo > 5 &&
            <div className='secondary-text'><CalendarIcon style={{marginTop: '-2px'}} /> Last seen by {AppConstants.providerNames.universe}: <strong>{lastSeenDaysAgo === 0 ? 'today' : `${pluralize('day', lastSeenDaysAgo, true)} ago`}</strong></div>
          }

          {
            lastSeenDaysAgo <= 5 &&
            <div className='secondary-text'><CalendarIcon style={{marginTop: '-2px'}} /> {AppConstants.providerNames.universe}: <strong>On the lot</strong></div>
          }
        </div>


        <div>
          <div className='llm-sample-detail-body'>
            <div style={{flexGrow: 1, flexShrink: 1}}>
              {
                this.props.vin && this.props.vin === sampleDetail.vin &&
                <div style={{backgroundColor: '#e95656', color: '#fff', padding: '2px 6px', borderRadius: '6px'}}>
                  This is the vehicle you are viewing pricing data for
                </div>
              }
              <div style={{marginBottom: 0, color: '#aaa', fontSize: '1.2rem', fontWeight: 'bold'}}>{sampleDetail.year}</div>
              <h2 style={{marginTop: '-8px'}}>{sampleDetail.make} {sampleDetail.model}</h2>
              {
                Boolean(sampleDetail.full_trim) &&
                <div>{sampleDetail.full_trim}</div>
              }

              {
                sampleDetail.mileage &&
                <div style={{fontSize: '1.25rem', fontWeight: 'bold'}}>{Formatters.formatThousands(sampleDetail.mileage)} {pluralize('mile', sampleDetail.mileage, false)}</div>
              }

              {
                sampleDetail.vin &&


                  <CopyToClipboard
                    text={sampleDetail.vin}
                    onCopy={() => {
                      toast.success("VIN Copied to Clipboard")
                    }}
                    >
                    <div style={{marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        {sampleDetail.vin}
                      <div className="label" style={{marginLeft: '8px', color: '#2f323e', cursor: 'pointer'}}>
                      <ClipboardIcon style={{marginTop: '-2px'}} />
                      </div>
                    </div>
                  </CopyToClipboard>


              }

              {
                Boolean(sampleDetail.engine || sampleDetail.drive_type || sampleDetail.fuel_type || sampleDetail.transmission) &&
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: '10px', gap: '10px'}}>
                  {
                    sampleDetail.engine &&
                    <div className='secondary-text'>{sampleDetail.engine}</div>
                  }
                  {
                    sampleDetail.drive_type &&
                    <div className='secondary-text'>{sampleDetail.drive_type}</div>
                  }
                  {
                    sampleDetail.fuel_type &&
                    <div className='secondary-text'>{sampleDetail.fuel_type}</div>
                  }
                  {
                    sampleDetail.transmission &&
                    <div className='secondary-text'>{sampleDetail.transmission}</div>
                  }
                </div>
              }

              {
                Boolean(exteriorColor || sampleDetail.interior_color) &&
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                  {
                    exteriorColor && exteriorColor.colorName &&
                    <div style={{display: 'flex', flexDirection: 'row', marginRight: '20px', alignItems: 'center'}}>
                      <div style={{backgroundColor: exteriorColor.colorClass || '', width: '20px', height: '20px', borderRadius: '15px', border: '1px solid #ddd', marginRight: '4px'}} />
                      {exteriorColor.colorName} exterior
                    </div>
                  }
                  {
                    interiorColor && interiorColor.colorName &&
                    <div style={{display: 'flex', flexDirection: 'row', marginRight: '20px', alignItems: 'center'}}>
                      <div style={{backgroundColor: interiorColor.colorClass || '', width: '20px', height: '20px', borderRadius: '15px', border: '1px solid #ddd', marginRight: '4px'}} />
                      {interiorColor.colorName} interior
                    </div>
                  }
                </div>
              }
            </div>


            {
              Boolean(sampleDetail) &&
              <div className='llm-sample-detail-prices'>
                {
                  Boolean(sampleDetail.price) &&
                  <div style={{fontSize: '1.25rem', fontWeight: 'bold'}}>Asking price {Formatters.formatCurrency(sampleDetail.price)}</div>
                }
                {
                  Boolean(sampleDetail.days_on_lot) &&
                  <div style={{marginBottom: '10px'}}>{pluralize('day', sampleDetail.days_on_lot, true)} on lot</div>
                }
                {
                  sampleDetail.num_price_changes !== null &&
                  <div style={{borderBottom: formattedPriceChanges && formattedPriceChanges.length > 0 ? '1px solid #ddd' : 'none', fontWeight: 'bold'}}>{pluralize('price change', sampleDetail.num_price_changes, true)}</div>
                }

                {
                  Boolean(formattedPriceChanges && formattedPriceChanges.length > 0) &&
                  formattedPriceChanges.map((priceChange, index) => {
                    return (
                      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #eee', padding: '4px 0'}} key={`llm-pc-${index}`}>
                        <div style={{width: '100px', flexGrow: 0, flexShrink: 0}}>{moment(priceChange.date).format('M/D/YY')}</div>
                        {
                          priceChange.changeAmount &&
                          <div style={{width: '80px', flexGrow: 0, flexShrink: 0, color: priceChange.changeAmount < 0 ? 'red' : 'green'}}>{priceChange.changeAmount > 0 ? '+' : ''}{Formatters.formatCurrency(priceChange.changeAmount)}</div>
                        }
                        <div style={{flexGrow: 1, flexShrink: 1, textAlign: 'right'}}>{Formatters.formatCurrency(priceChange.price)}</div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>

        </div>
      </div>
    )
  }
}
