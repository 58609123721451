import { useMemo, useState } from 'react'
import pluralize from 'pluralize'
import ListingsList from './ListingsList'
import TrendUpIcon from '../common/icons/TrendUpIcon'
import TrendDownIcon from '../common/icons/TrendDownIcon'

const scorecardMetricTypes = [
  'inventory',
  'days_on_lot',
  'market_days_supply',
  'sold_per_day',
  'price_change_portion',
  'retail_trend',
  'wholesale_inventory',
]

const ToggleMdsButton = ({ isShowingMds, setIsShowingMds }) => {
  return (
    <button className='link indicator' onClick={() => setIsShowingMds(!isShowingMds)} style={{maxHeight: '10px', marginTop: '-10px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" style={{}}>
        <path fillRule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5m14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5"/>
      </svg>
    </button>
  )
}

const Scorecard = ({ zip, radius, year, make, model, trim, scorecard }) => {
  const [isShowingMds, setIsShowingMds] = useState(false)
  const regionalListingsParams = useMemo(() => {
    return {
      zip,
      radius,
      year,
      make,
      model,
      trim,
    }
  }, [zip, radius, year, make, model, trim])

  return (
    <div className='split-row split-row-left' style={{columnGap: '10px', marginTop: '20px', alignItems: 'stretch'}}>
      <div className='my-lot-regional-metrics'>
        {
          // FIXME: Handle no scorecard data (i.e. no make/model stats in query)
          !scorecard ?
          <div>No data available</div> :
          scorecardMetricTypes.map(key => {
            const data = scorecard[key]
            // FIXME Handle missing data
            if (data == null) return null
            let levelBound
            let levelIndicator
            let trendText
            let trendIcon
            let title
            let currentValueText
            let levelName
            let isShown = true
            let toggleButton

            // FIXME: Handle insufficient data
            const insufficientLevelDataText = 'Insufficient data'

            switch (key) {
              case 'inventory':
                title = 'Local Retail Inventory'
                currentValueText = [data.current, 'for sale'].join(' ')
                levelName = 'supply'
                break
              case 'days_on_lot':
                title = 'Days on Lot'
                isShown = !isShowingMds
                currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(1), pluralize('day', data.current, false)].join(' ')
                levelName = data.level == null ? insufficientLevelDataText : 'turn'
                toggleButton = <ToggleMdsButton isShowingMds={isShowingMds} setIsShowingMds={setIsShowingMds} />
                break
              case 'sold_per_day':
                title = 'Daily Sales Rate'
                currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(2), 'per day'].join(' ')
                levelName = data.level == null ? insufficientLevelDataText : 'rate'
                break
              case 'price_change_portion':
                title = 'Price Change Percent'
                currentValueText = data.current == null ? 'N/A' : [(data.current * 100).toFixed(2), '%'].join('')
                levelName = data.level == null ? insufficientLevelDataText : 'percent'
                break
              case 'market_days_supply':
                title = 'Market Days Supply'
                isShown = isShowingMds
                currentValueText = data.current == null ? 'N/A' : [data.current.toFixed(1), pluralize('day', data.current, false)].join(' ')
                levelName = data.level == null ? insufficientLevelDataText : 'number'
                toggleButton = <ToggleMdsButton isShowingMds={isShowingMds} setIsShowingMds={setIsShowingMds} />
                break
              case 'retail_trend':
                title = 'Retail Trend'
                currentValueText = '',
                levelName = ''
                break
              case 'wholesale_inventory':
                title = 'Local Wholesale Inventory'
                currentValueText = [data.current, 'listed recently'].join(' ')
                levelName = data.level == null ? insufficientLevelDataText : 'supply'
                break
              default:
                throw new Error(`Unknown scorecard metric type: ${key}`)
            }

            switch (data?.level) {
              case -1:
                levelBound = 'Low'
                levelIndicator = '▼'
                break
              case 0:
                levelBound = 'Normal'
                levelIndicator = '-'
                break
              case 1:
                levelBound = 'High'
                levelIndicator = '▲'
                break
            }

            switch (data?.trend) {
              case -1:
                trendText = 'Trending down'
                trendIcon = <TrendDownIcon />
                break
              case 0:
                trendText = 'Trending flat'
                trendIcon = '-'
                break
              case 1:
                trendText = 'Trending up'
                trendIcon = <TrendUpIcon />
                break
            }

            if (!isShown) return null

            return (
              <section key={key}>
                <div className='split-row'>
                  <div className='section-label'>{title}</div>
                  {toggleButton}
                </div>
                <h2>{currentValueText}</h2>
                <div className='split-row'>
                  <div><span className='indicator'>{levelIndicator}</span> {levelBound} {levelName}</div>
                  <div>{trendText} <span className='indicator'>{trendIcon}</span></div>
                </div>
              </section>
            )
          })
        }
      </div>

      <div style={{flexGrow: 1, flexShrink: 1, border: '1px solid #ddd', borderRadius: '6px', position: 'relative'}}>
        <div style={{height: '100%', width: '100%', maxHeight: '100%', overflowY: 'scroll', position: 'absolute'}}>
          {/* FIXME: what is order for listings? is it selectable here? */}
          <ListingsList regionalListingsParams={regionalListingsParams} />
        </div>
      </div>
    </div>
  )
}

export default Scorecard
