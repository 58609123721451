import React from 'react'

export default class EditIconCircle extends React.PureComponent {
  render() {
    const width = this.props.width || '1em'
    const height = this.props.height || '1em'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 166 166" fill={color} style={this.props.style}>
        <path fillRule="evenodd" fill="rgb(0, 0, 0)" d="M114.842,67.146 L92.950,46.166 L104.808,34.308 L126.701,55.288 L114.842,67.146 ZM74.707,107.281 L52.815,86.301 L89.301,49.815 L111.194,70.795 L74.707,107.281 ZM43.693,122.788 C37.766,125.481 36.203,122.473 38.220,117.315 C40.237,112.158 50.990,89.038 50.990,89.038 L71.970,109.106 C71.970,109.106 49.620,120.096 43.693,122.788 Z"/>
        <path fillRule="evenodd" stroke="rgb(0, 0, 0)" strokeWidth="8px" strokeLinecap="butt" strokeLinejoin="miter" fill="none" d="M81.000,7.1000 C121.317,7.1000 154.000,40.683 154.000,81.000 C154.000,121.317 121.317,154.000 81.000,154.000 C40.683,154.000 7.1000,121.317 7.1000,81.000 C7.1000,40.683 40.683,7.1000 81.000,7.1000 Z"/>
      </svg>
    )
  }
}