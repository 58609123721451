import { useEffect, useState, useCallback } from 'react'
import ValuationStore from '../../../api/stores/ValuationStore'
import ValuationActionCreators from '../../../api/actions/ValuationActionCreators'

const useMyLotReport = () => {
  const [
    {
      isLoading,
      data,
    },
    setState
  ] = useState({
    data: ValuationStore.myLotReport,
    isLoading: ValuationStore.isLoadingMyLotReport,
  })

  const onReportChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      data: ValuationStore.myLotReport,
    }))
  }, [])

  const onIsLoadingChange = useCallback(() => {
    setState(prev => ({
      ...prev,
      isLoading: ValuationStore.isLoadingMyLotReport,
    }))
  }, [])

  const load = useCallback(() => {
    ValuationActionCreators.loadMyLotReport()
  }, [])

  useEffect(() => {
    const ev = 'my_lot_report_change'
    const loadingEv = 'is_loading_my_lot_report_change'
    ValuationStore.on(ev, onReportChange)
    ValuationStore.on(loadingEv, onIsLoadingChange)

    return () => {
      ValuationStore.removeListener(ev, onReportChange)
      ValuationStore.removeListener(loadingEv, onIsLoadingChange)
    }
  }, [onReportChange, onIsLoadingChange])

  return {
    isLoading: (isLoading && data) || !data,
    data,
    load,
  }
}

export default useMyLotReport
