import { useCallback, useRef } from 'react'
import pluralize from 'pluralize'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import VehiclesList from './VehiclesList'
import LotHistoryVehicleRow from './LotHistoryVehicleRow'
import Formatters from '../../api/constants/Formatters'

const renderRow = props => (
  <LotHistoryVehicleRow
    key={props.uuid}
    listing={props}
  />
)

const VehiclesListModal = ({ onClose, year, make, model, trim, modelStats }) => {
  const ref = useRef(null)

  const getScrollParent = useCallback(() => {
    return ref.current
  }, [])

  // FIXME: For pagination, moved scroll to parent, so footer close button is not visible

  return (
    <Modal isOpen innerRef={ref} centered toggle={onClose} backdropClassName='modal-bg' contentClassName='modal-c' style={{ minWidth: 1200 }}>
      <ModalHeader className='modal-header'>
        Lot History
      </ModalHeader>
      <ModalBody>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <div>
            {
              modelStats.num_sold > 1 &&
              <div>
                {pluralize('average day', modelStats.avg_days_on_lot_sold.toFixed(1), true)} on lot
              </div>
            }

            {
              modelStats.avg_profit_sold != null &&
              <div>
                {Formatters.formatCurrency(modelStats.avg_profit_sold)} average profit
              </div>
            }

            {
              modelStats.avg_price_change_portion_sold != null &&
              <div>
                {Math.round(modelStats.avg_price_change_portion_sold * 100)}% average price change
              </div>
            }

          </div>
          <div>
            <VehiclesList
              getScrollParent={getScrollParent}
              renderRow={renderRow}
              status='sold'
              order='sold_at DESC'
              year={year}
              make={make}
              model={model}
              trim={trim}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  )
}

export default VehiclesListModal
