import NumberInputField from './NumberInputField'

// NOTE: 'unmask' prop defaults to false for backwards compatibility.
// Ideally, we should change the default to true and update all usages.
const CurrencyInputField = ({ acceptCents, style, unmask = false, ...rest }) => {
  return (
    <div style={{flexGrow: 1, flexShink: 1, marginTop: '2px', position: 'relative'}}>
      <NumberInputField
        {...rest}
        acceptDecimal={acceptCents}
        unmask={unmask}
        style={{ ...style, paddingLeft: 20 }}
      />
      <div style={{position: 'absolute', top: 0, left: 0, height: '100%', display: 'flex', alignItems: 'center'}}>
        <span className='currency-input-label' style={{fontSize: '1.2rem', margin: '0 5px', marginTop: '-1px', color: '#aaa'}}>
          $
        </span>
      </div>
    </div>
  )
}

export default CurrencyInputField
