import { useCallback } from 'react'
import AppConstants from '../../api/constants/AppConstants'
import VehicleStateIcon from '../../pages/myLot/VehicleStateIcon'

const LotStateButton = ({ onClick: propsOnClick, lotVehicleState, className }) => {
  const onClick = useCallback(ev => {
    ev.preventDefault()
    if (propsOnClick) {
      propsOnClick()
    }
  }, [propsOnClick])

  return (
    <button className={`lot-vehicle-state-button ${className}`} onClick={onClick}>
      <VehicleStateIcon vehicleState={lotVehicleState} />
      <div>{AppConstants.myLotStateNames[lotVehicleState] ? AppConstants.myLotStateNames[lotVehicleState] : 'Unknown status'}</div>
    </button>
  )
}

export default LotStateButton
