export default {

  colorForDaysOnLot: function(dol, defaultColor = 'inherit') {
    if (dol > 30) {
      return 'red'
    } else {
      return defaultColor
    }
  },

  myLotVehicleURL: function(valuation) {
    return `/my-lot/vehicles/show/${valuation.uuid}`
  },

}