import AccountBug from '../../components/user/AccountBug'
import CalculatorBug from '../../components/common/CalculatorBug'
import useMyLotSettings from '../../components/myLot/util/useMyLotSettings'


// TODO: Do we want something like this to handle bad apollo data?
  // const badDataMessage = useMemo(() => {
  //   if (!dealer) return null
  //   if (dealer.is_deleted) return 'Dealer is deleted'
  //   if (!dealer.is_scrapeable) return 'Dealer is unscrapeable'
  //   if (!dealer.is_supported) return 'Dealer is unsupported'
  //   if (!dealer.is_trend_history_clean) return 'Dealer has incomplete trend history'
  //   if (!dealer.is_history_clean) return 'Dealer has incomplete history'
  //   if (!dealer.is_history_current) return 'Dealer has stale history'
  // }, [dealer])


const Header = ({ title, children, showDealerSiteInfo = false }) => {
  const [
    {
      lotInfo: {
        lotCity,
        lotState,
        lotUrl,
      },
    },
  ] = useMyLotSettings()

  return (
    <>
      <div className='my-lot-dashboard-header'>
        <div className="content-header">
          <div>
            <h1>{title}</h1>
            {
              showDealerSiteInfo &&
              <div className='split-row split-row-left' style={{columnGap: '20px'}}>
                {
                  lotCity && lotState &&
                  <div className='secondary-text'>{lotCity}, {lotState}</div>
                }

                {
                  lotUrl &&
                  <div>
                    <a href={lotUrl} target='_blank' rel='noreferrer' style={{color: '#aaa'}}>{lotUrl}</a>
                  </div>
                }
              </div>
            }
          </div>
          <div className="global-tools">
            <AccountBug />
            <CalculatorBug calculatorIconColor='#aaa' />
          </div>
        </div>
        {children}
      </div>
    </>
  )
}

export default Header
