import { useCallback, useState } from 'react'
import Select from 'react-select'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import ZipCodeEntryField from '../../components/common/ZipCodeEntryField'
import { reactSelectStyles } from './constants'

const RegionOptionsModal = ({ title, radiusOptions, radius, zip, onClose, onSave }) => {
  const [selectedRadius, setSelectedRadius] = useState(() => radiusOptions.find(item => item.value === radius))
  const [selectedZip, setSelectedZip] = useState(zip)
  const [selectedZipIsValid, setSelectedZipIsValid] = useState(false)

  const onSaveClick = useCallback(() => {
    onSave({
      radius: selectedRadius.value,
      zip: selectedZip,
      initialRadius: radius,
      initialZip: zip,
    })
  }, [onSave, selectedRadius, selectedZip, zip, radius])

  const onZipChanged = useCallback((value, isValid) => {
    if (isValid) {
      setSelectedZip(value)
      setSelectedZipIsValid(true)
    } else {
      setSelectedZipIsValid(false)
    }
  }, [])

  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'  style={{minWidth: '600px'}}>
      <form onSubmit={(e) => {
        e.preventDefault()
        if (selectedZipIsValid) {
          onSaveClick()
        } }}>
        <ModalHeader className='modal-header'>
          {title}
        </ModalHeader>
        <ModalBody>
            <div className="split-row split-row-left">
              <div style={{marginRight: '10px'}}>Includes dealers within</div>
              <div style={{ width: 200 }}>
                <Select
                  styles={reactSelectStyles}
                  name='radius'
                  className='react-select'
                  isSearchable={false}
                  onChange={setSelectedRadius}
                  value={selectedRadius}
                  options={radiusOptions}
                />
              </div>
            </div>

            <div className="split-row split-row-left" style={{marginTop: '20px'}}>
              <div style={{marginRight: '10px'}}>Zip code</div>
              <div style={{ width: 250 }}>
                <ZipCodeEntryField
                  style={{ marginTop: 0, padding: '2px 8px', height: 38, borderRadius: 4 }}
                  defaultZipCode={zip}
                  onZipCodeChange={onZipChanged}
                />
              </div>
            </div>
        </ModalBody>
        <ModalFooter>
          <Button color='secondary' onClick={onClose}>Cancel</Button>
          <Button type='submit' color='primary' onClick={onSaveClick} disabled={!selectedZipIsValid}>Save Options</Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default RegionOptionsModal
