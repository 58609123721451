import DownArrowShortIcon from "../../components/common/icons/DownArrowShortIcon"
import FlatbedTruckIcon from "../../components/common/icons/FlatbedTruckIcon"
import GavelIcon from "../../components/common/icons/GavelIcon"
import KeyIcon from "../../components/common/icons/KeyIcon"
import PriceTagIcon from "../../components/common/icons/PriceTagIcon"
import StarsIcon from "../../components/common/icons/StarsIcon"
import StopSignIcon from "../../components/common/icons/StopSignIcon"
import ToolsIcon from "../../components/common/icons/ToolsIcon"

const VehicleStateIcon = ({ className, vehicleState }) => {
  switch (vehicleState) {
    case 'acquired':
      return <DownArrowShortIcon className={className} color='currentColor' />
    case 'in_transit':
      return <FlatbedTruckIcon className={className} />
    case 'in_recon':
      return <StarsIcon className={className} />
    case 'on_lot':
      return <PriceTagIcon className={className} />
    case 'at_service':
      return <ToolsIcon className='icon' color="currentColor" />
    case 'stop_sale':
      return <StopSignIcon className={className} />
    case 'at_auction':
      return <GavelIcon className={className} />
    case 'sold':
      return <KeyIcon className={className} />
    default:

      return null
  }
}

export default VehicleStateIcon
