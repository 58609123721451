import React, { useEffect, useState, useCallback } from 'react'
import SessionStore from '../../api/stores/SessionStore'
import { Route, NavLink, Switch } from 'react-router-dom'
import HomeIcon from '../../components/common/icons/HomeIcon'
import GearIcon from '../../components/common/icons/GearIcon'
import Dashboard from './Dashboard'
import Analysis from './Analysis'
import Competitors from './Competitors'
import VehiclesByStatus from './VehiclesByStatus'
import Settings from './Settings'
import AppConstants from '../../api/constants/AppConstants'
import ValuationShow from '../../components/valuations/ValuationShow'
import VehicleStateIcon from './VehicleStateIcon'
import useMyLotVehiclesDetails from './util/useMyLotVehiclesDetails'
import useMyLotReport from './util/useMyLotReport'
import CompetitorsIcon from '../../components/common/icons/CompetitorsIcon'
import GraphIcon from '../../components/common/icons/GraphIcon'
import PlusIcon from '../../components/common/icons/PlusIcon'
import NewValuation from '../../components/valuations/NewValuation'
import ValuationStore from '../../api/stores/ValuationStore'
import MyLotFunctions from '../../api/constants/MyLotFunctions'
import AlarmIcon from '../../components/common/icons/AlarmIcon'
import usePolling from '../../api/hooks/usePolling'
import { alertVehiclesPollingInterval } from './util/constants'
import useMyLotSettings from '../../components/myLot/util/useMyLotSettings'
import FullOnboarding from './FullOnboarding'
import Upsell from './Upsell'

const MyLot = ({history}) => {
  const [isShowingNewValuationModal, setIsShowingNewValuationModal] = useState(false)
  const [{ alertSettings, onboarding }] = useMyLotSettings()

  const {
    getCountForState,
    load: loadMyLotVehiclesDetails,
  } = useMyLotVehiclesDetails()

  const {
    load: loadMyLotReport,
  } = useMyLotReport()

  const valuationCreated = useCallback((newValuation) => {
    setIsShowingNewValuationModal(false)
    console.log('valuationCreated', newValuation)
    if (newValuation?.uuid) {
      ValuationStore.valuations.addToCollection(`new-valuation-${newValuation.uuid}`, newValuation)
      history.push(MyLotFunctions.myLotVehicleURL(newValuation))
    }
  }, [])

  const onVehicleChanged = useCallback(() => {
    loadMyLotVehiclesDetails()
    loadMyLotReport()
  }, [loadMyLotVehiclesDetails, loadMyLotReport])

  const onVehicleSnoozeChanged = useCallback(() => {
    loadMyLotVehiclesDetails()
  }, [loadMyLotVehiclesDetails])

  useEffect(() => {
    const vehicleChangedEv = 'lot_vehicle_updated'
    const vehicleSnoozedEv = 'lot_vehicle_snooze_updated'
    ValuationStore.on(vehicleChangedEv, onVehicleChanged)
    ValuationStore.on(vehicleSnoozedEv, onVehicleSnoozeChanged)

    return () => {
      ValuationStore.removeListener(vehicleChangedEv, onVehicleChanged)
      ValuationStore.removeListener(vehicleSnoozedEv, onVehicleSnoozeChanged)
    }
  }, [onVehicleChanged, onVehicleSnoozeChanged])

  useEffect(() => {
    loadMyLotVehiclesDetails()
  }, [alertSettings])

  useEffect(() => {
    loadMyLotReport()
  }, [loadMyLotReport])

  usePolling(loadMyLotVehiclesDetails, alertVehiclesPollingInterval)

  if (!onboarding.apolloLinked && !onboarding.preferredLinked && !onboarding.lotVehiclesExist) {
    return ( <FullOnboarding /> )
  }

  return (
    <>
      <div style={{height: '100%'}}>

        <nav className="secondary-nav">
          <div className="secondary-nav-links">
            <section>
              <NavLink to="/my-lot" exact activeClassName="active">
                <HomeIcon className="icon" color="currentColor" />
                Dashboard
              </NavLink>
              <NavLink to="/my-lot/alerts" exact activeClassName="active">
                <AlarmIcon className="icon" color="currentColor" />
                Needing Attention
                <div style={{ marginLeft: 'auto' }}>
                  {getCountForState('having_alerts')}
                </div>
              </NavLink>
              <NavLink to="/my-lot/analysis" exact activeClassName="active">
                <GraphIcon className="icon" color="currentColor" />
                Lot Analysis
              </NavLink>
              <NavLink to="/my-lot/competitors" exact activeClassName="active">
                <CompetitorsIcon className="icon" color="currentColor" />
                Competitors
              </NavLink>
              <NavLink to="/my-lot/settings" exact activeClassName="active">
                <GearIcon className="icon" color="currentColor" />
                Settings
              </NavLink>
              <NavLink to="/my-lot/settingsX" exact activeClassName="active" onClick={(e) => {
                e.preventDefault()
                setIsShowingNewValuationModal(true)
              }}>
                <PlusIcon className="icon" color="currentColor" />
                Add vehicle to lot
              </NavLink>
            </section>

            <section style={{marginTop: '60px'}}>
              <div className="section-label">Vehicles by Status</div>
              {
                AppConstants.myLotStateValues.map((state, i) => (
                  <NavLink key={i} to={`/my-lot/${state}`} exact activeClassName="active">
                    <VehicleStateIcon className="my-lot-nav-icon" vehicleState={state} />
                    {AppConstants.myLotStateNames[state]}
                    <div style={{ marginLeft: 'auto' }}>
                      {getCountForState(`state_${state}`)}
                    </div>
                  </NavLink>
                ))
              }
            </section>
          </div>
        </nav>


        <div className="page-body-c my-lot-body-c body-with-nav" style={{minHeight: '100%'}}>
          <Switch>
            <Route
              exact
              path='/my-lot'
              children={() => <Dashboard currentInventoryCount={getCountForState('state_on_lot')} />}
            />
            <Route
              exact
              path='/my-lot/analysis'
              children={() => <Analysis />}
            />
            <Route
              exact
              path='/my-lot/competitors'
              children={() => <Competitors />}
            />
            <Route
              exact
              path='/my-lot/settings'
              children={() => <Settings />}
            />
            <Route
              exact
              path='/my-lot/alerts'
              children={() =>
                <VehiclesByStatus
                  title='Needing Attention'
                  havingAlerts
                  defaultOrder={'last_alerted_at DESC'}
                  count={getCountForState('having_alerts')}
                  excludedSortOptions={['sold_at']}
                />
              }
            />
            <Route
              exact
              path='/my-lot/vehicles/show/:id'
              children={({ match }) => (
                <div style={{margin: '-20px'}}>
                  <ValuationShow navMatch={match} />
                </div>
              )} />
            {
              AppConstants.myLotStateValues.map((state, i) => (
                <Route
                  key={i}
                  exact
                  path={`/my-lot/${state}`}
                  children={() =>
                    <VehiclesByStatus
                      title={AppConstants.myLotStateNames[state]}
                      status={state}
                      count={getCountForState(`state_${state}`)}
                      defaultOrder={state === 'sold' ? 'sold_at DESC' : undefined}
                      excludedSortOptions={state !== 'sold' ? ['sold_at', 'last_alerted_at'] : ['last_alerted_at']}
                    />
                  }
                />
              ))
            }
          </Switch>
        </div>
      </div>

      {
        isShowingNewValuationModal &&
        <NewValuation
          onDone={valuationCreated}
          onCancel={() => setIsShowingNewValuationModal(false)}
          history={history}
          showDrilldown={false}
          defaultLotVehicleState='on_lot'
          useModal />
      }
    </>
  )
}

const Page = ({history}) => {
  return SessionStore.hasMyLotAccess() ?
    <MyLot history={history} /> :
    <Upsell />
}

export default Page
