import React from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import SessionStore from '../../api/stores/SessionStore'
import SessionActionCreators from '../../api/actions/SessionActionCreators'
import Spinner from './Spinner'

export default class SessionExclusion extends React.Component {

  state = {
    userHasSessionControl: true,
    isReclaiming: false
  }

  componentDidMount() {
    SessionStore.on('user_session_control_changed', this.userSessionControlChanged)
  }

  componentWillUnmount() {
    SessionStore.removeListener('user_session_control_changed', this.userSessionControlChanged)
  }

  userSessionControlChanged = () => {
    this.setState({
      userHasSessionControl: SessionStore.userHasSessionControl,
      isReclaiming: false
    })
  }

  reclaimControl = () => {
    this.setState({
      isReclaiming: true
    })
    SessionActionCreators.reclaimControl()
  }

  render() {
    const { userHasSessionControl, isReclaiming } = this.state
    if (userHasSessionControl === true) {
      return null
    }

    return (
      <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c'>
        <ModalBody>
          <h2>Account In Use</h2>
          <p>This account is currently in use by another device. If you would like to continue using Carbly, you can reclaim control from the other device.</p>
          {
            isReclaiming &&
            <Spinner />
          }
        </ModalBody>
        {
          !isReclaiming &&
          <ModalFooter>
            <Button onClick={this.reclaimControl} className="highlight-button" style={{width: '100%', marginTop: '10px'}}>Reclaim Control</Button>
          </ModalFooter>
        }

      </Modal>
    )
  }
}
