import React from 'react'
import { MapContainer, TileLayer, Marker, Popup} from 'react-leaflet'
import L from 'leaflet'
import SessionStore from '../../api/stores/SessionStore'

const MAP_HEIGHT = '300px'

export default class DealersMap extends React.Component {
  constructor(props) {
    super(props)
    this.mapRef = React.createRef()
  }

  state = {
    hasDoneInitialZoom: false,
  }

  componentDidMount() {
    // Map might not be initialized when auctions are loaded, safety attempt
    setTimeout(() => {
      this.initialZoom()
    }, 200)
  }

  initialZoom = () => {
    if (!this.mapRef.current || this.state.hasDoneInitialZoom) {
      return
    }

    this.zoomToFitMarkers()
    this.setState({ hasDoneInitialZoom: true })
  }

  zoomToFitMarkers = () => {
    const { dealers } = this.props
    
    if (!this.mapRef || !this.mapRef.current) {
      return
    }

    const map = this.mapRef.current
    var displayBounds = null
    var paddingOptions = null    
    var maxZoom = dealers.length > 1 ? 9 : 7
    var minLongitude, maxLongitude, minLatitude, maxLatitude
    
    for (let dealer of dealers) {
      const coordinates = dealer.coordinates
      if (coordinates?.latitude && coordinates?.longitude) {
        minLongitude = !minLongitude || coordinates.longitude < minLongitude ? coordinates.longitude : minLongitude
        maxLongitude = !maxLongitude || coordinates.longitude > maxLongitude ? coordinates.longitude : maxLongitude
        minLatitude = !minLatitude || coordinates.latitude < minLatitude ? coordinates.latitude : minLatitude
        maxLatitude = !maxLatitude || coordinates.latitude > maxLatitude ? coordinates.latitude : maxLatitude
      }
    }

    displayBounds = [[minLatitude, minLongitude], [maxLatitude, maxLongitude]]
    paddingOptions = {paddingBottomRight: [0, 10], paddingTopLeft: [0, 10], maxZoom: maxZoom}
  
    
    if (displayBounds) {
      map.fitBounds(displayBounds, paddingOptions)
    }
  }



  render() {
    const { dealers } = this.props
    if (!dealers) {
      return null
    }
    const center = [40.1, -82.0]
    
    return (
      <div className="home-auctions-map-c" style={{height: MAP_HEIGHT}}>
        <MapContainer ref={this.mapRef} attributionControl={false} bounds={null} zoom={3.4} zoomSnap={0.25} center={center} scrollWheelZoom={true} style={{width: '100%', height: MAP_HEIGHT, borderRadius: '10px 0 0 10px', flexGrow: 1, flexShrink: 1, zIndex: 100}}>
          <TileLayer attribution='' url="https://tileserver.getcarbly.com/osm/{z}/{x}/{y}.png" />
          {
            dealers.map((dealer) => {
              if (dealer.coordinates?.latitude && dealer.coordinates?.longitude) {
                const coordinates = [dealer.coordinates.latitude, dealer.coordinates.longitude]
                return (
                  <Marker position={coordinates} icon={ secondaryMarker } key={`m-${dealer.uuid}`}>
                    <Popup ref={`pop-${dealer.uuid}`}>
                      <div className="auction-map-popup">
                        <h2>{dealer.dealer_name}</h2>
                      </div>
                    </Popup>
                  </Marker>
                )
              }
            })
          }
        </MapContainer>
      </div>
    )
  }
}

const secondaryMarker = new L.Icon({
  iconUrl: '/images/map-marker-secondary.svg',
  iconSize: [10,10],
  iconAnchor: [5, 10],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});
