import React from 'react'

export default class TrendDownIcon extends React.PureComponent {
  render() {
    const width = this.props.width || '18px'
    const height = this.props.height || '18px'
    const color = this.props.color || 'currentColor'

    return (
      <svg width={width} height={height} viewBox="0 0 16 16" fill={color} className={this.props.className} style={{...this.props.style}} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.854 3.146a.5.5 0 1 0-.708.708L9.243 9.95H6.475a.5.5 0 1 0 0 1h3.975a.5.5 0 0 0 .5-.5V6.475a.5.5 0 1 0-1 0v2.768z"/>
      </svg>
    )
  }
}