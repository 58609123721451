import { memo } from 'react'
import { ResponsiveBar } from '@nivo/bar'

const BarChart = memo(({
  data,
  valueFormat,
  valueAxisFormat,
  tooltip,
  tickValues,
  axisBottom,
  onClick,
  margin = { top: 10, right: 26, bottom: 26, left: 35 },
}) => {
  return (
    <ResponsiveBar
      data={data}
      animate={false}
      colors={['#2c6e8e']}
      margin={margin}
      axisLeft={{
        format: valueAxisFormat,
        tickSize: 0,
        tickValues,
      }}
      gridYValues={tickValues}
      valueFormat={valueFormat}
      enableLabel={false}
      tooltip={tooltip}
      axisBottom={axisBottom}
      onClick={onClick}
    />
  )
})

export default BarChart
