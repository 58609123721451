import React from 'react'

export default class GavelIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 26
    const height = this.props.width || 26
    const color = this.props.color || 'currentColor'

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 97 124" style={{...this.props.style}} className={this.props.className}>
        <path fillRule="evenodd" d="M96.025,41.070 L80.525,67.916 C79.697,69.351 77.862,69.843 76.427,69.014 L71.231,66.014 C69.796,65.186 69.304,63.351 70.133,61.916 L85.633,35.070 C86.461,33.635 88.296,33.143 89.731,33.971 L94.927,36.971 C96.362,37.800 96.853,39.635 96.025,41.070 ZM71.267,57.952 C70.438,59.387 68.603,59.879 67.169,59.050 L27.331,36.050 C25.897,35.222 25.405,33.387 26.233,31.952 L37.233,12.900 C38.062,11.465 39.897,10.973 41.331,11.802 L81.169,34.802 C82.603,35.630 83.095,37.465 82.267,38.900 L71.267,57.952 ZM23.367,34.916 C22.539,36.351 20.704,36.843 19.269,36.014 L14.073,33.014 C12.638,32.186 12.147,30.351 12.975,28.916 L28.475,2.070 C29.303,0.635 31.138,0.143 32.573,0.971 L37.769,3.971 C39.204,4.800 39.696,6.635 38.867,8.070 L23.367,34.916 ZM50.580,51.782 L15.178,119.100 C12.969,122.927 8.076,124.238 4.250,122.029 C0.424,119.819 -0.887,114.927 1.322,111.100 L41.920,46.782 C42.015,46.617 50.562,51.814 50.580,51.782 Z"/>
      </svg>
    )
  }
}