import { memo } from 'react'
import Formatters from '../../api/constants/Formatters'
import pluralize from 'pluralize'
import { Scatter } from 'react-chartjs-2'
import { blendPlugin, makeScatterTooltipPluginOptions } from './util/chart'

const getChartProps = ({ data, highlightedData = [] }) => {
  const tooltipMaxItems = 1

  return {
    plugins: [blendPlugin],
    options: {
      plugins: {
        tooltip: {
          ...makeScatterTooltipPluginOptions(d => {
            return `${Formatters.formatThousands(d.days_on_lot)} ${pluralize('day', d.days_on_lot)}`
          }, tooltipMaxItems),
        },
      },
      scales: {
        x: {
          ticks: {
            callback: Formatters.formatThousands,
          },
          border: {
            display: false,
          },
        },
        y: {
          ticks: {
            padding: 0,
            callback: (value) => {
              return value === 0 ? '' : null
            },
          },
        },
      },
      parsing: {
        xAxisKey: 'days_on_lot',
        yAxisKey: 'y',
      },
      elements: {
        point: {
          borderWidth: 1,
          radius: 8,
          hoverRadius: 8,
          hoverBorderWidth: 1,
        },
      },
    },
    data: {
      datasets: [
        {
          data: highlightedData.map(d => ({ ...d, y: 0 })),
          backgroundColor: 'rgba(255, 0, 0, 0.9)',
          borderColor: 'rgba(255, 0, 0, 0)',
          hoverBorderColor: 'rgba(255, 0, 0, 1)',
        },
        {
          data: data.map(d => ({ ...d, y: 0 })),
          backgroundColor: 'rgba(44, 110, 142, 0.3)',
          borderColor: 'rgba(44, 110, 142, 0)',
          hoverBorderColor: 'rgba(44, 110, 142, 0.6)',
        },
      ],
    }
  }
}

const CurrentDaysOnLotScatterChart = memo(props => {
  const chartProps = getChartProps(props)

  return (
    <div style={{width: '100%', height: '60px', position: 'relative'}}>
      <Scatter {...chartProps} />
    </div>
  )
})

export default CurrentDaysOnLotScatterChart
