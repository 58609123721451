import React from 'react'

export default class DownArrowIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 17
    const height = this.props.height || 21
    const color = this.props.color || '#111'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 34 42" className={this.props.className} style={{...this.props.style}}>
        <path fillRule="evenodd" d="M31.989,27.668 L19.760,39.984 C18.878,40.872 17.641,41.169 16.511,40.898 C15.380,41.169 14.143,40.872 13.261,39.984 L1.032,27.668 C-0.319,26.308 -0.319,24.102 1.032,22.742 C2.383,21.382 4.573,21.382 5.924,22.742 L13.051,29.920 L13.051,3.472 C13.051,1.561 14.600,0.012 16.511,0.012 C18.421,0.012 19.970,1.561 19.970,3.472 L19.970,29.920 L27.097,22.742 C28.448,21.382 30.638,21.382 31.989,22.742 C33.340,24.102 33.340,26.308 31.989,27.668 Z"/>
      </svg>
    )
  }
}