import React, { useEffect, useState, useCallback, useMemo } from 'react'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../../components/common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'
import AlertVehiclePreview from './AlertVehiclePreview'
import usePolling from '../../api/hooks/usePolling'
import { alertVehiclesPollingInterval } from './util/constants'

const baseParams = {
  havingAlerts: true,
  order: 'last_alerted_at DESC',
}

const AlertVehiclePreviewList = ({ limit = 5 }) => {
  const [
    {
      isLoading,
      vehicles,
    },
    setState,
  ] = useState({
    isLoading: false,
    vehicles: null,
  })

  const params = useMemo(() => {
    return {
      ...baseParams,
      limit,
    }
  }, [limit])

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject(params)
  }, [params])

  const onDataChange = useCallback(() => {
    const data = ValuationStore.myLotVehicles.dataWithID(clientID)
    setState(prev => ({
      ...prev,
      isLoading: false,
      vehicles: data.data,
    }))
  }, [clientID])

  useEffect(() => {
    const listingsEv = `my_lot_vehicles_change_${clientID}`
    ValuationStore.on(listingsEv, onDataChange)

    return () => {
      ValuationStore.removeListener(listingsEv, onDataChange)
    }
  }, [onDataChange, clientID])

  const load = useCallback((isInitialLoad = false) => {
    if (isInitialLoad) {
      setState(prev => ({ ...prev, isLoading: true }))
    }
    ValuationActionCreators.loadMyLotVehicles({ clientID, ...params })
  }, [clientID, params])

  useEffect(() => {
    const listingsEv = `my_lot_vehicles_change_${clientID}`
    const vehicleChangedEv = 'lot_vehicle_updated'
    const vehicleSnoozedEv = 'lot_vehicle_snooze_updated'

    ValuationStore.on(listingsEv, onDataChange)
    ValuationStore.on(vehicleChangedEv, load)
    ValuationStore.on(vehicleSnoozedEv, load)

    return () => {
      ValuationStore.removeListener(listingsEv, onDataChange)
      ValuationStore.removeListener(vehicleChangedEv, load)
      ValuationStore.removeListener(vehicleSnoozedEv, load)
    }
  }, [onDataChange, clientID, load])

  useEffect(() => {
    load(true)
  }, [load])

  usePolling(load, alertVehiclesPollingInterval)

  return (
    <div>
      {
        isLoading ?
        <Spinner /> : (
          vehicles && !vehicles.errors &&
          <div className='my-lot-alert-previews-c'>
            {
              !vehicles.length ?
              <div style={{ padding: 20 }}>No results</div> :
              vehicles.map((vehicle, index) => {
                return (
                  <AlertVehiclePreview
                    key={index}
                    vehicle={vehicle}
                  />
                )
              })
            }
          </div>
        )
      }
    </div>
  )
}

export default AlertVehiclePreviewList
