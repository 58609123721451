import React, { useEffect, useState, useCallback, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import ValuationStore from '../../api/stores/ValuationStore'
import ValuationActionCreators from '../../api/actions/ValuationActionCreators'
import Spinner from '../common/Spinner'
import CommonFunctions from '../../api/constants/CommonFunctions'

const VehiclesList = ({
  order,
  status: state,
  isInInventory,
  bodyStyle,
  havingAlerts,
  getScrollParent,
  renderRow,
  year,
  make,
  model,
  trim,
}) => {
  const [
    {
      isLoading,
      vehicles,
      endOfListReached,
      errors,
    },
    setState,
  ] = useState({
    isLoading: false,
    vehicles: null,
    endOfListReached: false,
  })

  const clientID = useMemo(() => {
    return CommonFunctions.cacheKeyFromObject({
      state,
      order,
      bodyStyle,
      isInInventory,
      havingAlerts,
      year,
      make,
      model,
      trim,
    })
  }, [state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim])

  const onDataChange = useCallback(() => {
    const { data, errors, isLastPage } = ValuationStore.myLotVehicles.dataWithID(clientID)
    if (errors) {
      setState(prev => ({ ...prev, errors, isLoading: false, vehicles: null }))
    } else {
      setState(prev => ({
        ...prev,
        isLoading: false,
        vehicles: data,
        errors: null,
        endOfListReached: isLastPage,
      }))
    }
  }, [clientID])

  const reload = useCallback(() => {
    const curPage = ValuationStore.myLotVehicles.dataWithID(clientID).page || 1
    ValuationActionCreators.loadMyLotVehicles({ clientID, pageStart: 1, pageEnd: curPage, state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim })
  }, [clientID, state, order, bodyStyle, isInInventory, havingAlerts])

  const loadMore = useCallback((page) => {
    if (page > 1) {
      ValuationActionCreators.loadMyLotVehicles({ clientID, pageStart: page, state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim })
    }
  }, [clientID, state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim])

  const onVehicleChanged = useCallback(() => {
    reload()
  }, [reload])

  useEffect(() => {
    const dataChangedEv = `my_lot_vehicles_change_${clientID}`
    const vehicleChangedEv = 'lot_vehicle_updated'
    const vehicleSnoozedEv = 'lot_vehicle_snooze_updated'

    ValuationStore.on(dataChangedEv, onDataChange)
    ValuationStore.on(vehicleChangedEv, onVehicleChanged)

    if (havingAlerts) {
      ValuationStore.on(vehicleSnoozedEv, onVehicleChanged)
    }

    return () => {
      ValuationStore.removeListener(dataChangedEv, onDataChange)
      ValuationStore.removeListener(vehicleChangedEv, onVehicleChanged)
      ValuationStore.removeListener(vehicleSnoozedEv, onVehicleChanged)
    }
  }, [onDataChange, onVehicleChanged, clientID, havingAlerts])

  useEffect(() => {
    setState(prev => ({ ...prev, isLoading: true }))
    ValuationActionCreators.loadMyLotVehicles({ clientID, state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim })
  }, [onDataChange, clientID, state, order, bodyStyle, isInInventory, havingAlerts, year, make, model, trim])

  if (errors) {
    return (
      <div className="card">
        <div style={{ padding: 20 }}>
          {errors}
        </div>
      </div>
    )
  }
  if (isLoading) {
    return <Spinner />
  }
  if (vehicles) {
    return (
      <div className="card">
        <div className="card-list">
          <InfiniteScroll
            pageStart={1}
            loadMore={loadMore}
            getScrollParent={getScrollParent}
            useWindow={getScrollParent ==  null}
            hasMore={!endOfListReached}
            style={{ overflowAnchor: 'none' }}
            loader={<Spinner key="pagination-spinner" />}
          >
            {
              !vehicles.length ?
              <div style={{ padding: 20 }}>No results</div> :
              vehicles.map(renderRow)
            }
          </InfiniteScroll>
        </div>
      </div>
    )
  }
  return null
}

export default VehiclesList
