import { Modal, ModalBody } from 'reactstrap'
import VehicleTransactionDataForm from './VehicleTransactionDataForm'

const VehicleTransactionDataFormModal = ({ vehicle, onCancel, onDelete, onUpdate, clientID }) => {


  return (
    <Modal isOpen={true} centered={true} backdropClassName='modal-bg' contentClassName='modal-c my-lot-transaction-form-modal' style={{minWidth: '680px'}}>
      <VehicleTransactionDataForm vehicle={vehicle} onCancel={onCancel} onDelete={onDelete} onUpdate={onUpdate} clientID={clientID} />
    </Modal>
  )
}

export default VehicleTransactionDataFormModal
