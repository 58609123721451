import { useCallback } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const ConnectPreferredLotInventoryProviderModal = ({ isSaving, onCancel, onConfirm }) => {
  const onCancelClick = useCallback(e => {
    e.preventDefault()
    onCancel()
  }, [onCancel])

  return (
    <Modal isOpen centered toggle={onCancel} backdropClassName='modal-bg' contentClassName='modal-c' style={{minWidth: '700px'}}>
      <ModalHeader className='modal-header'>
        Connect Your Inventory Management System
      </ModalHeader>
      <ModalBody>
        <div>
          <p>Carbly supports direct connection to many inventory management systems. This allows you to automatically sync your current inventory to Carbly for more powerful and accurate lot insights and vehicle recommendations.</p>

          <p>We support <strong>integrations over 140 different systems</strong> including Frazer, CarsForSale, DealerCenter, Dealer.com, Cars.com, Dealer Inspire, AutoManager, AutoRevo, Dealer Car Search, Wayne Reaves, and many more!</p>

          <div style={{textAlign: 'center'}}>
            <img src="/images/ims-providers-sample.png" alt="IMS example providers" style={{width: '550px', height: '56px', marginBottom: '10px'}} />
          </div>

          <p>If you would like your inventory to automatically show up in Carbly, just click the "Request Connection" button below and we will get the process started. You will hear from us after we start the connection process.</p>
        </div>

        <div style={{textAlign: 'center'}}>
          <Button type='button' color='primary' onClick={onConfirm} disabled={isSaving} style={{margin: '0 auto'}}>Request Connection</Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <a href="#cancel" onClick={onCancelClick}>Cancel</a>
      </ModalFooter>
    </Modal>
  )
}

export default ConnectPreferredLotInventoryProviderModal
