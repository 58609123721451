import Spinner from '../../components/common/Spinner'
import LineChart from '../../components/myLot/LineChart'
import BarChart from '../../components/myLot/BarChart'
import useMyLotReport from './util/useMyLotReport'
import ChartSection from './ChartSection'
import PriceMileageScatterChart from '../../components/myLot/PriceMileageScatterChart'
import CurrentDaysOnLotScatterChart from '../../components/myLot/CurrentDaysOnLotScatterChart'
import Header from './Header'

const trendOptions = {
  showHistorical: true,
}

const chartSpacing = 6

const Analysis = () => {
  const {
    data: report,
    isLoading: isLoadingReport,
  } = useMyLotReport()

  return (
    <div>
      <Header title='Lot Analysis' />
      <div>
        {
          !report && isLoadingReport ?
          <Spinner /> :
          <>
            {
              report &&
              <div>
                <h2 style={{borderBottom: '2px solid #111', marginBottom: '20px', marginTop: '20px'}}>Current Lot</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '20px', rowGap: '30px', marginBottom: 100, marginTop: -chartSpacing, marginLeft: -chartSpacing }}>
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    title='Current Days on Lot'
                    Chart={CurrentDaysOnLotScatterChart}
                    data={report}
                    dataKey='analytics.current_days_on_lot'
                  />
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={90}
                    title='Current Inventory by Days on Lot'
                    Chart={BarChart}
                    data={report}
                    dataKey='analytics.days_on_lot_groupings'
                  />

                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={180}
                    title='Current Inventory Levels by Type'
                    Chart={BarChart}
                    data={report}
                    dataKey='analytics.current_inventory_levels_by_body_style'
                  />
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={220}
                    title='Current Inventory Percent of Market'
                    Chart={BarChart}
                    data={report}
                    dataKey='analytics.percent_of_market'
                  />
                </div>



                <h2 style={{borderBottom: '2px solid #111', marginBottom: '20px', marginTop: '20px'}}>Historic Trends</h2>

                <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '20px', rowGap: '30px', marginBottom: 130, marginTop: -chartSpacing, marginLeft: -chartSpacing }}>
                  <ChartSection
                    style={{ width: '32%', padding: chartSpacing }}
                    height={200}
                    title='Days on Lot'
                    Chart={LineChart}
                    data={report}
                    dataKey='analytics.trends.days_on_lot'
                    options={trendOptions}
                  />
                  <ChartSection
                    style={{ width: '32%', padding: chartSpacing }}
                    height={200}
                    title='Inventory Levels'
                    Chart={LineChart}
                    data={report}
                    dataKey='analytics.trends.inventory_levels'
                    options={trendOptions}
                  />
                  <ChartSection
                    style={{ width: '32%', padding: chartSpacing }}
                    height={200}
                    title='Lot Value'
                    Chart={LineChart}
                    data={report}
                    dataKey='analytics.trends.retail_values'
                    options={trendOptions}
                  />
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={180}
                    title='Price Changes'
                    Chart={LineChart}
                    data={report}
                    dataKey='analytics.trends.num_price_changes'
                    options={trendOptions}
                  />
                  
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={180}
                    title='Vehicles Sold by Type'
                    Chart={BarChart}
                    data={report}
                    dataKey='analytics.num_sold_by_body_style'
                  />
                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    height={180}
                    title='Days on Lot by Type'
                    Chart={BarChart}
                    data={report}
                    dataKey='analytics.days_on_lot_by_body_style'
                  />

                  <ChartSection
                    style={{ width: '48.5%', padding: chartSpacing }}
                    title='Price and Mileage'
                    Chart={PriceMileageScatterChart}
                    data={report}
                    dataKey='analytics.price_mileage'
                  />
                </div>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

export default Analysis
