import { useRef, useCallback } from 'react'

const ListingImage = ({ src, style }) => {
  const hasErrorRef = useRef(false)
  const fallbackImage = '/images/missing-photo-placeholder.png'

  const onError = useCallback((e) => {
    e.target.src = hasErrorRef.current ? '' : fallbackImage
    hasErrorRef.current = true
  }, [])

  return (
    <img
      src={src || fallbackImage}
      loading='lazy'
      onError={onError}
      style={style}
      alt='Listing'
    />
  )
}

export default ListingImage
