import { useEffect, useState, useCallback } from 'react'
import RequestWebsiteUrlChangeModal from './RequestWebsiteUrlChangeModal'
import useMyLotSettings from '../../components/myLot/util/useMyLotSettings'
import usePrevious from '../../api/hooks/usePrevious'

const RequestWebsiteUrlChangeButton = ({ buttonText }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [
    {
      onboarding: {
        userSubmittedLotUrl,
      },
    },
    updateSettings,
    isUpdatingSettings,
  ] = useMyLotSettings()

  const prevUserSubmittedLotUrl = usePrevious(userSubmittedLotUrl)

  useEffect(() => {
    if (!isUpdatingSettings && userSubmittedLotUrl !== prevUserSubmittedLotUrl) {
      setIsModalOpen(false)
    }
  }, [isUpdatingSettings, userSubmittedLotUrl, prevUserSubmittedLotUrl])

  const onCancelClick = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const onSubmit = useCallback(value => {
    updateSettings({ userSubmittedLotUrl: value })
  }, [updateSettings])

  return (
    <>
      <button className='link' disabled={isUpdatingSettings} onClick={() => setIsModalOpen(true)}>{buttonText}</button>
      {
        isModalOpen &&
        <RequestWebsiteUrlChangeModal
          isSaving={isUpdatingSettings}
          onCancel={onCancelClick}
          initialValue={userSubmittedLotUrl}
          onSubmit={onSubmit}
        />
      }
    </>
  )
}

export default RequestWebsiteUrlChangeButton
