import React from 'react'

export default class DownArrowShortIcon extends React.PureComponent {
  render() {
    const width = this.props.width || 22
    const height = this.props.height || 22
    const color = this.props.color || '#111'
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`} fill={color} viewBox="0 0 16 16" className={this.props.className} style={{...this.props.style}}>
          <path fillRule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1"/>
      </svg>
    )
  }
}